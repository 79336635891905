import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  GetDelPointListByTerritory,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, getProductWiseCumalitiveReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { getDistributorList } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { FaSearch } from "react-icons/fa";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { useMemo } from "react";

const SecondarySalesProductWiseReport = () => {
  const history = useHistory();
  const isProductLoading = useSelector((state) => state.secondarySales.isProductLoading);
  const productReport = useSelector((state) => state.secondarySales.productReport);
  console.log('productReport :>> ', productReport);
  const productTotal = useSelector((state) => state.secondarySales.productTotal);
  const TargetTotalPrice = useSelector((state) => state.secondarySales.TargetTotalPrice);
  const SalesGroupdistributorList = useSelector(
    (state) => state.remoteSales.SalesGroupdistributorList
  );
  console.log('SalesGroupdistributorList :>> ', SalesGroupdistributorList);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();
  const [Distributor, setDistributor] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  useEffect(() => {
    if (defultTerritory?.value) {
      dispatch(getDistributorList(defultTerritory?.value, salesType));
      //inputChangeHandle("intDistributorId", 0);
    }
  }, [defultTerritory]);

  const initialData = async () => {
    var d = new Date();
    var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);
        GetDelPointList(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);

  let ref = React.createRef();
  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);

  };


  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [soPoint, setSelectSoPoint] = useState(null);
  const [delpoint, setDelPointData] = useState([]);

  const GetDelPointList = async (id) => {
    let delData = await GetDelPointListByTerritory(id);
    setDelPointData(delData);
  }


  const delpointDropdownData = () => {
    let options = [];
    if (delpoint.data?.length > 0) {
      delpoint.data.forEach((item) => {
        let itemData = {
          value: item.delPointID,
          label: item.delPointName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }
  const showReport = () => {
    const validation = showValidation();
    if (validation) {
      dispatch(getProductWiseCumalitiveReport(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, soPoint, selectGeoType));
    }
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState('productName');

  // For Sorting
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
      setSearchTerm("");
    }
  };

  // Sorting logic
  const sortedList = useMemo(() => {
    if (!Array.isArray(productReport)) {
      return [];
    }

    const filteredList = productReport.filter((item) => {
      const itemNameLower = String(item.productName).toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();
      return !searchTermLower || itemNameLower.includes(searchTermLower);
    });

    const sorted = filteredList.sort((a, b) => {
      if (sortOrder === 'asc') {
        if (a[sortedColumn] === b[sortedColumn]) {
          return a.damages - b.damages || a.serial - b.serial;
        } else {
          return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
        }
      } else {
        if (a[sortedColumn] === b[sortedColumn]) {
          return b.damages - a.damages || b.serial - a.serial;
        } else {
          return a[sortedColumn] < b[sortedColumn] ? 1 : -1;
        }
      }
    });

    return sorted;
  }, [productReport, searchTerm, sortOrder, sortedColumn]);

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Sku Wise IMS Cumalative Report</h3>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="mt-2 p-2 float-right ">
              <DownloadDropdown excelname='Sku Wise IMS Cumalative Report' />
            </div>
          </div>


        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  //getTerritoryData(option?.value);
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                  //dispatch(getDistributorList(option?.value, salesType));
                  GetDelPointList(option?.value);
                  setValue("intDistributorId", 0);
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>
          {/* <div className="col-lg-3 mb-5">
            <Form.Group as={Col} controlId="formGridState">

              <label className="form-label">Select Distributor</label>
              <RHFInput

                as={<Select options={SalesGroupdistributorList} />}
                rules={{ required: false }}
                name="intDistributorId"
                register={register}
                onChange={(option) => {
                  setDistributor(option?.value?.intcusid)
                }}
                setValue={setValue}
              />
            </Form.Group>


          </div> */}

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select SO Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={delpointDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectSoPoint(option?.value);
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col mt-5 ml-5">
            <Button size="sm" onClick={() => showReport()}><FaSearch /> Show Report</Button>
          </div>
        </div>
        <div>





          {productReport.length > 0 &&

            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">

                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget three">
                    <div className="widget__left">
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <span className=" target-label-cumalative">Total Target Value</span>
                    </div>
                    <div className="widget__right">
                      <div>
                        <h4 className="balanceleft">৳ {numberFormatDecemal(parseFloat(TargetTotalPrice).toFixed(2))}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget two">
                    <div className="widget__left" >
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <span className=" target-label-cumalative">Total IMS Value</span>
                    </div>
                    <div className="widget__right">

                      {/* <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-warning" />
                      </div> */}

                      <div>
                        <h4 className="balanceleft">৳ {numberFormatDecemal(parseFloat(productTotal).toFixed(2))}</h4>
                      </div>

                    </div>
                  </div>
                </div>



                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget four">
                    <div className="widget__left">
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <span className=" target-label-cumalative">Achievement</span>
                    </div>
                    <div className="widget__right">
                      <div>
                        <h4 className="balanceleft">{numberFormatDecemal(parseFloat((productTotal / TargetTotalPrice) * 100).toFixed(2))}%</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          }
        </div>
        <div className=" pl-5 pr-5 mb-5">
          {productReport?.length > 0 ? (
            <div className="searchBox" >
              <form className="form mt-2" style={{ backgroundColor: 'skyblue', float: 'right' }}>
                <span>
                  <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                    <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </span>
                <input
                  className="input"
                  placeholder="Search Product Name"
                  required=""
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="reset" type="reset" onClick={() => setSearchTerm("")}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </form>

            </div>
          ) : null}

        </div>

        <div className="row">
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            <div
              className="shortstickyTable"
              style={{ height: sortedList?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims">
                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                <thead>

                  <tr>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">BRAND NAME</th>
                    {/* <th scope="col">PACK NAME</th>
                    <th scope="col">Category</th>
                    <th scope="col">PRODUCT PRICE(PCS)</th>
                    <th scope="col">TARGET PCS</th> */}
                    <th scope="col">TARGET CTN</th>
                    {/* <th scope="col">TARGET PRICE</th> */}
                    <th scope="col">TARGET VALUE</th>
                    {/* <th scope="col">IMS PCS</th> */}
                    <th scope="col">IMS CTN</th>
                    <th scope="col">IMS VALUE</th>
                    <th scope="col">IMS ACH%</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedList.length > 0 &&
                    sortedList.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{item.productID}</td>
                        <td>{item.productName}</td>
                        <td>{item.brandName}</td>
                        {/* <td>{item.packName}</td>
                        <td>{item.productCategory}</td> */}
                        {/* <td>{parseFloat(item.productPicPrice).toFixed(2)}</td> */}
                        {/* <td>{`${parseFloat(item.targetProdQty).toFixed(2)}`}</td> */}
                        <td>{`${parseFloat(item.targetCTN).toFixed(2)}`}</td>
                        {/* <td>{`${parseFloat(item.targetProdPrice).toFixed(2)}`}</td> */}
                        <td>{numberFormatDecemal(`${parseFloat(item.targetTotalPrice).toFixed(2)}`)}</td>
                        {/* <td>{item.productQty}</td> */}
                        <td>{Math.round(item.productQtyCtn)}</td>
                        <td>{numberFormatDecemal(`${parseFloat(item.productTPrice).toFixed(2)}`)}</td>
                        <td>
                          {
                            item.targetProdQty !== 0 ?
                              parseFloat((item.productTPrice) / ((item.targetTotalPrice)) * 100).toFixed(2) + "%" :
                              "0%"
                          }
                        </td>

                      </tr>
                    ))}


                </tbody>
              </table>
            </div>

            {!isProductLoading && productReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
          </div>
        </div>


        <div className="row notshow" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            <div
              className="shortstickyTable"
              style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                <thead>

                  <tr>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">BRAND NAME</th>
                    <th scope="col">PACK NAME</th>
                    <th scope="col">Category</th>
                    <th scope="col">PRODUCT PRICE(PCS)</th>
                    <th scope="col">TARGET PCS</th>
                    <th scope="col">TARGET CTN</th>
                    {/* <th scope="col">TARGET PRICE</th> */}
                    <th scope="col">TARGET VALUE</th>
                    <th scope="col">IMS PCS</th>
                    <th scope="col">IMS CTN</th>
                    <th scope="col">IMS VALUE</th>
                    <th scope="col">IMS ACH%</th>
                  </tr>
                </thead>
                <tbody>
                  {productReport.length > 0 &&
                    productReport.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{item.productID}</td>
                        <td>{item.productName}</td>
                        <td>{item.brandName}</td>
                        <td>{item.packName}</td>
                        <td>{item.productCategory}</td>
                        <td>{parseFloat(item.productPicPrice).toFixed(2)}</td>
                        <td>{`${parseFloat(item.targetProdQty).toFixed(2)}`}</td>
                        <td>{`${parseFloat(item.targetCTN).toFixed(2)}`}</td>
                        {/* <td>{`${parseFloat(item.targetProdPrice).toFixed(2)}`}</td> */}
                        <td>{`${parseFloat(item.targetTotalPrice).toFixed(2)}`}</td>
                        <td>{item.productQty}</td>
                        <td>{Math.round(item.productQtyCtn)}</td>
                        <td>{`${parseFloat(item.productTPrice).toFixed(2)}`}</td>
                        <td>
                          {
                            item.targetProdQty !== 0 ?
                              parseFloat((item.productTPrice) / ((item.targetTotalPrice)) * 100).toFixed(2) + "%" :
                              "0%"
                          }
                        </td>

                      </tr>
                    ))}


                </tbody>
              </table>
            </div>

            {!isProductLoading && productReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
          </div>
        </div>

      </div>
    </>
  );
};

export default SecondarySalesProductWiseReport;
