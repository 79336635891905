
//Purchases Order 
export const GET_ORDER_INPUT = "GET_ORDER_INPUT";
export const ADD_MULTIPLE_ORDER = "ADD_MULTIPLE_ORDER";
export const GET_SBU_LIST = "GET_SBU_LIST";
export const GET_BRANCH_LIST = "GET_BRANCH_LIST";
export const GET_PURCHASE_ORGANIZATION = "GET_PURCHASE_ORGANIZATION";
export const GET_REFERENCE_TYPE = "GET_REFERENCE_TYPE";
export const PURCHASE_ORDER_FILTER = "PURCHASE_ORDER_FILTER";
export const DELETE_MULTIPLE = "DELETE_MULTIPLE";
export const EDIT_MULTIPLE = "EDIT_MULTIPLE";
export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER";
export const FINAL_ORDER_INPUT = "FINAL_ORDER_INPUT";
export const CREATE_FIANL_INPUT = "CREATE_FIANL_INPUT";
export const GET_ORDER_VIEW = "GET_ORDER_VIEW";
export const GET_INCOTERM_NAME = "GET_INCOTERM_NAME";
export const RESPONSE_DATA_STATUS = "RESPONSE_DATA_STATUS";
