import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import {getPrimaryDemandSkuWise} from "../../_redux/actions/Report/ReportAction";

const PrimarySalesSkuWiseDemand = () => {
  const history = useHistory();
  const Plot = createPlotlyComponent(window.Plotly);
  const PlotlyRenderers = createPlotlyRenderers(Plot);
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const productReport = useSelector((state) => state.bradBurn.primaryDemandSku);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(0);
  const [selectArea, setSelectArea] = useState(0);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectType, setSelectType] = useState(null);
  const [daynamicWidth, setDaynamicWidth] = useState(100);
  const componentRef = useRef();
  const [selectDaynamicVal, setSelectDaynamicVal] = useState("productQtyPcs");

  const [pivotScheme, setPivotScheme] = useState({
    hideConstructor: true,
    rows: [
      "zoneName", "divisionName", "areaName", "distribName"
    ],
    cols: ["productCat", "productName"],
    aggregatorName: "Sum",
    vals: ["productQtyPcs"],
    //  vals: [selectDaynamicVal],
  })
  const [valpivotScheme, setValPivotScheme] = useState({
    hideConstructor: true,
    rows: [
      "zoneName", "divisionName", "areaName", "distribName"
    ],
    cols: ["productCat", "productName"],
    aggregatorName: "Sum",
    vals: ["productPrice"],
    //  vals: [selectDaynamicVal],
  })
  

  



  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);



  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
    
  };





  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const typeDropdownData = () => {
    let options = [
      {
      value:1,
      label:'Quantity'
    },
    {
      value:2,
      label:'Price'
    },
  ];
  
    return options;
  };





  let ref = React.createRef();
  const inputField = React.useRef(null);



  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleDateFilter =(input)=>{
    setToDate(input);
  }

 


  const handleDaynamicWidth = (value) => {
    if (value == 4) {
      setDaynamicWidth('200')
    } else {
      setDaynamicWidth('400')
    }
  }


  const handleDaynamicVal=(val)=>{
    if(val==1){
      setSelectDaynamicVal('stockQty');
    }else{
      setSelectDaynamicVal('stockValue')
    }
  }
  const showReport =()=>{

    dispatch(getPrimaryDemandSkuWise(startDate, toDate,selectZone, selectDivision, selectArea,selectTerritory));
  }

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

 

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
            <span className="button-010">Cumalative Primary Demand(SKU Wise)</span>
              {/* <h3 className="mb-0 pb-0">Cumalative Primary Demand(SKU Wise)</h3> */}
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Cumalative Primary Demand(SKU Wise)' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={(event) => {
                  handleDateFilter(event.target.value)
                }}

              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Type</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={typeDropdownData()} />}
                rules={{ required: false }}
                name="typeData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectType(option?.value)
                  handleDaynamicVal(option?.value);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  handleDaynamicWidth(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                 

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
               
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                 
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                 
                  setValue("territory", "");
                
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                   
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
          <div className="col mt-6 mb-5">
          <button className="button-621" onClick={() => showReport()} >  Show Report</button>
            {/* <Button size="sm" onClick={()=>showReport()}>Show Report</Button> */}
            
          </div>
        </div>

        
      </div>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 mb-5" style={{ width: `${daynamicWidth}%` }}>
        {productReport.length > 0 &&

          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
              <table className="table table-head-custom table-vertical-center  item-add-table breadBurnProductLifting" id="table-to-xls" ref={componentRef}>
                {
                  selectType==1&&
                  <PivotTableUI
                  data={productReport}
                  // onChange={s => this.setState({ pivotState: s })}
                  onChange={(s) => setPivotScheme(s)}
                  renderers={Object.assign({},
                    TableRenderers,
                    PlotlyRenderers
                  )}
                  {...pivotScheme}
                />
                }
                {
                  selectType==2&&
                  <PivotTableUI
                  data={productReport}
                  // onChange={s => this.setState({ pivotState: s })}
                  onChange={(s) => setValPivotScheme(s)}
                  renderers={Object.assign({},
                    TableRenderers,
                    PlotlyRenderers
                  )}
                  {...valpivotScheme}
                />
                }
                
              </table>
              {!isLoading && productReport.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! Sales List Not Found.
                </div>
              )}
              
            </div>
          </div>

        }
      </div>
    </>
  );
};

export default PrimarySalesSkuWiseDemand;
