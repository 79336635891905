import React from "react";
import VesselAccountList from "../../components/vessel/VesselAccountList";

const VesselAccountContainer = () => {
  return (
    <>
      <VesselAccountList />
    </>
  );
};

export default VesselAccountContainer;
