import React from "react";
import ComparativeStatementList from "../components/ComparativeStatementList";



const ComparativeStatementListContainer = () => {
  return (
    <div className="container">
      <div className="card card-custom gutter-b">
        <ComparativeStatementList />
      </div>
    </div>
  );
};

export default ComparativeStatementListContainer;
