import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
  getDistributorWiseProductSkuDetails,
  getSOSkuProduct
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

import * as XLSX from "xlsx/xlsx.mjs";
import { getDistributorWiseProductSkuDetailsForExcel, getSecondarysoProductForExcel } from "../../_redux/actions/Delivery/DeliveryAction";

const DistributorWiseProductSkuDetails = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const productReport = useSelector((state) => state.secondarySales.dwisepskud);
  const skuTotal = useSelector((state) => state.secondarySales.skuTotal);
  const skuQuantity = useSelector((state) => state.secondarySales.skuQuantity);
  const productTotal = useSelector((state) => state.secondarySales.productTotal);
  const productQuantity = useSelector((state) => state.secondarySales.productQuantity);
  const productPrice = useSelector((state) => state.secondarySales.productPrice);
  const amount = useSelector((state) => state.secondarySales);
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const componentRef = useRef();




  
  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {

    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id,selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id,selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);



  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);




  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea,idTerritory) => {
    dispatch(getDistributorWiseProductSkuDetails(fromDate, tDate, idZone, idDivision, idArea,idTerritory,selectGeoType,1));
  }




  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }

  const hanleExcelClick = async () => {
    let excelClick = await getDistributorWiseProductSkuDetailsForExcel(
      startDate,
      toDate,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory,
      2,
      2
    );
    
    
    const worksheet = XLSX.utils.json_to_sheet(excelClick.data.allReport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Distributor Wise Product Sku Details.xlsx");
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Distributor Sku Wise IMS Report  </h3>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              <Button size="sm" onClick={() => hanleExcelClick()} >Download</Button>
              <DownloadDropdown />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
            <SimpleSgeoType
             getSalesValue={getSalesValueType}
             sGeoType={selectGeoType}
             />
            </div>
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                onChange={(option) => {
                  setSelectZone(option.value);
                  divisionList(option.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option.value);
                  setSelectDivision(option.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option.value)
                  setSelectArea(option.value);
                  
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                    // handleListAction(startDate, toDate, selectZone, selectDivision, selectArea,option.value);
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
          <div className="col-md-3 mt-5 mb-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea,selectTerritory,selectGeoType)}>Show Report</Button>
          </div>
         
        </div>
        <div>
          {productReport?.length > 0 &&

            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">

                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget one">
                    <div className="widget__left">
                      <p className="balanceleft">Total Quantity</p>
                    </div>
                    <div className="widget__right">

                      <div className="overlay-layer bg-transparent">
                      </div>
                      <div>
                        <h4 className="balanceleft"> {parseInt(amount.DtotalQuentity)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="paid__widget two">
                    <div className="widget__left">
                      <p className="balanceleft">Total CTN Quantity</p>
                    </div>
                    <div className="widget__right">

                      <div className="overlay-layer bg-transparent">
                      </div>
                      <div>
                        <h4 className="balanceleft"> {parseInt(amount.DtotalQuentityCtn)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="paid__widget three">
                    <div className="widget__left">
                      <p className="balanceleft">Total Price</p>
                    </div>
                    <div className="widget__right">

                      <div className="overlay-layer bg-transparent">
                      </div>
                      <div>
                        <h4 className="balanceleft">৳ {parseFloat(amount.DtotalPrice).toFixed(2)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

          }
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
              <table className="table table-head-custom table-vertical-center   item-add-table" id="table-to-xls" ref={componentRef}>
                <thead className="bg-white" >

                  <tr>
                  <th scope="col">ZONE </th>
                  <th scope="col">DIVSION </th>
                  <th scope="col">REGION </th>
                  <th scope="col">TERRITORY </th>
                  <th scope="col">DISTRIB ID</th>
                  <th scope="col">DISTRIB NAME</th>
                  <th scope="col">DIS POINT NAME</th>
                  <th scope="col">PRODUCT ID</th>
                  <th scope="col">PRODUCT NAME</th>
                  <th scope="col">PRODUCT PIC PRICE</th>
                  <th scope="col">PRODUCT QUANTITY(PCS)</th>
                  <th scope="col">PRODUCT QUANTITY(QTN)</th>
                  <th scope="col">TOTAL VALUE</th>
                 
                  </tr>
                </thead>
              

                <tbody>
                  {productReport?.length > 0 &&
                    productReport?.map((item, index) => (
                      <>
                        <tr className="alert bg-secondary">
                          {/* <td>{item.orderId}</td> */}
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.distribID}</td>
                          <td>{item.distributorName}</td>
                          <td>{item.disPointName}</td>
                          <td />
                          <td />
                          <td />
                          <td>{parseInt(item.totalQuentity)}</td>
                          <td>{parseInt(item.totalQuentityCtn)}</td>
                          <td>{parseFloat(item.totalPrice).toFixed(2)}</td>
                          
                          
                        </tr>
                        {
                          item?.itemReport.map((product, subindex) => (
                            <tr>
                                <td>{item.zoneName}</td>
                                <td>{item.divisionName}</td>
                                <td>{item.areaName}</td>
                                <td>{item.territoryName}</td>
                                <td>{item.distribID}</td>
                                <td>{item.distributorName}</td>
                                <td>{item.disPointName}</td>
                                <td>{product.productID}</td>
                                <td>{product.productName}</td>
                                <td>{parseFloat(product.productPicPrice).toFixed(2)}</td>
                                <td>{parseInt(product.productQty)}</td>
                                <td>{parseInt(product.productQtyCtn)}</td>
                                <td>{parseFloat(product.productTPrice).toFixed(2)}</td>
                            </tr>
                          ))
                        }
                      </>
                    ))}


                </tbody>



               
              </table>
              </div>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>



        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>
      </div>
    </>
  );
};

export default DistributorWiseProductSkuDetails;

