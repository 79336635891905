import React from "react";
import BookingEntry from "../components/BookingEntry";

const BookingEntryContainer = () => {
  return (
    <div className="container">
      <BookingEntry />
    </div>
  );
};

export default BookingEntryContainer;
