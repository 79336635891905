import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_ZONE_INPUT, payload: formData });
};
export const submitStatusRemove = () => (dispatch) => {
  dispatch({ type: Types.ZONE_SUBMIT_STATUS_REMOVE, payload: null });
};
export const handleSubmit = (inputData) => (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `https://localhost:44373/api/GeoSetup/ZoneEntry?ZoneName=${inputData.zoneName}`;
    axios
      .post(postUrl)
      .then(function(response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.messege);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function(error) {
      
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {
    
  }

  dispatch({
    type: Types.ZONE_SAVE,
    payload: responseList,
  });
};
export const GetAllZone = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  let url = `https://localhost:44373/api/GeoSetup/GetAllZone`;
  
    axios.get(url)
    .then((res) => {
        responseList.data=res.data.data;
        responseList.status=false;
        let data = res.data; 
    });
    dispatch({ type: Types.GET_ZONE_LIST, payload: responseList });
};

export const GetAllZoneForEntryWithoutPC = async (name, value) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZoneEntryForNoPromotion`;
    console.log('geturl', geturl);
    await axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
    // responseList.data.unshift(objectVar)
  } catch (error) {

  }
  return responseList;
};


export const GetZoneForDistributorSalesEntry = async (name, value) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetZoneForDistributorSalesEntry`;
    console.log('geturl', geturl);
    await axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
    // responseList.data.unshift(objectVar)
  } catch (error) {

  }
  return responseList;
};



