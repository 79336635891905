import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { SubdistributorEntry } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { handleChangeUserAction } from "../../../../role-permission-management/information/_redux/actions/UserAction";


const SubDistributorEntry = ({ handleClose }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const userInput = useSelector((state) => state.userRole.inputData);
  console.log('userInput', userInput)
  const isLoading = useSelector((state) => state.userRole.isLoading);
  const roleListOption = useSelector((state) => state.roleReducer.roleListOption);
  const dispatch = useDispatch();



  const onSubmit = () => {
    dispatch(SubdistributorEntry(userInput?.UserID));
  };

  const handleChange = (name, value, e = null) => {
    dispatch(handleChangeUserAction(name, value, e));
  };

  useEffect(() => {
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">

          <div className="col-12">
            <div className="card card-custom gutter-b p-5">
              <div className="row mt-3">
                <div className="col-md-9">
                  <div className="mt-2 p-2">
                    <h3 className="mb-0 pb-0">Sub Distributor Entry </h3>
                  </div>
                </div>
              </div>



              <hr></hr>
              <form
                className="form form-label-right voyageEngineerForm"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group row">
                  <div className="col-xl-3 col-lg-3 col-md-6 ">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Distributor ID
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="first_name"
                        placeholder="Enter Enroll"
                        onChange={(e) =>
                          handleChange("UserID", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  
                 
                  
                 
                  <div className="col-xl-3 col-lg-3 col-md-6 mt-6">
                    {isLoading && (
                      <Button
                        className="mr-4 saveButton text-white " disabled={true}>
                        <span>Submitting</span>
                        <span className="ml-3 spinner spinner-white"></span>
                      </Button>
                    )}

                    {!isLoading && (
                      <Button
                        className="mr-4 saveButton text-white " variant="" onClick={() => onSubmit()}>
                        Submit
                      </Button>
                    )}

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubDistributorEntry;
