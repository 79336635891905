import React from "react";
import EmployeeEdit from "../../components/hr/employee/personal/EmployeeEdit";



const EmployeeEditContainer = (props) => {
    return (
        <>
            <EmployeeEdit props={props} />
        </>
    );
};

export default EmployeeEditContainer;
