import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  cleanRemoteSalesData,
  EditDateGet,
  getSalesList,
  salesDelete,
  showApprovedReport,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import Select from "react-select";
import { GetDisZDAreaTerriDistbpoints } from "../Dashboard/DashboardCartAction";
import { GetZoneForDistributorSalesEntry } from "../../_redux/actions/Zone/ZoneAction";
import { GetDivisionForDistributorSalesEntry, GetRegionForDistributorSalesEntry, GetTerritoryForDistributorSalesEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { InputHandle } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { subSalesApprovedReport } from "../../_redux/actions/RemoteSales/SuperDepoAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import SubSalesApprovedDetails from "./SalesApprovedReport/SubSalesApprovedDetails";
import { setDateFromOldDate } from "../../../../master/PrintDwopdown/DateHelper";



const DisSubSalesApprovedReport = () => {
  const history = useHistory();

  const isLoading = useSelector((state) => state.itemList.approvedLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const salesApprovedList = useSelector((state) => state.remoteSales.salesApprovedList);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();


  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    dispatch(cleanRemoteSalesData());
  }, [])

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetDisZDAreaTerriDistbpoints()
      .then((res) => {
        console.log('resNew :>> ', res.data);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, employeeType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

        setSalesType(employeeType);
        setDistribType(employeeType);

      });

    let zoneData = await GetZoneForDistributorSalesEntry();
    Setzone(zoneData.data);
  }

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };


  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await GetDivisionForDistributorSalesEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await GetRegionForDistributorSalesEntry(id, selectGeoType);
    setArea(areaData.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await GetTerritoryForDistributorSalesEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.inVId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);

  }
  const handleChangeTodate = ({ currentTarget: input }) => {

    setToDate(input.value);
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const showReport = () => {
    dispatch(subSalesApprovedReport(startDate, toDate, selectArea, selectTerritory, salesType));
  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        {/* <div className="container"> */}
        {/* <SalesApprovedReportFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}
        /> */}
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Sales Demand Approved Report</h3>
            </div>
          </div>
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>
        <div className="form-group row">
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);

                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);

                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                  dispatch(getSalesList(option.value, salesType));

                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3 mt-0">
            <label className="form-label"> From Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Deadweight"
              name="fromDate"
              className="fromStyle"
              onChange={handleChange}
              value={startDate}
            />

          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label"> To Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Todate"
              name="todate"
              value={toDate}
              className="fromStyle"
              onChange={handleChangeTodate}
            />
          </div>
          <div className="col-lg-3 mt-4">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  {/* <th scope="col">Department</th> */}
                  {/* <th scope="col">ORDER ID</th> */}
                  <th scope="col">DISTRIBUTOR ID</th>
                  <th scope="col">DISTRIBUTOR NAME</th>
                  <th scope="col">INVOICE NO</th>
                  <th scope="col">INVOICE DATE</th>
                  <th scope="col">ORDER QUANTITY</th>
                  <th scope="col">BONUS QUANTITY</th>
                  <th scope="col">APPROVED QUANTITY</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {salesApprovedList.length > 0 &&
                  salesApprovedList.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.distId}</td>
                      <td>{item.distName}</td>
                      <td>{item.invCode}</td>
                      <td>{setDateFromOldDate(item?.invDate)}</td>
                      <td>{item.totalOrderQuentity}</td>
                      <td>{item.bonusQty}</td>
                      <td>{item.totalApprovedQuentity}</td>
                      <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!isLoading && salesApprovedList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Approved List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SubSalesApprovedDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default DisSubSalesApprovedReport;
