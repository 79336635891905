import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewLoader2 from '../../../../master/spinner/NewLoader2';
import { GetCategoryWiseIMSDivision } from '../../../information/_redux/actions/Report/ReportAction';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FaSearch, FaTable } from 'react-icons/fa';
import DotLoading from '../../../../master/spinner/DotLoading';
import { format, subDays } from 'date-fns';
import { formatDate } from '../../../../master/PrintDwopdown/DateHelper';
import { dateTimeConvertLongStringForDate } from '../../../../../domains/CCO/utils/DateHelper';
import DatePicker from "react-datepicker";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const CategoryWiseDivisionData = () => {
    const dispatch = useDispatch();
    const reportDataLoading = useSelector((state) => state.target.cateReportsLoading);
    const cateWiseDivision = useSelector((state) => state.target.cateWiseDivision);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const [showTable, setShowTable] = useState(false);
    const componentRef = useRef();

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const initialStartDate = new Date(currentYear, currentMonth - 1, 1);

    const [startDate, setStartDate] = useState(initialStartDate);
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeNew = (date) => {
        setIsOpen(!isOpen);
        setStartDate(date);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setStartDate(initialStartDate);
    }, []);

    useEffect(() => {
        const d = new Date();
        const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
        const previousDay = new Date(d);
        previousDay.setDate(d.getDate() - 1);

        const firstD = formatDate(firstDayOfMonth);

        dispatch(GetCategoryWiseIMSDivision(firstD));
    }, [])


    useEffect(() => {
        if (cateWiseDivision?.length > 0) {
            const labels = cateWiseDivision.map(item => item.divisionName);
            const dataset = [
                {
                    label: 'Biscuit Achievement',
                    data: cateWiseDivision.map(item => item.bisAch),
                    backgroundColor: 'rgba(255, 99, 132, 0.7)',
                },
                {
                    label: 'Cookie Achievement',
                    data: cateWiseDivision.map(item => item.cookiAch),
                    backgroundColor: 'rgba(54, 162, 235, 0.7)',
                },
                {
                    label: 'Cake Achievement',
                    data: cateWiseDivision.map(item => item.cakeAch),
                    backgroundColor: 'rgba(255, 206, 86, 0.7)',
                },
                {
                    label: 'Wafer Achievement',
                    data: cateWiseDivision.map(item => item.waferAch),
                    backgroundColor: 'rgba(75, 192, 192, 0.7)',
                },
                {
                    label: 'Snacks Achievement',
                    data: cateWiseDivision.map(item => item.snacksAch),
                    backgroundColor: 'rgba(153, 102, 255, 0.7)',
                }
            ];

            setChartData({
                labels: labels,
                datasets: dataset,
            });
        }
    }, [cateWiseDivision]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLogoClick = () => {
        setShowTable((prev) => !prev); // Toggle the table view
    };

    const showReport = () => {
        dispatch(GetCategoryWiseIMSDivision((selectedMonthDate)));
    }


    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);
    const [selectedMonthDate, setSelectedMonthDate] = useState(initialMonth);

    const handleMonthChange = (event) => {
        const month = event.target.value;
        setSelectedMonth(event.target.value);
        setSelectedMonthDate(`${month}-01`);
    };

    return (
        <>

            <div className="container-fluid form-group row pl-4 paddingremove">
                <div className="form-group row pl-4">
                    <div className="col-lg-5 col-sm-5">
                        <Form.Group controlId="formGridState">
                            <label className="form-label text-light mb-0 mr-3">Select Month</label>
                            <div>
                                <input
                                    className='example-custom-input-month'
                                    type="month"
                                    id="monthPicker"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                />
                            </div>
                        </Form.Group>
                    </div>

                    <div className={`col-lg-6 col-sm-6 ${isSmallScreen ? '' : 'mt-6'}`}>
                        <button
                            size="sm"
                            onClick={() => showReport()}
                            className="btn btn-success"
                            style={{
                                height: "30px",
                                padding: "0 15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <FaSearch style={{ marginRight: '5px' }} />
                            Show Report
                        </button>
                    </div>

                </div>
            </div>




            {reportDataLoading && <DotLoading />}

            <div className='container-fluid fullcardbg' style={{
                // backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
            }}>
                <div className="row" ref={componentRef}>
                    <div className='col-lg-12 paddingremove'>

                        {!reportDataLoading && chartData.labels?.length > 0 && (
                            <>
                                {showTable || isSmallScreen ? (
                                    <div className="table-responsive-category">
                                        <table className="table table-striped custom-table">
                                            <thead>
                                                <tr>
                                                    <th className="division-name">Division Name</th>
                                                    <th className="biscuit-achievement text-center">Biscuit Achievement</th>
                                                    <th className="cookie-achievement text-center">Cookie Achievement</th>
                                                    <th className="cake-achievement text-center">Cake Achievement</th>
                                                    <th className="wafer-achievement text-center">Wafer Achievement</th>
                                                    <th className="snacks-achievement text-center">Snacks Achievement</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cateWiseDivision.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="division-name">{item.divisionName}</td>
                                                        <td className="biscuit-achievement text-center">{item.bisAch}%</td>
                                                        <td className="cookie-achievement text-center">{item.cookiAch}%</td>
                                                        <td className="cake-achievement text-center">{item.cakeAch}%</td>
                                                        <td className="wafer-achievement text-center">{item.waferAch}%</td>
                                                        <td className="snacks-achievement text-center">{item.snacksAch}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <FaTable
                                            onClick={handleLogoClick}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '10px',
                                                cursor: 'pointer',
                                                zIndex: 10,
                                            }}
                                        />
                                    </div>

                                ) : (
                                    <div style={{ position: 'relative' }}>
                                        <FaTable
                                            onClick={handleLogoClick}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '10px',
                                                cursor: 'pointer',
                                                zIndex: 10,
                                            }}
                                        />
                                        <Bar
                                            data={chartData}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Category-Wise Division Achievement',
                                                    },
                                                    datalabels: {
                                                        anchor: 'end',
                                                        align: 'bottom',
                                                        color: 'black',
                                                        formatter: (value) => {
                                                            return value;
                                                        },
                                                    },
                                                },
                                            }}
                                            height={400}
                                        />

                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategoryWiseDivisionData;
