
import * as Types from "../../types/Types";

const initialState = {
    rollbacklist: [],
    rollbackData: [],
    allCheckbox: false,
    unapprovedList: [],
    allReport: [],
    fundList: [],
    workingdayList: []
};
const FundReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case Types.GET_FUND_LIST:
            return {
                ...state,
                fundList: action.payload.data,
            };
            case Types.GET_WORKING_DAY_LIST:
                return {
                    ...state,
                    workingdayList: action.payload.data,
                };
        default:
            break;
    }
    return newState;
};

export default FundReducer;
