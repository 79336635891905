import React from "react";
import EmployeeSigningAdd from "../../components/hr/employee/signing/EmployeeSigningAdd";


const EmployeeSigningAddContainer = () => {
    return (
        <>
            <EmployeeSigningAdd />
        </>
    );
};

export default EmployeeSigningAddContainer;
