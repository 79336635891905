/*******************************************************************************************************
        Sub Module: Purchase Request / Comparative Statement
********************************************************************************************************/
export const GET_COMPARATIVE_STATEMENT_LIST = "GET_COMPARATIVE_STATEMENT_LIST";
export const COMPARATIVE_STATEMENT_INPUT_CHANGE = "COMPARATIVE_STATEMENT_INPUT_CHANGE";
export const COMPARATIVE_STATEMENT_SUBMIT = "COMPARATIVE_STATEMENT_SUBMIT";
export const GET_RQF_OPTION_LIST = "GET_RQF_OPTION_LIST";
export const COMPARATIVE_STATEMENT_SELECT_ITEM = "COMPARATIVE_STATEMENT_SELECT_ITEM";
export const CS_OPTION_LIST = "CS_OPTION_LIST";
export const UPDATED_CS = "UPDATED_CS";

