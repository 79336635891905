import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  delteProductList,
  getDistributorList,
  getProductList,
  productSelecteditem,
  itemAddInput,
  InputHandle,
  getCustomerBalance,
  cleanRemoteSalesData,
  GetNewCustomerBalanceByID,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { productSubmitStausRemove } from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { getSubProductList, getSuperDepChildDis, GetSuperDistributor, InsertSubDisSalesOrder, SubproductQuantity } from "../../_redux/actions/RemoteSales/SuperDepoAction";
import { GetAllZoneForEntryWithoutPC } from "../../_redux/actions/Zone/ZoneAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const SuperDepoDemand = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.subDistri.itemDataInput);
  const productCost = useSelector((state) => state.subDistri.productCost);
  const fillterProductList = useSelector(
    (state) => state.subDistri.filterProduct
  );
  const productList = useSelector((state) => state.subDistri.productList);
  console.log('productList', productList);
  const submitLoading = useSelector((state) => state.subDistri.submitLoading);
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const availableBalance = useSelector(
    (state) => state.subDistri.availableBalance
  );
  const restBalance = useSelector(
    (state) => state.subDistri.restBalance
  );

  const creditLimit = useSelector((state) => state.subDistri.creditLimit);
  const countProduct = useSelector((state) => state.subDistri.productQuantity);
  const balanceLoader = useSelector((state) => state.subDistri.balanceLoader);
  const submitData = useSelector((state) => state.subDistri.submitData);
  const statusProduct = useSelector((state) => state.subDistri.productSubmitStatus);

  console.log('statusProduct', statusProduct);

  const distributionSalesGroupId = useSelector((state) => state.remoteSales.distributionSalesGroupId);
  console.log('distributionSalesGroupId', distributionSalesGroupId);

  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);
  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);

  console.log('NewCustomerBalance', NewCustomerBalance)

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [btndisabled, setbtnDisabled] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);
  const [distribType, setDistribType] = useState(null);
  const [superDistribId, setSuperDistribId] = useState();
  const [subDistribId, setSubDistribId] = useState();
  const [superDisPointId, setSuperDisPointId] = useState();
  const [SuperDistribDispoint, setSuperDistribDispoint] = useState();
  const [startDate, setStartDate] = useState(null);

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const [salesGroupId, setSalesGroupId] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  console.log('selectTerritory', selectTerritory)
  console.log('superDistribId :>> ', superDistribId);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    //dispatch(getSubProductList(user.employeeType));
    dispatch(cleanRemoteSalesData());
  }, [])

  useEffect(() => {
    if (statusProduct) {
      setbtnDisabled(true)
      setTimeout(() => { history.go(0) }, 3000);
    } else {
      setbtnDisabled(false)
    }
  }, [statusProduct]);

  const subDistrib = useSelector(
    (state) => state.subDistri.subDistributor
  );
  console.log('subDistrib', subDistrib)
  const superdistributor = useSelector(
    (state) => state.subDistri.superdistributor
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('resNew :>> ', res.data);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, employeeType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

        setSalesType(employeeType);
        setDistribType(employeeType);

        dispatch(getSuperDepChildDis('1844', territoryId));
        dispatch(GetSuperDistributor(territoryId));

      });
  
    let zoneData = await GetAllZoneForEntryWithoutPC();
    Setzone(zoneData.data);
  }


  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (item, name, value) => {
    dispatch(SubproductQuantity(item, name, value));
  };



  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };


  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  }


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");
    //dispatch(getDistributorList(selectArea, value));
    //dispatch(getProductList(value));
  }

  const onSubmit = async (e) => {
    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (NewCustomerBalance?.cusid == "") {
      alert("Please select sub distributor");
      return false;
    }

    console.log('salesGroupId', salesGroupId);

    // setDisabled(true);
    console.log('submitData', submitData);
    dispatch(InsertSubDisSalesOrder(NewCustomerBalance, productList, selectZone, selectDivision, selectArea, selectTerritory, subDistribId, startDate, salesGroupId,superDistribId,SuperDistribDispoint));
    // dispatch(MainCertificateCreateAction(certificateInfoInput));
  };

 
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);

  };


  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <h3 className="mb-0 pb-0">Super Depo Demand</h3>
              </div>
            </div>
            {/* Check sales type  0 for all 1 for Biscuite and 3 for Bread */}


          </div>

          <hr></hr>
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Deadweight"
                  name="fromDate"
                  className="fromStyle"
                  onChange={handleChange}
                  value={startDate}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    
                   setdefultDivision(null);
                    setdefaultArea(null);
                   setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    
                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    
                    setdefultTerritory(null);
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Territory</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={territoryDropdownData()}
                  value={defultTerritory}
                  onChange={(option) => {
                   
                    setdefultTerritory(option)
                    setSelectTerritory(option?.value);
                    dispatch(GetSuperDistributor(option?.value));
                    dispatch(getSuperDepChildDis('1844', option?.value));
                    

                  }}
                  defaultValue={defultTerritory}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3">
              <label className="form-label">Super Depo</label>
              <RHFInput
                as={<Select options={superdistributor} />}
                rules={{ required: false }}

                register={register}
                value=""
                onChange={(option) => {
                  console.log('superoption :>> ', option);
                  
                 
                  dispatch(getSuperDepChildDis('1844', selectTerritory));

                }}
                setValue={setValue}
              />
            </div>
            <div className="col-lg-3">
              <label className="form-label">SUB DISTRIBUTOR</label>
              <RHFInput
                as={<Select options={subDistrib} />}
                rules={{ required: false }}
                name="intDistributorId"
                register={register}

                onChange={(option) => {
                  console.log('suboption', option);
                  inputChangeHandle("subDistribName", option.label);
                  inputChangeHandle("supperDistribId", option.value);
                  setSalesGroupId(option.salesGroupId);
                  
                  dispatch(GetNewCustomerBalanceByID(option.value));
                  dispatch(getSubProductList(98, option?.value, option?.salesGroupId, option?.superDis, option?.superDistibId));
                  setSubDistribId(option?.value);
                  setSuperDistribId(option?.superDis);
                  setSuperDistribDispoint(option?.superDistibId);
                }}
                setValue={setValue}
              />
            </div>


          </div>
        </div>
        <div className="container-fluid mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left" >
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p className="balanceleft">Available Balance</p>
                  <p className="balanceleft">Rest Balance</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <div>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                    </div>

                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Credit Limit</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Product Value</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Quantity(Pieces)</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {productList.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Stock Qty</th>

                  <th>Quantity</th>
                  <th>Free Qty</th>
                  <th>Damage Qty</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{item.stockQty}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.quantity}
                            onChange={(e) =>
                              changeProductQuantity(item, 'itemQuentity', e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="bonusQty"
                            value={item.bonusQnty}
                            onChange={(e) =>
                              changeProductQuantity(item, 'BonusQty', e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="damageQty"
                            name="damageQty"
                            // value={item.damageQty}
                            onChange={(e) =>
                              changeProductQuantity(item, 'damageQty', e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                {/* {
                  submitLoading?
                  <button
                 
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit .....
                </button>:  */}
                <button
                  type="submit"
                  disabled={btndisabled}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button>
                {/* } */}

              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default SuperDepoDemand;
