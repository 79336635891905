import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
    delteProductList,
    getDistributorList,
    productSelecteditem,
    itemAddInput,
    productQuantity,
    InputHandle,
    getCustomerBalance,
    cleanRemoteSalesData,
    getCorporateProductList,
    corporateSalesSubmit,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDistributorAddress, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";

const CoorporateSalesAddressEntry = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { register, handleSubmit, errors, setValue, setFocus } = useForm();

    const itemDataInput = useSelector((state) => state.remoteSales.itemDataInput);
    console.log('itemDataInput :>> ', itemDataInput);
    const productCost = useSelector((state) => state.remoteSales.productCost);
    const fillterProductList = useSelector(
        (state) => state.remoteSales.filterProduct
    );
    const productList = useSelector((state) => state.remoteSales.productList);
    const submitLoading = useSelector((state) => state.remoteSales.submitLoading);
    const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
    const availableBalance = useSelector(
        (state) => state.remoteSales.availableBalance
    );
    const restBalance = useSelector(
        (state) => state.remoteSales.restBalance
    );



    const creditLimit = useSelector((state) => state.remoteSales.creditLimit);
    const countProduct = useSelector((state) => state.remoteSales.productQuantity);
    const balanceLoader = useSelector((state) => state.remoteSales.balanceLoader);
    const submitData = useSelector((state) => state.remoteSales.submitData);
    const productSubmitStatus = useSelector(
        (state) => state.remoteSales.productSubmitStatus
    );


    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [distribAddress, setDistribAddress] = useState([]);
    const [startDate, setStartDate] = useState(null);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectDistribAddress, setSelectDistribAddress] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [salesType, setSalesType] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [distribType, setDistribType] = useState(null);
    const [poNo, setPoNo] = useState('');

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);

        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        // dispatch(getProductList(user.employeeType));
        dispatch(cleanRemoteSalesData());
    }, [])
    // Redirect List Page after submitting data
    useEffect(() => {
    }, [productSubmitStatus]);

    const distributorList = useSelector(
        (state) => state.remoteSales.distributorList
    );

    const changeText = (name, value) => {
        dispatch(itemAddInput(name, value));
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };
    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);
    }

    function productSelected(productItem) {
        dispatch(productSelecteditem(productItem));
    }

    const delteItem = (itemId) => {
        dispatch(delteProductList(itemId));
    };
    const changeProductQuantity = (name, value) => {
        dispatch(productQuantity(name, value));
    };

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");
        return options;
    };
    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id);
        SetDivision(divisionData.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id);
        setArea(areaData.data);
    }
    const getDistributorAddr = async (id) => {
        let distribData = await getDistributorAddress(id);
        setDistribAddress(distribData.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const distribAddressDropdownData = () => {
        let options = [];
        if (distribAddress?.length > 0) {
            distribAddress.forEach((item) => {
                let itemData = {
                    value: item.distribAddressID,
                    label: item.distribAddressName,
                };
                options.push(itemData);
            });
        }
        return options;
    };

    const getSalesValueType = (name, value) => {
        setSalesType(value);
        setValue("intDistributorId", "");
        setValue("divisionData", "");
        setValue("areaData", "");
    }

    const onSubmit = async (e) => {
        if (selectZone == null) {
            alert("Please select Zone");
            return false;
        } else if (selectDivision == null) {
            alert("Please select Division");
            return false;
        } else if (selectArea == null) {
            alert("Please select Area");
            return false;
        } else if (itemDataInput.intDistributorId == "") {
            alert("Please select Distribution");
            return false;
        }
        setDisabled(true);
        dispatch(corporateSalesSubmit(submitData, productList, selectZone, selectDivision, selectArea, selectTerritory, startDate, selectDistribAddress));
    };


    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };



    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);

    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData.data);
    }

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <span className="button-010">Corporate Sales Distributor Address Entry</span>
                            </div>
                        </div>
                        <SimpleSalesType
                            distribType={distribType}
                            salesType={salesType}
                            getSalesValue={getSalesValueType}
                        />
                    </div>
                    <hr></hr>
                    <div className="form-group row"></div>
                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Zones</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={zoneDropdownData()} />}
                                    rules={{ required: false }}
                                    name="zoneData"
                                    register={register}
                                    onChange={(option) => {
                                        divisionList(option.value);
                                        setSelectZone(option.value);
                                        setValue("divisionData", "");
                                        setValue("areaData", "");
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Division</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={divisionDropdownData()} />}
                                    rules={{ required: false }}
                                    name="divisionData"
                                    register={register}
                                    value=""
                                    onChange={(option) => {
                                        getAreaData(option.value);
                                        setSelectDivision(option.value);
                                        setValue("areaData", "");
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Region</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={areaDropdownData()} />}
                                    rules={{ required: false }}
                                    name="areaData"
                                    register={register}
                                    onChange={(option) => {
                                        getTerritoryData(option.value)
                                        setSelectArea(option.value);
                                        setValue("territory", "");
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Territory</label>
                                <RHFInput
                                    className="formSelect pt-0"
                                    as={<Select options={territoryDropdownData()} />}
                                    rules={{ required: false }}
                                    name="territory"
                                    register={register}
                                    onChange={(option) => {
                                        setSelectTerritory(option.value);
                                        dispatch(getDistributorList(option.value, salesType));
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">

                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Distributor</label>
                                <RHFInput
                                    as={<Select options={distributorList} />}
                                    rules={{ required: false }}
                                    name="intDistributorId"
                                    register={register}
                                    value=""
                                    onChange={(option) => {
                                        inputChangeHandle("distributorName", option.label);
                                        inputChangeHandle("intDistributorId", option.value);
                                        dispatch(getCustomerBalance(option.value));
                                        dispatch(getCorporateProductList(startDate, option.value, salesType));
                                        getDistributorAddr(option.value);
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Select Distributor address</label>
                                <RHFInput
                                    as={<Select options={distribAddressDropdownData()} />}
                                    rules={{ required: false }}
                                    name="distribAddressID"
                                    register={register}
                                    onChange={(option) => {
                                        inputChangeHandle("distribAddressName", option.label);
                                        inputChangeHandle("distribAddressID", option.value);
                                        setSelectDistribAddress(option.value);
                                    }}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label ml-4">Type Address</label>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Control
                                    as="textarea"
                                    rows="1"
                                    autoComplete="off"
                                    type="textarea"
                                    placeholder="Type Here"
                                    name="typeAddress"
                                    // value={itemDataInputSMSTEM.typeAddress}
                                    style={{ fontSize: "15px" }}
                                    className="italic-placeholder cuscard bgtypehere"
                                    onChange={(e) => {
                                        inputChangeHandle("typeAddress", e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CoorporateSalesAddressEntry;
