import React from "react";
import { useSelector } from "react-redux";
import DotLoading from "../../../../master/spinner/DotLoading";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const Money2 = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const dashboardInfo = useSelector(
    (state) => state.dashboardcart.dashboardData
  );
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const Measurement = reportData.measurement;
  const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);

  const formatValueWithCommas = (value) => {
    return value.toString().replace(/(\d)(?=(\d{2})+\d$)/g, "$1,");
  };

  const average =
    dashboardInfo?.totalSecondaryTargetValue - dashboardInfo?.totalSecondaryValue;
  const remaining = dashboardInfo?.totalDays - dashboardInfo?.currentDays;
  const workingPassed = parseInt(
    (dashboardInfo?.totalDays - remaining) / dashboardInfo?.totalDays * 100
  );
  const restavg = Math.floor(
    (dashboardInfo?.totalSecondaryTargetValue - dashboardInfo?.totalSecondaryValue) / remaining
  );
  const restavgqty = Math.floor(
    (dashboardInfo?.targetSecondaryQty - dashboardInfo?.totalSecondaryQty) / remaining
  );

  const color = restavg < 0 ? "#006622" : "#976e3f";

  return (
    <div className="money" style={{ height: isLoading ? '280px' : 'auto' }}>
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <DotLoading />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="sub_card">
              <p className="mb-4 moneyheading2 fontfy">SECONDARY</p>
            </div>

            <div className="sub_card">
              <span>
                <h6 className="txtwidth totaltargetSec fontfy">TOTAL TARGET</h6>
                <h2 className="text-secondary-money">
                  {formatValueWithCommas(
                    isNaN(parseInt(
                      Measurement === 1
                        ? dashboardInfo?.totalSecondaryTargetValue
                        : dashboardInfo?.targetSecondaryQty
                    )) ? 0 : parseInt(
                      Measurement === 1
                        ? dashboardInfo?.totalSecondaryTargetValue
                        : dashboardInfo?.targetSecondaryQty
                    )
                  )}

                </h2>
              </span>
            </div>
            <div className="sub_card">
              <span>
                <h5 className="txtwidth totaltargetSec fontfy"></h5>
                <h2 className="text-secondary-money p-6">

                </h2>
              </span>
            </div>
            <div className="sub_card">
              <span className="moneyacheived">
                <h6 className="txtwidth fontfy">DELIVERY{" "}</h6>
                <h2 className="text-4th">
                  {formatValueWithCommas(
                    isNaN(parseInt(
                      Measurement === 1
                        ? dashboardInfo?.totalSecondaryValue
                        : dashboardInfo?.totalSecondaryQty
                    )) ? 0 : parseInt(
                      Measurement === 1
                        ? dashboardInfo?.totalSecondaryValue
                        : dashboardInfo?.totalSecondaryQty
                    )
                  )}

                </h2>
              </span>
            </div>
            <div className="sub_card">
            <h6 className="txtwidth rdtcolor fontfy">RDT</h6>
              {year === dashboardInfo?.currentYear &&
                month === dashboardInfo?.currentMonth ? (
                <h2 className="text-2nd">
                  {restavg > 0 ? (
                    <div style={{ color: color }}>
                      {Measurement === 1
                        ? formatValueWithCommas(restavg)
                        : formatValueWithCommas(restavgqty)}
                    </div>
                  ) : (
                    <div style={{ color: color }}>0</div>
                  )}
                </h2>
              ) : (
                <h2 className="text-2nd rdtcolor">0</h2>
              )}
            </div>
            {year === dashboardInfo?.currentYear &&
              month === dashboardInfo?.currentMonth ? (
              <div className="sub_card">
                <h2 className="text-3rd">{remaining} W. DAYS LEFT</h2>
              </div>
            ) : (
              <h2 className="text-3rd">0 W. DAYS LEFT</h2>
            )}
            {year === dashboardInfo?.currentYear &&
              month === dashboardInfo?.currentMonth ? (
              <div className="">
                <h2 className="wpassed">{workingPassed}% T.PASSED</h2>
              </div>
            ) : (
              <h2 className="wpassed">100% T.PASSED</h2>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Money2;
