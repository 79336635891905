import React from "react";
import CertificateMainEdit from "../components/edit/CertificateMainEdit";

const CertificateMainEditContainer = () => {
  return (
    <>
      <CertificateMainEdit />
      <br />
    </>
  );
};

export default CertificateMainEditContainer;
