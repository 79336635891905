import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InputHandle } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { getAreaListByDivisionEntry, getDivisionListByZoneEntry, getTerritoryEntry, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { GetABLMOnthWiseData, GetCategoryType, GetDamageData, GetDashboardTargetDate, GetDSMType, GetLocationWiseSalesForDonut, GetNSMType, GetPermissionDropDown, GetProductGroup, GetProductListByCategory, GetProductListByGroup, GetRMType, getSoList, GetTSMType, InputReportHandle } from "./DashboardCartAction";
import { FaSearch } from 'react-icons/fa';
import { GetIMSFocusSKUTarAcv, GetTopSellingSku } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";


const DashboardInput = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const dispatch = useDispatch();

    const categorytype = useSelector((state) => state.dashboardcart.ddcategorytype);
    const proLinebyPro = useSelector((state) => state.dashboardcart.proLinebyPro);
    const productGroupType = useSelector((state) => state.dashboardcart.productGroupType);

    const topSellingSkuList = useSelector((state) => state.secondarySales.topSellingSkuList);
    console.log('topSellingSkuList', topSellingSkuList)

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState();
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [productlist, SetProductlist] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectCategory, setSelectCategoryType] = useState(null);
    const [selectProductType, setSelectProductType] = useState(null);
    const [selectcategoryName, setSelectCategoryName] = useState(null);
    const [selectProductGroupType, setSelectProductGroupType] = useState(null);
    const [selectProductionLine, setSelectProductionLine] = useState(null);

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});


    const isFirstRender = useRef(true)
    const [soData, setSodata] = useState([]);
    const [selectSo, setSelectSoPoint] = useState(null)

    const options = [
        { value: "1", label: "Value" },
        { value: "2", label: "Volume" },
    ];

    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };

    const handleChange = (name, value) => {
        dispatch(InputReportHandle(name, value));
    };

    useEffect(() => {

        initialData();
    }, [])

    const initialData = async () => {
        var d = new Date();
        var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
        var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";


        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);

                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

                dispatch(GetDashboardTargetDate(zoneId, divisionId, araId, territoryId, firstD, currentD, selectCategory, selectProductType, selectProductGroupType, selectProductionLine, selectSo));
                // dispatch(GetABLMOnthWiseData(zoneId, divisionId, araId, territoryId, firstD, currentD, selectCategory, selectProductType, selectProductGroupType, selectProductionLine, selectSo));
                 dispatch(GetDamageData(zoneId, divisionId, araId, territoryId, firstD, currentD, selectCategory, selectProductType, selectProductGroupType, selectProductionLine, selectSo));
                dispatch(GetLocationWiseSalesForDonut(zoneId, divisionId, araId, territoryId, firstD, currentD, selectCategory, selectProductType, selectProductGroupType, selectProductionLine));
                dispatch(GetCategoryType());
                dispatch(GetProductGroup());
                dispatch(GetProductListByCategory());
                dispatch(GetProductListByGroup());
                dispatch(GetTopSellingSku(firstD, currentD, zoneId, divisionId, araId, territoryId, 2));
                dispatch(GetIMSFocusSKUTarAcv(firstD, currentD, zoneId, divisionId, araId, territoryId, 2));

            });
        let zoneData = await getZoneListEntry();
        Setzone(zoneData.data);
    }

    const soListData = async (id) => {
        let soDataList = await getSoList(id, selectGeoType);
        setSodata(soDataList.data);
    }

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData.data);
    }
    const soDropDownData = () => {
        let options = [];
        if (soData?.length > 0) {
            soData.forEach((item) => {
                let itemData = {
                    value: item.soid,
                    label: item.soName,
                };

                options.push(itemData);
            });
        }
        return options;
    }

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };
    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const showValidation = () => {
        if (startDate == null || startDate == "") {
            alert("Please Select From Date");
            return false;
        }
        else if (toDate == null || toDate == "") {
            alert("Please select To Date");
            return false;
        }
        else {
            return true;
        }
    }

    const handleShowReport = () => {
        const validation = showValidation();
        if (validation) {
            dispatch(GetDashboardTargetDate(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, selectCategory, selectProductType, selectProductGroupType, selectProductionLine, selectSo));
        //    dispatch(GetABLMOnthWiseData(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, selectCategory, selectProductType, selectProductGroupType, selectProductionLine, selectSo));
            dispatch(GetDamageData(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, selectCategory, selectProductType, selectProductGroupType, selectProductionLine, selectSo));
            dispatch(GetLocationWiseSalesForDonut(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, selectCategory, selectProductType, selectProductGroupType, selectProductionLine));
            dispatch(GetTopSellingSku(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, 2));
            dispatch(GetIMSFocusSKUTarAcv(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, 2));
        }
    }
    const handleChangeStartDate = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };

    const handleChangeTodate = ({ currentTarget: input }) => {
        setToDate(input.value);
    };

    return (
        <div>

            <div className="card p-3 dashboardCart text-left">

                <div className="form-group row">
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">From Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="form-control cusheight"
                                onChange={handleChangeStartDate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label"> To Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Todate"
                                name="todate"
                                value={toDate}
                                className="form-control cusheight "
                                onChange={handleChangeTodate}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Measurement</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={options} />}
                                rules={{ required: false }}
                                
                                name="measurement "
                                register={register}
                                isClearable
                                onChange={(option) => handleChange('measurement', option?.value)}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label ml-4">Select Zone</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={zoneDropdownData()}
                                value={defultZone}
                                isClearable
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectZone(option?.value);
                                    setDefaultZone(option)
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                defaultValue={defultZone}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label ml-4">Select Division</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={divisionDropdownData()}
                                value={defultDivision}
                                isClearable
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setdefultDivision(option)
                                    setValue("areaData", "");
                                    setValue("territory", "");

                                }}
                                defaultValue={defultDivision}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">

                        <label className="form-label ml-4">Select Region</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={areaDropdownData()}
                                value={defaultArea}
                                isClearable
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setdefaultArea(option)
                                    setValue("territory", "");
                                }}
                                defaultValue={defaultArea}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label ml-4">Select Territory</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={territoryDropdownData()}
                                value={defultTerritory}
                                isClearable
                                onChange={(option) => {
                                    setSelectTerritory(option?.value);
                                    setdefultTerritory(option)
                                    soListData(option?.value);
                                    setValue("soData", "");
                                }}
                                defaultValue={defultTerritory}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Sales Group</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={productGroupType} />}
                                rules={{ required: false }}
                                name="productGroupType"
                                register={register}
                                placeholder="Select Product Group"
                                isClearable
                                onChange={(option) => {
                                    handleChange('productGroupType', option?.value);
                                    handleChange('salesGroupName', option?.label);
                                    setSelectProductGroupType(option?.value);
                                    setValue("categoryType", "");
                                    setValue("productType", "");
                                    dispatch(GetProductListByGroup(option?.value));
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Category</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={categorytype} />}
                                rules={{ required: false }}
                                name="categoryType"
                                register={register}
                                placeholder="Select Category type"
                                isClearable
                                onChange={(option) => {
                                    handleChange('categoryType', option?.value);
                                    handleChange('categoryName', option?.label);
                                    setSelectCategoryType(option?.value);
                                    setSelectProductGroupType(null)
                                    dispatch(GetProductListByCategory(option?.value, startDate, toDate));
                                    setValue("productType", "");
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">SKU</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={proLinebyPro} />}
                                rules={{ required: false }}
                                name="productType"
                                register={register}
                                isClearable
                                placeholder="Select Product type"
                                onChange={(option) => {
                                    handleChange('productType', option?.value);
                                    setSelectProductType(option?.value);
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">SO</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={soDropDownData()} />}
                                rules={{ required: false }}
                                name="soData"
                                isClearable
                                register={register}
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectSoPoint(option?.value);
                                }}
                                setValue={setValue}
                            />

                        </Form.Group>
                    </div>

                    <div className="col-lg-2">
                        <Button className="mt-6" size="sm" onClick={() => handleShowReport()}><FaSearch /> Show Report</Button>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DashboardInput