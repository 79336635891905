import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  getSalesList,
  salesDelete,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import RemoteSalesDetails from "./RemoteSalesDetails";
import { InputHandle } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { getDamageList, getDamageSalesList } from "../../_redux/actions/RemoteSales/DamageDemandAction";
import {clearDamageList, damageDelete, getDamageListForDamage, GetSattlementDamageList, handleDamageApproved, handleSattlementDamageApproved, SattlementDamageDelete } from "../../_redux/actions/RemoteSales/DamageAction";
import DamageListDetails from "./DamageListDetails";
import SattlementDamageListDetails from "./SattlementDamageListDetails";

const SattlementDamageForApproved = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const damageList = useSelector((state) => state.damage.damageList);
  console.log('damage-damageList', damageList);
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  useEffect(() => {
    dispatch(clearDamageList());

  }, []);


  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };



  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemCode, setItemCode] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/SattlementDamageEdit/${item.orderId}/${item.custName}/${item.custId}`);
  }
  const showReport = () => {
    dispatch(GetSattlementDamageList(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, "N", salesType));
  };
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const approvedReport = (item) => {
    console.log('damage item :>> ', item);
    dispatch(handleSattlementDamageApproved(item,selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, "N", salesType));
  }

  const ItemSingleApproved = (item) => {
    confirmAlert({
      title: "Confirm To Approved",
      message: `Are you sure to Confirm?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => approvedReport(item)
        },
        {
          label: "No",
        },
      ],
    });

    // let data =  dispatch(handleSubmitAction(item,approvedData,item.distID));
  }

  const handleDeleteItem = (item) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(item),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const confirmDelete = (item) => {
    dispatch(SattlementDamageDelete(item,selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate, "N", salesType));
  };
  const itemDetail = (item) => {
    console.log('item :>> ', item);
    setItemifo(item);
    setItemID(item.orderCode);
    setItemCode(item.orderId);
    setItemDetailShow(true);
  };

  console.log('itemCode', itemCode);

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        {/* <div className="container"> */}
        {/* <RemoteSalesFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}
        /> */}
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Sattlement Damage For Approved</h3>
            </div>
          </div>
          {/* <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType==1?true:false}  onChange={(e)=>getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType==3?true:false} onChange={(e)=>getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
                </div>
              </div> */}
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>

        <div className="form-group row">
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  // dispatch(getDamageListForDamage(selectZone,option?.value, salesType));

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Button variant="primary" onClick={() => showReport()} size="sm" className="mt-6 mb-5">
              Show Report
            </Button>
          </div>

        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
              className="shortstickyTable"
              style={{ height: damageList?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table">
                <thead>
                  <tr>
                    {/* <th scope="col">Department</th> */}
                    <th scope="col">SL</th>
                    <th scope="col">ORDER CODE</th>
                    <th scope="col">CUSTOMER ID</th>
                    <th scope="col">CUSTOMER NAME</th>
                    <th scope="col">SALES TYPE</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {damageList.length > 0 &&
                    damageList.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{index + 1}</td>
                        <td>{item.orderCode}</td>
                        <td>{item.custId}</td>
                        <td>{item.custName}</td>
                        <td>{item.salesType == "D" ? "Damage" : "General"}</td>
                        <td>{item.totalQuenty}</td>
                        <td>

                          <Link onClick={() => itemDetail(item)} title="Details">
                            <i className="far fa-eye editIcon item-list-icon"></i>
                          </Link>
                          <Link onClick={() => gotoEditPage(item)} title="Edit">
                            <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                          </Link>

                          <a
                            href
                            onClick={(id) => handleDeleteItem(item)}
                            title="Delete"
                          >
                            <i className="fas fa-trash-alt deleteIcon item-list-icon ml-4"></i>
                          </a>
                          <Link onClick={() => ItemSingleApproved(item)} title="Approved">
                            <i className="fa fa-check editIcon item-list-icon"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {!isLoading && damageList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Sattlement Damage Details"}
        >
          <SattlementDamageListDetails
            handleClose={() => setItemDetailShow(false)}
            itemCode={itemCode}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default SattlementDamageForApproved;
