import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCertificateMasterList } from "../../../../certificates/certificate-master/_redux/actions/CertificateListAction";
import DistributorReportsFilterBr from "../../../information/components/DistributorReports/DistributorReportsFilterBr";
import DistributorReportBrDataTableDemo from "./DistributorReportBrDataTableDemo";


const DistributorReportsBrContainerDemo = () => {
  const dispatch = useDispatch();
  const modalAddStatus = useSelector(
    (state) => state.CertificateListReducer.addStatus
  );
  const [show, setShow] = useState(false);
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getCertificateMasterList());
  }, []);

  useEffect(() => {
    if (modalAddStatus) {
      setShow(false);
    }
  }, [modalAddStatus]);

  return (
   <>
              <DistributorReportsFilterBr />
              {/* <DistributorReportBr /> */}
              <DistributorReportBrDataTableDemo />
           
      </> 
  );
};

export default DistributorReportsBrContainerDemo;
