import React from "react";
import VesselAdd from "../../components/vessel/VesselAdd";

const VesselAddContainer = () => {
  return (
    <>
      <VesselAdd />
    </>
  );
};

export default VesselAddContainer;
