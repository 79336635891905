import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

/** 
| Domain : DCO
*/
import authMenuPermissionReducer from "../app/modules/Auth/_redux/menu-permission/authMenuPermissionReducer";
import ControllingUnitReducer from "../domains/CCO/_redux/reducers/ControllingUnitReducer";
import EmployeeReducer from "../domains/CCO/_redux/reducers/EmployeeReducer";
import EmployeeEducationReducer from "../domains/CCO/_redux/reducers/EmployeeEducationReducer";
import EmployeeRecordReducer from "../domains/CCO/_redux/reducers/EmployeeRecordReducer";
import EmployeeDocumentReducer from "../domains/CCO/_redux/reducers/EmployeeDocumentReducer";
import EmployeeCertificateReducer from "../domains/CCO/_redux/reducers/EmployeeCertificateReducer";
import EmployeeBankDetailsReducer from "../domains/CCO/_redux/reducers/EmployeeBankDetailsReducer";
import EmployeeReferenceReducer from "../domains/CCO/_redux/reducers/EmployeeReferenceReducer";
import VesselReducer from "../domains/Vessel/_redux/reducers/VesselReducer";
import AdditionDeductionReducer from "../domains/CCO/_redux/reducers/AdditionDeductionReducer";
import EmployeeSigningReducer from "../domains/CCO/_redux/reducers/EmployeeSigningReducer";
import PurchaseRequisitionReducer from "../app/modules/SupplyChain/Procurement/_redux/reducers/PurchaseRequisitionReducer";
import EmployeePromotionReducer from "../domains/CCO/_redux/reducers/EmployeePromotionReducer";
import CurrencyReducer from "../domains/CCO/_redux/reducers/CurrencyReducer";
import DemandReducer from "../modules/demand-sheet/redux/reducers/DemandReducer";
import VesselItemReducer from "../domains/VesselItem/_redux/reducers/VesselItemReducer";
import VesselAccountReducer from "../domains/Vessel/_redux/reducers/VesselAccountReducer";
import VoyageReducer from "../modules/voyage/_redux/reducers/VoyageReducer";
import VoyageActivityReducer from "../modules/voyage/_redux/reducers/VoyageActivityReducer";
import VoyageActivityBoilerReducer from "../modules/voyage/_redux/reducers/VoyageActivityBoilerReducer";
import VoyageActivityAuxEngnReducer from "../modules/voyage/_redux/reducers/VoyageActivityAuxEngnReducer";
import VoyageActivityBunkerReducer from "../modules/voyage/_redux/reducers/VoyageActivityBunkerReducer";
import VoyageActivityOfficerReducer from "../modules/voyage/_redux/reducers/VoyageActivityOfficerReducer";
import VoyageActivityAuxEngn2Reducer from "../modules/voyage/_redux/reducers/VoyageActivityAuxEngn2Reducer";
import VoyageActivityAuxEngn3Reducer from "../modules/voyage/_redux/reducers/VoyageActivityAuxEngn3Reducer";
import CertificateMainReducer from "../modules/certificates/certificate-main/_redux/reducers/CertificateMainReducer";
import CertificateTypeReducer from "../modules/certificates/certificate-types/_redux/reducers/CertificateTypeReducer";
import CertificateIssueAuthorityReducer from "../modules/certificates/issue-authority/_redux/reducers/CertificateIssueAuthorityReducer";
import CertificateCategoryReducer from "../modules/certificates/certificate-category/_redux/reducers/CertificateCategoryReducer";
import CertificateListReducer from "../modules/certificates/certificate-master/_redux/reducers/CertificateListReducer";
import LaytimeInfoReducer from "../modules/voyage/laytime/_redux/reducers/LaytimeInfoReducer";
import LaytimeHeaderReducer from "../modules/voyage/laytime/_redux/reducers/LaytimeHeaderReducer";
import BankInfoReducer from "../modules/partners/bank-information/_redux/reducers/BankInfoReducer";
import PartnerAddressReducer from "../modules/partners/address/_redux/reducers/PartnerAddressReducer";
import LaytimeRowReducer from "../modules/voyage/laytime/_redux/reducers/LaytimeRowReducer";
import PartnerInfoReducer from "../modules/partners/basic-information/_redux/reducers/PartnerInfoReducer";
import OthersInfoReducer from "../modules/partners/others-information/_redux/reducers/OthersInfoReducer";
import SuppliersListReducer from "../modules/partners/suppliers-list/_redux/reducers/SuppliersListReducer";
import VesselBookingReducer from "../modules/voyage/booking/_redux/reducers/VesselBookingReducer";
import LaytimeMultipleReducer from "../modules/voyage/laytime/_redux/reducers/LaytimeMultipleReducer";
import LayTimeOperationRemarkReducer from "../modules/voyage/laytime/_redux/reducers/LaytimeOperationRemark";
import ItemReducer from "../modules/item/information/_redux/reducers/ItemReducer";
import PortReducer from "../modules/master/DropDownData/Port/_redux/PortReducer/PortReducer";
import VoyageTypeReducer from "../modules/master/DropDownData/VoyageType/_redux/VoyageTypeReducer/VoyageTypeReducer";
import CargoReducer from "../modules/master/DropDownData/Cargo/_redux/CargoReducer/CargoReducer";
import ShipReducer from "../modules/master/DropDownData/Ship/_redux/ShipReducer/ShipReducer";
import BookingStatusReducer from "../modules/master/DropDownData/BookingStatus/_redux/BookingStatusReducer/BookingStatusReducer";
import CharterReducer from "../modules/master/DropDownData/Charter/_redux/CharterReducer/CharterReducer";
import PurhasesRequestReducer from "../modules/Purchase/purhasesRequest/_redux/reducers/PurhasesRequestReducer";
import UtilityReducer from "../modules/utility/information/_redux/reducers/UtilityReducer";
import RolePermissionManagementReducer from "../modules/role-permission-management/information/_redux/reducers/RolePermissionManagementReducer";
import DepartmentReducer from "../modules/master/DropDownData/Department/_redux/DepartmentReducer/DepartmentReducer";
import PurchaseReuestCategoryReducer from "../modules/master/DropDownData/PurchaseReuestCategory/_redux/PurchaseReuestCategoryReducer/PurchaseReuestCategoryReducer";
import PurchasesOrderReducer from "../modules/Purchase/purhasesOrder/_redux/reducers/PurhasesOrderReducer";
import PurchaseApprovalReducer from "../modules/Purchase/PurchaseApproval/_redux/reducers/PurchaseApprovalReducer";
import POApprovalReducer from "../modules/Purchase/POApproval/_redux/reducers/POApprovalReducer";
import PaymentTermsReducer from "../modules/master/DropDownData/PaymentTerms/_redux/PaymentTermsReducer/PaymentTermsReducer";
import RefferenceTypeReducer from "../modules/master/DropDownData/RefferenceNo/_redux/RefferenceNoActionReducer/RefferenceNoActionReducer";
import CurrencyListReducer from "../modules/master/DropDownData/Currency/_redux/CurrencyReducer/CurrencyReducer";
import SupplierNameReducer from "../modules/master/DropDownData/SupplierName/_redux/SupplierNameReducer/SupplierNameReducer";
import ItemListReducer from "../modules/master/DropDownData/Item/_redux/ItemListReducer/ItemListReducer";
import SupplierCsReducer from "../modules/Purchase/SupplierCS/_redux/reducer/SupplierCsReducer";

/** 
| Domain : CCO
*/
// import authMenuPermissionReducer from "../app/modules/Auth/_redux/menu-permission/authMenuPermissionReducer";
import CatalogueListReducer from './../modules/master/DropDownData/Catalogue/_redux/CatalogueReducer/CatalogueReducer';
import UserRoleReducer from "../modules/role-permission-management/information/_redux/reducers/UserRoleReducer";
import ComparativeStatementReducer from "../modules/Purchase/comparativeStatement/_redux/reducers/ComparativeStatementReducer";
import QuotationFilterReducer from "../modules/Purchase/Quotation/_redux/reducers/QuotationFilterReducer";
import ZoneReducer from "../modules/Sales/information/_redux/reducers/Zone/ZoneReducer";
import RemoteSalesReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/RemoteSalesReducer";
import DistributorReportReducer from "../modules/Sales/information/_redux/reducers/DistributorReport/DistributorReportReducer";
import SecondarySalesReducers from "../modules/Sales/information/_redux/reducers/SecondarySales/SecondarySalesReducers";
import SecodnaryReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/SecodnaryReducer";
import BreadBurnReportReducer from "../modules/Sales/information/_redux/reducers/BreadBurnRemoteSales/Reports/BreadBurnReportReducer";
import DeliveryReducer from "../modules/Sales/information/_redux/reducers/Delivery/DeliveryReducer";
import TargetReducer from "../modules/Sales/information/_redux/reducers/Target/TargetReducer";
import StockReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/StockReducer";
import SecondarySalesFebReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/SecondarySalesFebReducer";
import SecondaryRollbackReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/SecondaryRollbackReducer";
import SubDepoReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/SubDepoReducer";
import FundReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/FundReducer";
import DashboardReducer from "../modules/Sales/information/_redux/reducers/Dashboard/DashboardReducer";
import PrimaryReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/PrimaryReducer";
import PromotionalReducer from "../modules/Sales/information/_redux/reducers/SecondarySales/PromotionalReducer";
import DamageDemandReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/DamageDemandReducer";
import DashboardCartReducer from "../modules/Sales/information/components/Dashboard/DashboardCartReducer";
import DamageReducer from "../modules/Sales/information/_redux/reducers/RemoteSales/DamageReducer";
import BreadBurnDashboardReducer from "../modules/Sales/information/_redux/reducers/Dashboard/BreadBurnDashboardReducer";
import SoDashboardReducer from "../modules/Sales/information/components/Dashboard/SoDashboardReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,

  /** Menu Reducers */
  menu: authMenuPermissionReducer,

  /** CCO Reducers */
  controllingUnit: ControllingUnitReducer,

  /** CCO Reducers */
  employeeInfo: EmployeeReducer,
  employeeEducationInfo: EmployeeEducationReducer,
  employeeRecordInfo: EmployeeRecordReducer,
  employeeDocumentInfo: EmployeeDocumentReducer,
  employeeCertificateInfo: EmployeeCertificateReducer,
  employeeBankDetailsInfo: EmployeeBankDetailsReducer,
  employeeReferenceInfo: EmployeeReferenceReducer,
  vesselInfo: VesselReducer,
  voyageInfo: VoyageReducer,
  voyageData: VoyageReducer,
  voyageActivityInfo: VoyageActivityReducer,
  VoyageActivityOfficerReducer: VoyageActivityOfficerReducer,
  additionDeductionInfo: AdditionDeductionReducer,
  vesselItemInfo: VesselItemReducer,
  employeeSigningInfo: EmployeeSigningReducer,
  currencyInfo: CurrencyReducer,
  vesselAccountInfo: VesselAccountReducer,
  VoyageActivityBoilerReducer: VoyageActivityBoilerReducer,
  voyageAux1: VoyageActivityAuxEngnReducer,
  voyageAux2: VoyageActivityAuxEngn2Reducer,
  voyageAux3: VoyageActivityAuxEngn3Reducer,
  VoyageActivityBunkerReducer: VoyageActivityBunkerReducer,


  /** Laytime Reducers */
  laytimeHeaderInfo: LaytimeHeaderReducer,
  laytimeDetailInfo: LaytimeRowReducer,
  LaytimeMultiple: LaytimeMultipleReducer,
  layTimeOperationRemark: LayTimeOperationRemarkReducer,

  //Vessel Booking 
  VesselBookingReducer: VesselBookingReducer,

  /** Partners Reducers */
  partnerInfo: PartnerInfoReducer,
  bankInfo: BankInfoReducer,
  partnerAddress: PartnerAddressReducer,
  partnerOthersInfo: OthersInfoReducer,
  supplierList: SuppliersListReducer,


  /** Procurement Module Reducers */
  purchaseRequisition: PurchaseRequisitionReducer,
  demand: DemandReducer,

  /** Promotion */
  promotion: EmployeePromotionReducer,

  /** Purchase */
  purchaseApprovalFilter: PurchaseApprovalReducer,
  POApprovalFilter: POApprovalReducer,



  /** Quotation */
  QuotationFilterinfo: QuotationFilterReducer,

  // master module reducer // dynamic data for all dropdown menu list 
  PortReducer: PortReducer,
  VoyageTypeReducer: VoyageTypeReducer,
  CargoReducer: CargoReducer,
  CharterReducer: CharterReducer,
  ShipReducer: ShipReducer,
  BookingStatusReducer: BookingStatusReducer,
  DepartmentReducer: DepartmentReducer,
  PurchaseReuestCategoryReducer: PurchaseReuestCategoryReducer,
  PaymentTermsReducer: PaymentTermsReducer,
  RefferenceTypeReducer: RefferenceTypeReducer,
  CurrencyListReducer: CurrencyListReducer,
  SupplierNameReducer: SupplierNameReducer,
  ItemListReducer: ItemListReducer,
  CatalogueListReducer: CatalogueListReducer,

  /**Certificates */
  certificateMainInfo: CertificateMainReducer,
  certificateIssueAuthorityInfo: CertificateIssueAuthorityReducer,
  CertificateCategoryReducer: CertificateCategoryReducer,
  CertificateListReducer: CertificateListReducer,
  certificateTypeInfo: CertificateTypeReducer,
  laytimeInfo: LaytimeInfoReducer,
  itemList: ItemReducer,

  //purchase 
  purchaseRequest: PurhasesRequestReducer,
  utitlityInfo: UtilityReducer,
  roleReducer: RolePermissionManagementReducer,

  /**Purchase Order */
  purchasesOrderInfo: PurchasesOrderReducer,
  supplierCsInfo: SupplierCsReducer,
  userRole: UserRoleReducer,

  // comparative statement 
  ComparativeStatementReducer: ComparativeStatementReducer,


  // Sales reducers start here
  zone: ZoneReducer,
  remoteSales: RemoteSalesReducer,
  disReport: DistributorReportReducer,
  secondarySales: SecondarySalesReducers,
  secondary: SecodnaryReducer,
  bradBurn: BreadBurnReportReducer,
  delivery: DeliveryReducer,
  target: TargetReducer,
  stock:StockReducer,
  secondaryFeb:SecondarySalesFebReducer,
  rollback:SecondaryRollbackReducer,
  subDistri:SubDepoReducer,
  fund:FundReducer,
  primary: PrimaryReducer,
  promotional: PromotionalReducer,

  // Dashboard reducers start here
  dashboard:DashboardReducer,

  // Damaged reducers start here
  damagedemand: DamageDemandReducer,

  //DashboradCart reducer
  dashboardcart :DashboardCartReducer,
  //damage
  damage: DamageReducer,
  //bradeburn
  breadburn: BreadBurnDashboardReducer,
  //soDashboard
  sodash: SoDashboardReducer


});

export function* rootSaga() {
  yield all([auth.saga()]);
}
