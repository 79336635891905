import React from "react";

const BasicItemInformationListContainer = () => {
  return (
    <>
      <h2>Item Basic Information</h2>
      <p>Testing Basic Information List Page</p>
    </>
  );
};

export default BasicItemInformationListContainer;
