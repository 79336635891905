import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { ActivateMenuStatus, DeactivatedMenu, GetAllMenuList, MenuPermissionSumbit } from '../../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction';
import NewLoader2 from '../../../../../modules/master/spinner/NewLoader2';
import { Button } from "react-bootstrap";
import { showToast } from '../../../../../modules/master/utils/ToastHelper';

// Define the item type for drag-and-drop
const ItemType = 'MENU_ITEM';

// Draggable Menu Item Component
const DraggableMenuItem = ({ menu, onClick, isDisabled }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType,
    item: { id: menu.id, name: menu.name },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !isDisabled,
  }));

  return (
    <div
      ref={drag}
      onClick={() => !isDisabled && onClick(menu)}
      className={`draggable-menu-item ${isDisabled ? 'disabled' : ''}`}
    >
      {menu.id}.{menu.name}
    </div>
  );
};

// Drop Zone Component
const DropZone = ({ onDrop, droppedItems, onRemove }) => {
  const [, drop] = useDrop(() => ({
    accept: ItemType,
    drop: (item) => onDrop(item),
  }));

  return (
    <div ref={drop} className="drop-zone">
      {droppedItems.length === 0 ? (
        <p className='drop-zone-p-section'>No Items Added/Dropped Yet</p>
      ) : (
        droppedItems.map((item) => (
          <div
            key={item.id}
            className="dropped-item"
            onClick={() => onRemove(item)}
          >
            {item.id}.{item.name}
          </div>
        ))
      )}
    </div>
  );
};

// Main MenuPermission Component
const MenuPermission = () => {
  const dispatch = useDispatch();
  const allMenuList = useSelector((state) => state.menu.allMenuList);
  const allMenuLoading = useSelector((state) => state.menu.allMenuLoading);

  const [droppedItems, setDroppedItems] = useState([]);
  const [disabledItems, setDisabledItems] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');

  const [enroll, setEnroll] = useState(null);
  console.log('enroll :>> ', enroll);

  console.log('droppedItems :>> ', droppedItems);

  useEffect(() => {
    dispatch(GetAllMenuList());
  }, [dispatch]);

  const handleDrop = (item) => {
    if (!disabledItems.has(item.id)) {
      setDroppedItems((prevItems) => [...prevItems, item]);
      setDisabledItems((prevSet) => new Set(prevSet.add(item.id)));
    }
  };

  const handleClick = (menu) => {
    if (!disabledItems.has(menu.id)) {
      setDroppedItems((prevItems) => [...prevItems, menu]);
      setDisabledItems((prevSet) => new Set(prevSet.add(menu.id)));
    }
  };

  const handleRemove = (item) => {
    setDroppedItems((prevItems) => prevItems.filter((i) => i.id !== item.id));
    setDisabledItems((prevSet) => {
      const updatedSet = new Set(prevSet);
      updatedSet.delete(item.id);
      return updatedSet;
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredMenuList = allMenuList.filter((menu) =>
    menu.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const submitdata = () => {
    if (enroll != null) {
      dispatch(MenuPermissionSumbit(enroll, droppedItems));
    } else {
      showToast("error", "Type Enroll");
    }
}

  const ActivateMenu = (menuid) => {
    dispatch(ActivateMenuStatus(menuid));
  }
  const Deactivate = (menuid) => {
    dispatch(DeactivatedMenu(menuid));
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="menu-permission-container">
        {allMenuLoading ? (
          <div className="loader-container">
            <NewLoader2 />
          </div>
        ) : (
          <div className="menu-sections">
            <div className="drag-and-drop-section">
              <h3>Added List</h3>
              <DropZone
                onDrop={handleDrop}
                droppedItems={droppedItems}
                onRemove={handleRemove}
              />
              {
                droppedItems.length > 0 &&
                <>
                  <Button className="btn btn-sm btn-success float-right mt-3" onClick={() => submitdata()}>Add Menu</Button>
                  <div className="col-lg-7 float-right mt-2 mr-3">
                    <Form.Group as={Row} controlId="formGridState">

                      <Form.Control
                        className=""
                        type="text"
                        placeholder='Type Enroll Here'
                        value={enroll}
                        name="enroll"
                        onChange={(e) => {
                          setEnroll(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>
                </>
              }

            </div>
            
            <div className="menu-list">
              <h3>All Menu List</h3>
              <div className="search-input-container">
                <input
                  type="text"
                  placeholder="Search By Menu Name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <span className="search-icon">🔍</span>
              </div>
              {filteredMenuList.map((menu) => (
                <React.Fragment key={menu.id}>
                  {menu.status === 'A' ? (
                    <Button
                      className="btn btn-sm float-right mt-2 mr-2"
                      style={{
                        width: '100px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none'
                      }}
                      onClick={() => Deactivate(menu.id)}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-sm float-right mt-2 mr-2"
                      style={{
                        width: '100px',
                        backgroundColor: '#66B266',
                        color: 'white',
                        border: 'none'
                      }}
                      onClick={() => ActivateMenu(menu.id)}
                    >
                      Active
                    </Button>
                  )}

                  <DraggableMenuItem
                    menu={menu}
                    onClick={handleClick}
                    isDisabled={disabledItems.has(menu.id)}
                  />
                </React.Fragment>
              ))}



            </div>

          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default MenuPermission;
