import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  delteProductList,
  getDistributorList,
  getProductList,
  productSelecteditem,
  itemAddInput,
  productQuantity,
  InputHandle,
  cleanRemoteSalesData,
  getSOListByArea,
  getSODetailsBySOID,
  getSOPointByArea,
  salesSubmitBySoPoint,
  getLockSystemData,
  getItemforStockProduct,
  getItemValidationforStockProduct
} from "../../_redux/actions/SecondarySales/SecondaryAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList, getZoneListEntry } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { getStatusList } from "../../_redux/actions/SecondarySales/SecondarySalesAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const NewSecondarySalesEntry = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.secondary.itemDataInput);
  const productCost = useSelector((state) => state.secondary.productCost);
  const fillterProductList = useSelector(
    (state) => state.secondary.filterProduct
  );
  const productList = useSelector((state) => state.secondary.productList);
  const ProductStockSum = useSelector((state) => state.secondary.ProductStockSum);
  console.log('productListNew', productList)
  const submitLoading = useSelector((state) => state.secondary.submitLoading);
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const availableBalance = useSelector(
    (state) => state.secondary.availableBalance
  );
  const restBalance = useSelector(
    (state) => state.secondary.restBalance
  );

  const creditLimit = useSelector((state) => state.secondary.creditLimit);
  const countProduct = useSelector((state) => state.secondary.productQuantity);
  const balanceLoader = useSelector((state) => state.secondary.balanceLoader);
  const submitData = useSelector((state) => state.secondary.submitData);
  const submitsecondaryStatus = useSelector((state) => state.secondary.submitsecondaryStatus);
  const productSubmitStatus = useSelector(
    (state) => state.secondary.productSubmitStatus
  );

  useEffect(() => {
    if (submitsecondaryStatus === true) {
      setTimeout(() => { history.go(0) }, 3000);
    }

  }, [submitsecondaryStatus]);

  console.log('submitsecondaryStatus :>> ', submitsecondaryStatus);


  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [soList, setSoList] = useState([]);
  const [sOPointid, setSoPointId] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectSo, setSelectSo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [distribType, setDistribType] = useState(null);
  let [distributionId, setdistributionId] = useState(null);
  const [soId, setSoId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [lockStatus, setLockStatus] = useState(null);
  console.log('lockStatus :>> ', lockStatus);
  const [memoData, setMemoData] = useState({
    orderValue: 0,
    totalmemoQty: 0,
    totalSkuQty: 0,
    visitedOutlet: 0
  });

  const [status, Setstatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState(5);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user?.employeeType);
    setDistribType(user?.employeeType);
    //dispatch(getProductList(user.employeeType));
    dispatch(cleanRemoteSalesData());
  }, [])
  // Redirect List Page after submitting data
  useEffect(() => {
    if (productSubmitStatus) {

      setTimeout(() => {
        setValue("intDistributorId", "");

        getSOListDate(selectArea, startDate);

        setDisabled(false);
      }, 3000);
      dispatch(getProductList());
      // dispatch(cleanRemoteSalesData());
    }
  }, [productSubmitStatus]);

  const initialSOData = async () => {
    // setDisabled(false);
    let soData = await getSOListByArea(selectArea, startDate);

    let soDetai = soData.data.slice();
    setSoList(soDetai);

  }


  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });



    let lockData = await getLockSystemData();
    setLockStatus(lockData.data[0]);
    let zoneData = await getZoneListEntry();
    Setzone(zoneData.data);
    let statusData = await getStatusList();
    Setstatus(statusData.data);
  }

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (name, value) => {
    dispatch(productQuantity(name, value));
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const statusDropdownData = () => {
    let options = [];
    if (status?.length > 0) {
      status.forEach((item) => {
        let itemData = {
          value: item.stausId,
          label: item.statusName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");
    dispatch(getDistributorList(selectArea, value));
    dispatch(getProductList(value));
  }

  const getSOListDate = async (value, date) => {
    // let solIst = await getSOListByArea(value,date);
    let solIst = await getSOPointByArea(value, selectTerritory, date);
    setSoList(solIst.data);
  }

  const soDropDownData = () => {
    let options = [];
    if (soList?.length > 0) {
      soList.forEach((item) => {
        let itemData = {
          value: item.soid,
          label: item.soName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  const handleDistribution = async (item) => {
    let distrInfo = await getSODetailsBySOID(item);
    setdistributionId(distrInfo?.data[0]);
    setSoId(item);
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    if (selectZone == null || selectZone == 0) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null || selectDivision == 0) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null || selectArea == 0) {
      alert("Please select Region");
      return false;
    } else if (selectTerritory == null || selectTerritory == 0) {
      alert("Please select Territory");
      return false;
    } else if (memoData.visitedOutlet > 999) {
      alert("Visited Outlet must less than 1000");
      return false;
    }
    else if (memoData.visitedOutlet == 0) {
      alert("Please Input T.Visited Outlet");
      return false;
    } else if (memoData.orderValue == 0) {
      alert("Please Input T.Order value ");
      return false;
    }
    else if (memoData.totalmemoQty == 0) {
      alert("Please Input T.Memo Qty");
      return false;
    }
    else if (memoData.totalmemoQty > 999) {
      alert("T.Memo Qty must less than 1000 ");
      return false;
    } else if (memoData.totalSkuQty == 0) {
      alert("Please Input Number of T.Memo Line ");
      return false;
    }
    else if (memoData.totalSkuQty > 999) {
      alert("T.Memo Line must less than 1000");
      return false;
    } else if (startDate == null || startDate == "") {
      alert("Please select Date");
      return false;
    } else if (sOPointid == null) {
      alert("Please select so Point id");
      return false;
    } else if (selectStatus == null) {
      alert("Please select status");
      return false;
    }
    else {
      setDisabled(true);
      dispatch(salesSubmitBySoPoint(sOPointid, memoData, productList, startDate, selectStatus));
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
    getSOListDate(selectArea, input.value);
  };

  const handleMemoData = (value, name) => {
    const cloneObj = { ...memoData };
    cloneObj[name] = value;
    setMemoData(cloneObj);
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);



  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const valueStyle = {
    color: 'yellow',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
  };


  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={onSubmit}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

          <div className="row mt-3">
            <div className="col-md-9 ml-2">
              <div className=" p-2">
                {/* <span className="button-010">Secondary Sales Entry</span> */}
                <h3 className="mb-0 pb-0">Secondary Sales Entry</h3>
              </div>
            </div>
          </div>

          {/* <hr></hr> */}
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    setdefultDivision(null);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");

                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    setdefultTerritory(null);
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Territory</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={territoryDropdownData()}
                  value={defultTerritory}

                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                    setdefultTerritory(option)
                    setValue("soData", "");
                    setValue("intDistributorId", "");

                    setStartDate(null);
                    setStartDate("");
                  }}
                  defaultValue={defultTerritory}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                {
                  lockStatus?.status == "Y" &&
                  <Form.Control

                    min={lockStatus?.lockTime}
                    max={lockStatus?.maxLock}
                    type="date"
                    placeholder="Enter Deadweight"
                    name="fromDate"
                    className="fromStyle"
                    onChange={handleChange}
                    value={startDate}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                }
                {
                  lockStatus?.status == "N" &&
                  <Form.Control
                    type="date"
                    placeholder="Enter Deadweight"
                    name="fromDate"
                    className="fromStyle"
                    onChange={handleChange}
                    value={startDate}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                }


              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select SO</label>
                <RHFInput
                  as={<Select options={soDropDownData()} />}
                  rules={{ required: false }}
                  name="intDistributorId"
                  register={register}
                  value=""
                  // value={itemDataInput.intDistributorId}
                  onChange={(option) => {
                    inputChangeHandle("distributorName", option.label);
                    inputChangeHandle("intDistributorId", option.value);

                    const userData = localStorage.getItem("userData");
                    const user = JSON.parse(userData);

                    dispatch(getItemforStockProduct(option.value, startDate, user?.employeeType));
                    dispatch(getProductList(user?.employeeType));
                    setSoPointId(option.value);
                  }}
                  setValue={setValue}
                />

              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">T.Visited Outlet</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Visited Outlet"
                  name="visitedOutlet"
                  className="fromStyle"
                  // min="1"
                  // max="999"
                  onChange={(e) => handleMemoData(e.target.value, 'visitedOutlet')}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 ">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">T.Order value</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Order value"
                  name="orderValue"
                  className="fromStyle"
                  onChange={(e) => handleMemoData(e.target.value, 'orderValue')}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 ">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">T.Memo Qty</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Memo Qty"
                  name="totalmemoQty"
                  className="fromStyle"
                  // min="1"
                  // max="999"
                  onChange={(e) => handleMemoData(e.target.value, 'totalmemoQty')}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Number of T.Memo Line</label>
                <Form.Control
                  type="number"
                  placeholder="Enter sku Qty"
                  name="totalSkuQty"
                  className="fromStyle"
                  // min="1"
                  // max="999"
                  onChange={(e) => handleMemoData(e.target.value, 'totalSkuQty')}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Delivery Status</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={statusDropdownData()} />}
                  rules={{ required: false }}
                  name="statusData"
                  register={register}
                  value=""
                  defaultValue={{ label: "Delivered", value: 5 }}
                  onChange={(option) => {
                    setSelectStatus(option.value)

                  }}

                  setValue={setValue}
                />
              </Form.Group>
            </div>


          </div>

          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <span className="paid__widget_New one text-center text-bold secondary-entry-label">Total Quantity(Pieces)</span>
                    <div className="achiveLayer">
                      <span style={containerStyle}>
                        <span style={valueStyle}>{numberFormatDecemal(countProduct)}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <span className="paid__widget_New two text-center text-bold secondary-entry-label">Total Stock Value</span>
                    <div className="achiveLayer">
                      <span style={containerStyle}>
                        <span style={valueStyle}>{numberFormatDecemal((ProductStockSum.toFixed(2)))}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          productList.length > 0 &&
          <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
            {/* {productList[0]?.netStock > 0 ? ( */}
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">

                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>Stock</th>
                  <th>Damage</th>
                  <th>Net Stock</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>

                <tbody>
                  {productList.filter(item => item?.prodStock !== 0).map((item, index) => (
                    // item.netStock > 0 && (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{parseInt(item.prodStock)}</td>
                      {/* <td>{parseInt(item.prodDamage)}</td>
                      <td className={item.netStock < 0 ? 'redText' : ''}>{parseInt(item.netStock)}</td> */}
                      <td>{isNaN(parseInt(item.prodDamage)) ? 0 : parseInt(item.prodDamage)}</td>
                      <td className={item.netStock < 0 ? 'redText' : ''}>{isNaN(parseInt(item.netStock)) ? 0 : parseInt(item.netStock)}</td>

                      <td>{parseFloat(item.prodTrdpricebypic).toFixed(2)}</td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={`${item.itemQuentityPic}`}
                            onChange={(e) => {
                              dispatch(getItemValidationforStockProduct(sOPointid, startDate, item));
                              changeProductQuantity(item, e.target.value)
                            }


                            }
                          />
                        </Form.Group>
                        <span><small className="validation-symbol"> {item.status == false ? "Sorry !! Stock is not avaiable" : ""} </small></span>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                    // )
                  ))}
                </tbody>
              </table>

              {
                productList.length > 0 &&
                <div className="col-lg-12 mt-5">
                  <div className=" mb-5">
                    <div className="col-lg-12">
                      <button
                        disabled={disabled}
                        type="submit"

                        className="btn btn-success btn-block"
                      >
                        Submit
                      </button>
                    </div>

                  </div>
                </div>
              }


              {/* <div className="col-lg-12 mt-5">
                <button
                 disabled={disabled}
                  type="submit"
                  
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button>
              </div> */}
            </div>
            {/* ) : null} */}
          </div>
        }

      </form>
    </>
  );
};

export default NewSecondarySalesEntry;
