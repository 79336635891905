import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
    itemAddInput,
    InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryAction";
import {getZoneList } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { handleWorkingDaysSubmit } from "../../_redux/actions/SecondarySales/FundAction";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import DatePicker, { DateObject } from "react-multi-date-picker";

const format = "YYYY-MM-DDTHH:mm:ss.sssZ";

const WorkingDay = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { register, handleSubmit, errors, setValue, setFocus, fund } = useForm();

    const itemDataInput = useSelector((state) => state.secondary.itemDataInput);
    const productCost = useSelector((state) => state.secondary.productCost);
    const fillterProductList = useSelector(
        (state) => state.secondary.filterProduct
    );
    const productList = useSelector((state) => state.secondary.productList);

    const submitLoading = useSelector((state) => state.secondary.submitLoading);
    const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
    const availableBalance = useSelector(
        (state) => state.secondary.availableBalance
    );
    const restBalance = useSelector(
        (state) => state.secondary.restBalance
    );
    const creditLimit = useSelector((state) => state.secondary.creditLimit);
    const countProduct = useSelector((state) => state.secondary.productQuantity);
    const balanceLoader = useSelector((state) => state.secondary.balanceLoader);
    const submitData = useSelector((state) => state.secondary.submitData);
    const productSubmitStatus = useSelector(
        (state) => state.secondary.productSubmitStatus
    );
    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [fundAmount, setFundAmount] = useState(null);

    const [multiDateValue, setMultiDateValue] = useState([
        // new DateObject().set({ day: 4, format }),
        // new DateObject().set({ day: 25, format }),
        // new DateObject().set({ day: 20, format })
    ]);


    // Redirect List Page after submitting data
    useEffect(() => {
        if (productSubmitStatus) {
            setTimeout(() => {

            }, 3000);

            // dispatch(cleanRemoteSalesData());
        }
    }, [productSubmitStatus]);


    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    }

    const changeText = (name, value) => {
        dispatch(itemAddInput(name, value));
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };

    const onSubmit = async (e) => {

        let dateArray = [];
        for (let i = 0; i < multiDateValue.length; i++) {
            let productData = {
                workingDays:null
            };
            productData.workingDays = multiDateValue[i].format();
            dateArray.push(productData)
        }

        const submit = handleWorkingDaysSubmit(dateArray);
    }


    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <h3 className="mb-0 pb-0">Working Days Report</h3>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <Form.Group as={Col} controlId="formGridState">
                                <p className="collectionDateText"> Collection Date: </p>

                                <DatePicker
                                    value={multiDateValue}
                                    multiple={true}
                                    onChange={setMultiDateValue}
                                    format={format}
                                    className="workingDays"
                                    plugins={[
                                        <DatePanel />
                                    ]}
                                />
                            </Form.Group>

                        </div>
                        <div className="col-lg-1">
                            <button type="submit" className="btn btn-primary btn-sm text-center mt-5 float-right">Submit</button>
                        </div>
                    </div>

                    <div className="row" id="id">
                        <div className="react-bootstrap-table table-responsive border-0 pl-5">
                            <table className="table table-head-custom table-vertical-center  item-add-table">
                                <thead >
                                    <tr>
                                        <th scope="col">SL</th>
                                        <th scope="col">DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {multiDateValue.map((date, index) => (
                                        <tr>
                                            <td >{index + 1}</td>
                                            <td key={index}>{date.format()}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>

            </form>
        </>
    );
};

export default WorkingDay;
