import React from 'react';
const LaytimeTotal = () => {
    return (
        <div className="row">
            <div className="offset-xl-4 offset-lg-4 empty-space">

            </div>
            <div className="float-left total">
                <h6 className="text-left">Total</h6>
            </div>
            <div className="float-left balance">
                <h6 className="text-left">80080 USD</h6>
            </div>
            <div className="float-left commision">
                <h6>80080 USD</h6>
            </div>
        </div>
    );
}

export default LaytimeTotal;