import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";


export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };

  dispatch({ type: Types.GET_FUND_INPUT, payload: formData });
};
export const handleFundSubmit = (startDate, selectZone, selectDivision, selectArea, selectTerritory, fundAmount, orderValue) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertFundCollection?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&fundDate=${startDate}&fundAmount=${fundAmount}&order=${orderValue}`;

    axios
      .post(postUrl)
      .then(function (response) {
        console.log('response :>> ', response);
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } 
        if (response.data.code === 400) {
          responseList.submitLoading = false;
          showToast("error", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }


};

export const handleWorkingDaysSubmit = (dateArray) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };


  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertWorkingDay`;

    axios
      .post(postUrl,dateArray)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }


};


export const InsertIMSFocudProduct = (ProductList) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };


  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertIMSFocudProduct`;

    axios
      .post(postUrl,ProductList)
      .then(function (response) {
        console.log('response', response)
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.data}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", `${response.data.data}`);
        }
      })
      .catch(function (error) {
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }


};

export const getFundList = (zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetFundCollecton?`;
  if (zoneId !== null) {

    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }
  if (fromDate !== null) {

    url += `&fromDate=${fromDate}`
  }
  if (toDate !== null) {

    url += `&toDate=${toDate}`
  }

  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_FUND_LIST, payload: responseList });
};

export const getWorkingDayList = (fromDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetWorkingDay?fundDate=${fromDate}`;
 
  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_WORKING_DAY_LIST, payload: responseList });
};