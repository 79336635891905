import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { GetMenuListsByPermission } from "../../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";
import { useState } from "react";
import { getEmployeData } from "../../../../../app/modules/Auth/_redux/authCrud";
import { GetImsMenuTrackingSystem } from "../../../../../modules/Sales/information/_redux/actions/SecondarySales/SecondaryAction";

export function AsideMenuList({ layoutProps }) {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [menuId, setMenuId] = useState(0);
  console.log('menuId', menuId)


  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  const menuList = useSelector((state) => state.menu.menuList);
  const isMenuLoading = useSelector((state) => state.menu.isMenuLoading);

  console.log('menuList', menuList)

  useEffect(() => {
    dispatch(GetMenuListsByPermission());
  }, [dispatch]);


  const ChangeMenuClick = (subMenu) => {
    getLocation(subMenu);
  };


  const getLocation = (subMenu) => {
    if ("geolocation" in navigator) {

      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        console.log("Latitude: ", latitude);
        console.log("Longitude: ", longitude);

        const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;

        fetch(url)
          .then(response => {
            if (!response.ok) {

              dispatch(GetImsMenuTrackingSystem(subMenu.name, "Click", "mac", subMenu.menuID, "0", "0", "Location Not Allowed"));
              throw new Error('Network response was not ok');

            }
            return response.json();
          })
          .then(data => {
            console.log('Location Data:', data?.display_name);

            dispatch(GetImsMenuTrackingSystem(subMenu.name, "Click", "mac", subMenu.menuID, latitude, longitude, data?.display_name));
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      }, (error) => {
        console.error("Error getting geolocation:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };


  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Main Menu</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/sidebar/menu-svgrepo.svg")}
              />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
        </li>

        {
          typeof menuList != 'undefined' &&
          menuList?.map((menu, index) => (
            <li
              className={`menu-item menu-item-submenu`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/sidebar/menu-svgrepo-com.svg"
                    )}
                  />
                </span>
                <span className="menu-text">{menu.motherMenu}</span>
                <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {typeof menu.p_ApplicationURLChildren !== 'undefined' && menu.p_ApplicationURLChildren.map((subMenu, subIndex) => (
                    <li

                      // className={`menu-item menu-item-submenu`}

                      className={`menu-item ${getMenuItemActive(subMenu.path)}`}

                      aria-haspopup="true"
                      data-menu-toggle="hover"
                    // className={`menu-item ${getMenuItemActive(subMenu.path)}`}
                    >
                      <NavLink
                        className="menu-link menu-toggle"
                        to={subMenu.path}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span className="menudotcolor"></span>
                        </i>

                        <span className="menu-text customcolormenuname" onClick={() => ChangeMenuClick(subMenu)}>
                          {subMenu.displayname}
                        </span>
                        <i className="menu-arrow" />
                      </NavLink>
                    </li>
                  ))}

                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          ))
        }

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
