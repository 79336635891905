import React from "react";
import VesselList from "../../components/vessel/VesselList";

const VesselListContainer = () => {
  return (
    <>
      <VesselList />
    </>
  );
};

export default VesselListContainer;
