import * as Types from "../types/Types";
import Axios from "axios";
import { toast } from "react-toastify";
import { getCertificateIssueBy } from "../../../certificate-main/_redux/actions/CertificateMainAction";
import { showToast } from "../../../../master/utils/ToastHelper";

export const handleChangeCertificateIssueAuthorityInput = (name, value) => (
  dispatch
) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({
    type: Types.CHANGE_CERTIFICATE_ISSUE_AUTHORITY_INPUT,
    payload: formData,
  });
};

export const getIssuingAuthorities = (searchValue = "", status = "", page) => async (dispatch) => {
  let response = {
    issuingAuthorities: [],
    status: false,
    message: "",
    isLoading: true,
    errors: [],
  };
  dispatch({ type: Types.GET_ISSUING_AUTHORITY_LIST, payload: response });

  // let isActive = status == "" ? "" : parseInt(status);
  let isActive = status == "" ? 1 : parseInt(status);

  let url = `${process.env.REACT_APP_API_URL}certificate/issuingAuthority?search=${searchValue}&isActive=${isActive}&isPaginated=1&paginateNo=10`;

  if (page !== null || page === "") {
    url += `&page=${page}`;
  }

  try {
    await Axios.get(url)
      .then((res) => {
        const { data, message, status } = res.data;
        response.status = status;
        response.issuingAuthorities = data.data;
        response.message = message;
        response.issuingAuthoritiesPaginatedData = data;
        response.isLoading = false;
      })
      .catch((err) => {
        toast.error(err);
      });
  } catch (error) {
    response.message = "Something Went Wrong !";
    toast.error(error);
  }
  response.isLoading = false;
  dispatch({ type: Types.GET_ISSUING_AUTHORITY_LIST, payload: response });
};

export const issueAuthoritySubmitAction = (CertificateIssueAuthirityInput) => (
  dispatch
) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  dispatch({
    type: Types.POST_ISSUING_AUTHORITY,
    payload: responseList,
  });

  let postUrl = `${process.env.REACT_APP_API_URL}certificate/issuingAuthority`;
  Axios.post(postUrl, CertificateIssueAuthirityInput)
    .then(function (response) {
      responseList.data = response.data;
      responseList.isLoading = false;
      responseList.status = response.data.status;
      if (response.data.status) {
        showToast("success", response.data.message);
        dispatch({
          type: Types.POST_ISSUING_AUTHORITY,
          payload: responseList,
        });
        dispatch(getCertificateIssueBy());
      } else {
        showToast("error", response.data.message);
      }
    })
    .catch(function (error) {
      responseList.isLoading = false;
      const message =
        "Something went wrong ! Please fill all inputs and try again !";
      showToast("error", message);

      dispatch({
        type: Types.POST_ISSUING_AUTHORITY,
        payload: responseList,
      });
    });
};

export const setIssuingAuthorityEditValue = (editValue) => (dispatch) => {
  const formData = {
    strIssuingAuthorityName: editValue.strIssuingAuthorityName,
    isActive: editValue.isActive,
    intActionBy: 1272,
  };
  dispatch({
    type: Types.SET_ISSUING_AUTHORITY_EDIT_DATA,
    payload: formData,
  });
};

export const issueAuthorityEditAction = (
  CertificateIssueAuthirityInput,
  intIssuingAuthorityID
) => (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  dispatch({
    type: Types.EDIT_ISSUING_AUTHORITY,
    payload: responseList,
  });

  let editUrl = `${process.env.REACT_APP_API_URL}certificate/issuingAuthority/${intIssuingAuthorityID}`;
  Axios.put(editUrl, CertificateIssueAuthirityInput)
    .then(function (response) {
      responseList.data = response.data;
      responseList.isLoading = false;
      responseList.status = response.data.status;
      if (response.data.status) {
        showToast("success", response.data.message);
        dispatch({
          type: Types.EDIT_ISSUING_AUTHORITY,
          payload: responseList,
        });
      } else {
        showToast("error", response.data.message);
      }
    })
    .catch(function (error) {
      responseList.isLoading = false;
      const message =
        "Something went wrong ! Please fill all inputs and try again !";
      showToast("error", message);

      dispatch({
        type: Types.EDIT_ISSUING_AUTHORITY,
        payload: responseList,
      });
    });
};

export const issueAuthorityDelete = (id) => (dispatch) => {
  let isLoading = true;
  dispatch({ type: Types.DELETE_ISSUING_AUTHORITY, payload: isLoading })

  Axios.delete(`${process.env.REACT_APP_API_URL}certificate/issuingAuthority/${id}`)
    .then((res) => {
      if (res.status === 200) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_ISSUING_AUTHORITY, payload: false })
      }
    })
}