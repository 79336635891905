import React from 'react';
import PurchaseApprovalList from '../components/list/PurchaseApprovalList';

const PurchaseApprovalListContainer = () => {
    return (
        <div>
            <PurchaseApprovalList />
        </div>
    );
};

export default PurchaseApprovalListContainer;