import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  getSalesList,
  salesDelete,
} from "../../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData} from "../../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../../Master/DownloadDropdown";
import { getBreadBurnReportByAsm } from "../../../_redux/actions/BreadBurnRemoteSales/BreadBurnReportAction";


const BradBurnCumalitiveByAsm = () => {
  const history = useHistory();
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const demandlist = useSelector((state) => state.bradBurn.cumalative);
  const amount = useSelector((state) => state.bradBurn);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();
  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
  };
  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };





  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea) => {
    dispatch(getBreadBurnReportByAsm(fromDate, tDate, idZone, idDivision, idArea));
  }


  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">BREAD BURN CUMALATIVE</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown />
          </div>

        </div>
        <hr></hr>



        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>

              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                onChange={(option) => {
                  setSelectZone(option.value);
                  divisionList(option.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option.value);
                  setSelectDivision(option.value);
                  handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option.value)
                  setSelectArea(option.value);
                  
                  setValue("territory", "");
                  handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                    dispatch(getSalesList(option.value, salesType));
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>



        </div>
        <div>
          {demandlist.length > 0 &&
           
           <div className="container mb-3 mt-3 containerFixed">
           <div className="row">
            
             <div className="col-xl-4 col-lg-4 col-md-6">
               <div className="paid__widget two">
                 <div className="widget__left">
                   {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                   <p className="balanceleft">Cake Quantity</p>
                   <p className="balanceleft">Cake Price</p>
                 </div>
                 <div className="widget__right">
                  
                     <div className="overlay-layer bg-transparent">
                       {/* <div className="spinner spinner-lg spinner-warning" /> */}
                     </div>
                     <div>
                       <h4 className="balanceleft"> {parseFloat(amount.cakeQty)}</h4>
                       <h4 className="balanceleft">৳ {parseFloat(amount.cakePrice).toFixed(2)}</h4>
                     </div>
                 </div>
               </div>
             </div>
             <div className="col-xl-4 col-lg-4 col-md-6">
               <div className="paid__widget one">
                 <div className="widget__left" >
                   {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                   <p className="balanceleft">Biscuit Quantity</p>
                   <p className="balanceleft">Biscuit Price</p>
                 
                 </div>
                 <div className="widget__right">
                 
                     <div className="overlay-layer bg-transparent">
                       {/* <div className="spinner spinner-lg spinner-warning" /> */}
                     </div>
                 
                     <div>
                       <h4 className="balanceleft">{parseFloat(amount.biscuteQty+amount.cookisQty)}</h4>
                       <h4 className="balanceleft">৳ {parseFloat(amount.biscutePrice+amount.cookisPrice).toFixed(2)}</h4>
                     </div>
                 </div>
               </div>
             </div>
           
             <div className="col-xl-4 col-lg-4 col-md-6">
               <div className="paid__widget four">
                 <div className="widget__left">
                   {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                   <p className="balanceleft">Total Quantity</p>
                   <p className="balanceleft">Total Price</p>
                 </div>
                 <div>
                   <h4 className="balanceleft">{parseFloat(amount.totalQuantityCum)}</h4>
                   <h4 className="balanceleft">৳ {parseFloat(amount.totalPriceCum).toFixed(2)}</h4>
                 </div>
               </div>
             </div>
           </div>
         </div>

          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
              <thead>
                <tr>
                  <th scope="col">ZONE</th>
                  <th scope="col">DIVISION</th>
                  {/* <th scope="col">DATE</th> */}
                  <th scope="col">AREA</th>
                  <th scope="col">DISTRIBUTION POINT</th>
                  <th scope="col">ENROLL</th>
                  <th scope="col">ASM NAME</th>
                  <th scope="col">PRODUCT_CODE	</th>
                  <th scope="col">PRODUCT_NAME	</th>
                  <th scope="col">TOTAL QUANTITY</th>
                  <th scope="col">TOTAL Value</th>
                </tr>
              </thead>
              <tbody>
                {demandlist.length > 0 &&
                  demandlist.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.distributionId}</td>
                      <td>{item.distributionName}</td>
                      {/* <td>{dateTimeConvertLongString(item.salesDdate)}</td> */}
                      <td>{item.biscuteQty}</td>
                      <td>{parseFloat(item.biscutePrice).toFixed(2)}</td>
                      <td>{item.cakeQty}</td>
                      <td>{parseFloat(item.cakePrice).toFixed(2)}</td>
                      <td>{item.cookisQty}</td>
                      <td>{parseFloat(item.cookisPrice).toFixed(2)}</td>
                      <td>{parseInt(item.totalQuantity)}</td>
                      <td>{parseFloat(item.totalPrice).toFixed(2)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
           
          </div>
          {/* <CustomPagination
            data={demandlist}
            itemsPerPage={50}
          /> */}
        </div>
      </div>
    </>
  );
};

export default BradBurnCumalitiveByAsm;
