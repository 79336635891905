import React from "react";
import VesselItemList from "../../components/vessel/VesselItemList";

const VesselItemListContainer = () => {
  return (
    <>
      <VesselItemList />
    </>
  );
};

export default VesselItemListContainer;
