import React from "react";
import SupplierCs from "../components/SupplierCs";


const SupplierCsContainer = () => {
  return (
    <div className="container">
      <div className="card card-custom gutter-b">
        <SupplierCs />
      </div>
    </div>
  );
};

export default SupplierCsContainer;
