import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
    clearList,
  EditDateGet,
  getCustomerBalance,
  getCustomerListDetails,
  getGiftdemandList,
  getSalesList,
  salesDelete,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import RemoteSalesDetails from "./RemoteSalesDetails";
import {InputHandle} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import GiftListDetails from "./GiftListDetails";


const EventList = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const demandlist = useSelector((state) => state.remoteSales.productList);

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [customerList,setCustomerList] = useState([]);
  const [selectCustomer,setSelectCustomer] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectgiftType, setSelectgiftType] = useState(null);
  
 

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearList());

}, [])



  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    var customerList = await getCustomerListDetails();
    setCustomerList(customerList.data);
    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    
  };



  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id,selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id,selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.invID);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.custId}`);
  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  const distributorDropDownData = () => {
    let options = [];
    if (customerList?.length > 0) {
      customerList.forEach((item) => {
        let itemData = {
          value: item.distribID,
          label: item.distribName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const handleListAction =()=>{
    dispatch(getGiftdemandList(startDate,toDate,selectCustomer,"E"));
  }
 

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        {/* <div className="container"> */}
        {/* <RemoteSalesFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}
        /> */}
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Event List Report</h3>
            </div>
          </div>
          {/* <div className="col-md-3">
                <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType==1?true:false}  onChange={(e)=>getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType==3?true:false} onChange={(e)=>getSalesValue('general',e.target.value)}/>
                  <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
                </div>
              </div> */}
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>

        <div className="form-group row">
        <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          {/* <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Distributor</label>
              <RHFInput
                
                as={<Select options={distributorDropDownData()} />}
                rules={{ required: false }}
                name="intDistributorId"
                register={register}
                value=""
                // value={itemDataInput.intDistributorId}
                onChange={(option) => {
                 
                  setSelectCustomer(option.value)

                }}
                setValue={setValue}
              />
               </Form.Group>
            </div> */}
            <div className="col-md-3 mt-5">
            <Button size="sm" onClick={() => handleListAction()}>Show Report</Button>
          </div>
          
          </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
                className="shortstickyTable"
                style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
              >
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  <th scope="col">Gift ID</th>
                  <th scope="col">Gift Date</th>
                  <th scope="col">CUSTOMER NAME</th>
                  <th scope="col">Product ID</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">QUANTITY</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {demandlist.length > 0 &&
                  demandlist.map((item, index) => (
                    <tr>
                      <td>{item.invID}</td>
                      <td>{item.giftDate}</td>
                      <td>{item.custName}</td>
                      <td>{item.productID}</td>
                      <td>{item.productName}</td>
                      <td>{item.totalQty}</td>
                      <td>
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                 
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Promotional List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <GiftListDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default EventList;
