import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams,useLocation  } from "react-router-dom";

import {
  delteProductList,
  productSelecteditem,
  itemAddInput,
  InputHandle,
  getUpdateProductList,
  productUpdateInputQuantity,
  salesUpdate,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { damageUpdate, damageUpdateInputQuantity, DemageUpdateQuantity, getDamageProduct, getUpdateDamageProductList } from "../../_redux/actions/RemoteSales/DamageAction";

const DamageEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();


  const { id,name,code } = useParams();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const location = useLocation();
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const salesEditInfo = useSelector((state) => state.remoteSales.salesEditInfo);
  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled,setDisabled ]=useState(false);
  const damageUpdateData = useSelector((state) => state.damage.damageUpdateData);
  console.log('damageUpdateData :>> ', damageUpdateData);

  const balanceLoader = useSelector((state) => state.remoteSales.balanceLoader);
  const availableBalance = useSelector(
    (state) => state.remoteSales.availableBalance
  );
  const creditLimit = useSelector((state) => state.remoteSales.creditLimit);
  const restBalance = useSelector(
    (state) => state.remoteSales.restBalance
  );
  const productCost = useSelector((state) => state.remoteSales.productCost);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);

  const [salesType, setSalesType] = useState(1);
  const [distribType, setDistribType] = useState(null);
  

  // Redirect List Page after submitting data
  useEffect(() => {
    if (productUpdateStatus) {

       setTimeout(() => {history.go(0)}, 3000);
    }

  }, [productUpdateStatus]);

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    const custId = code.slice(1);
    // dispatch(getDistributorList());
    dispatch(getUpdateDamageProductList(id));
    // dispatch(getCustomerBalance(id));
    // dispatch(getCustomerBalance(custId));
    // dispatch(getItemCategory())
    
  }, []);
  useEffect(() => {
    // const userData = localStorage.getItem("userData");
    // const user = JSON.parse(userData);
    // console.log('user.employeeType :>> ', user.employeeType);
    // setSalesType(user.employeeType);
    // setDistribType(user.employeeType);
    // dispatch(getDamageProduct(salesType));
   
}, [])

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (item,index,product,subindex,value) => {
    dispatch(DemageUpdateQuantity(item,index,product,subindex,value));
  };

  const onSubmit = async (e) => {
    setDisabled(true);
    dispatch(damageUpdate(id, damageUpdateData));
    // setDisabled(false);
  };

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];
  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border p-3">
          <div className="mt-5">
            <h3 className="mb-0 pb-0">Damage Update</h3>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">{name}</strong>
                    <br />
                   {code}
                    <br />
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row"></div>
        </div>

        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
                    {damageUpdateData.length > 0 ? (
                        <div className="row" id="id" >
                            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
                                <div>
                                    <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" >
                                        <thead className="bg-white" >
                                            <tr >
                                                <th scope="col">PRODUCT ID</th>
                                                <th scope="col">PRODUCT NAME</th>
                                                <th></th>
                                                <th scope="col">QUANTITY</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {damageUpdateData?.length > 0 &&
                                                damageUpdateData?.map((item, index) => (
                                                    <>
                                                        <tr >
                                                            <td>{item.productId}</td>
                                                            <td>{item.productName}</td>
                                                        </tr>
                                                        {
                                                            item?.details.map((product, subindex) => (
                                                                <tr className="alert bg-light">
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>{product.categoryName}</td>
                                                                    <td>
                                                                        <Form.Group>
                                                                            <Form.Control
                                                                                autocomplete="off"
                                                                                className="formHeight"
                                                                                type="number"
                                                                                placeholder="Quantity"
                                                                                name="itemName"
                                                                                value={product.value}
                                                                                onChange={(e) =>
                                                                                    changeProductQuantity(item,index,product,subindex,e.target.value)
                                                                                }
                                                                            />
                                                                        </Form.Group>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <button
                                    type="submit"
                                    disabled={disabled}
                                    className="btn btn-primary 
                                    btn-sm float-right text-center 
                                    custome-addnew-btn item-add-save mb-5"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
      </form>
    </>
  );
};

export default DamageEdit;
