import React from 'react'
import { GoogleMap, useJsApiLoader,Marker,InfoWindow  } from '@react-google-maps/api';
import axios from "axios";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 23.909279,
  lng: 90.415218
};



const locations = [
  { lat: 23.909696162178463, 
    lng: 90.41604855944885,
    time:'12:019999'
  },
  { lat: 23.910459577454127, 
    lng: 90.41683619131497,
    time:'12:5555555'
   },
  { lat: 23.910270426853426, 
    lng: 90.41829048040096,
    time:'12:0100000000'
   }
];

function SoTracking() {
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const handleMarkerClick = async (location) => {

    const API_USERNAME = 'fariduddin';
   const apiUrl = `http://api.geonames.org/findNearbyPlaceNameJSON?lat='23.909696162178463'&lng='90.41604855944885'&username=${API_USERNAME}`;
   await axios.get(apiUrl).then((res) => {
    console.log('res', res);
  });

    setSelectedLocation(location);
  };

  const handleInfoWindowClose = () => {
    setSelectedLocation(null);
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD0bqf9tht_4BdrXB8prjVx359q9oLH9XY"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  const delta = {
    lat: 0.5,
    lng: 0.5
  };

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={locations[0]}
        zoom={10}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
        //delta ={delta}
      >
        {locations.map((location, index) => (
          <Marker key={index} 
          position={location}
          onClick={() => handleMarkerClick(location)}
           />
      ))}
       {selectedLocation && (
        <InfoWindow
          position={selectedLocation}
          onCloseClick={handleInfoWindowClose}
        >
          <div>{selectedLocation.time}</div>
        </InfoWindow>
      )}
        <></>
      </GoogleMap>
  ) : <></>
}

export default React.memo(SoTracking)