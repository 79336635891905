import React from "react";
import VesselItemAdd from "../../components/vessel/VesselItemAdd";

const VesselItemAddContainer = () => {
  return (
    <>
      <VesselItemAdd />
    </>
  );
};

export default VesselItemAddContainer;
