import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, GetDisWiseImsReportValueV2 } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const SecondaryDistributorWiseIMSReportValueV2 = () => {
  const history = useHistory();

  const isLoading = useSelector((state) => state.secondarySales.sowiseimsLoading);
  console.log('isLoading', isLoading)
  const cumalative = useSelector((state) => state.secondarySales.cumulative);

  const totalQuantityCum = useSelector((state) => state.secondarySales.totalQuantityCum);
  const totalPriceCum = useSelector((state) => state.secondarySales.totalPriceCum);
  const biscuteQty = useSelector((state) => state.secondarySales.biscuteQty);
  const biscutePrice = useSelector((state) => state.secondarySales.biscutePrice);
  const cakeQty = useSelector((state) => state.secondarySales.cakeQty);
  const cakePrice = useSelector((state) => state.secondarySales.cakePrice);
  const cookisQty = useSelector((state) => state.secondarySales.cookisQty);
  const cookisPrice = useSelector((state) => state.secondarySales.cookisPrice);
  const wiferQty = useSelector((state) => state.secondarySales.wiferQty);
  const wiferPrice = useSelector((state) => state.secondarySales.wiferPrice);
  const snacksQty = useSelector((state) => state.secondarySales.snacksQty);
  const snacksPrice = useSelector((state) => state.secondarySales.snacksPrice);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [defultZone, setDefaultZone] = useState({});

  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const componentRef = useRef();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user?.employeeType);
    setDistribType(user?.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  let ref = React.createRef();
  const inputField = React.useRef(null);


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = (input) => {
    setToDate(input);
  };
  const handleDateFilter = (option) => {
    handleChangeTodate(option);
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }

  const showReport = () => {
    const validation = showValidation();
    if (validation) {
      dispatch(GetDisWiseImsReportValueV2(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType));
    }
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-8">
            <div className="mt-2 p-2">
              <span className="mb-0 pb-0 pageheadingText">DISTRIBUTOR WISE IMS REPORT(VALUE)</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right ">
              <DownloadDropdown excelname='Distributor Wise IMS Report(Value)' />
            </div>
          </div>
          
        </div>

        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={(event) => {
                  handleDateFilter(event.target.value)


                }}

              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>
        

          <div className="col mt-5 ml-5">
            {/* <button className="button-621" onClick={() => showReport()} >  Show Report</button> */}
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>

        </div>
        <div>
          {cumalative?.length > 0 &&

            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">
                <div className="col-12 col-md mb-1">
                  <div className="paid__widget_SOWISE one">
                    <div className="widget__left">
                      <div className="achiveLayer">
                        <span>Biscuit CTN Qty:  {parseInt(biscuteQty + cookisQty)}</span>
                        <span>Biscuit Value:  ৳ {parseFloat(biscutePrice + cookisPrice).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md mb-1">
                  <div className="paid__widget_SOWISE two">
                    <div className="widget__left">
                      <div className="achiveLayer">
                        <span>Wafer CTN Qty:   {parseInt(wiferQty)}</span>
                        <span>Wafer Value:  ৳ {parseFloat(wiferPrice).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md mb-1">
                  <div className="paid__widget_SOWISE three">
                    <div className="widget__left">
                      <div className="achiveLayer">
                        <span>Cake CTN Qty:   {parseInt(cakeQty)}</span>
                        <span>Cake Value:  ৳ {parseFloat(cakePrice).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md mb-1">
                  <div className="paid__widget_SOWISE four">
                    <div className="widget__left">
                      <div className="achiveLayer">
                        <span>Snacks CTN Qty:   {parseInt(snacksQty)}</span>
                        <span>Snacks Value:  ৳ {parseFloat(snacksPrice).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md mb-1">
                  <div className="paid__widget_SOWISE five">
                    <div className="widget__left">
                      <div className="achiveLayer">
                        <span>Total CTN Qty:   {parseInt(totalQuantityCum)}</span>
                        <span>Total Value:  ৳ {parseFloat(totalPriceCum).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isLoading && <LoadingSpinner text="Loading Items List..." />}

          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            <div
              className="stickyTable"
              style={{ height: cumalative?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                <thead>
                  <tr>
                    <th scope="col">ZONE NAME</th>
                    <th scope="col">DIVSION NAME</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">DISTRIBUTION ID</th>
                    <th scope="col">DISTRIBUTOR NAME</th>
                    <th scope="col">DISTRIBUTOR POINT ID</th>
                    <th scope="col">DISTRIBUTOR POINT NAME</th>
                    <th scope="col">GROUP NAME</th>
                    <th scope="col">NUMBER OF MEMOlINE</th>
                    <th scope="col">TOTAL MEMO QTY</th>
                    <th scope="col">TOTAL VISITED OUTLET</th>
                    <th scope="col">TOTAL ORDER VALUE</th>
                    <th scope="col">BISCUIT QUNATITY</th>
                    <th scope="col">BISCUIT VALUE</th>
                    <th scope="col">CAKE QUNATITY</th>
                    <th scope="col">CAKE VALUE</th>
                    <th scope="col">COOKIES QUNATITY</th>
                    <th scope="col">COOKIES VALUE</th>
                    <th scope="col">WAFER QUNATITY</th>
                    <th scope="col">WAFER VALUE</th>
                    <th scope="col">SANCKS QUNATITY</th>
                    <th scope="col">SANCKS VALUE</th>
                    <th scope="col">TOTAL QUANTITY</th>
                    <th scope="col">TOTAL CTN(QTY)</th>
                    <th scope="col">TOTAL VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  {cumalative?.length > 0 &&
                    cumalative?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.zoneName}</td>
                        <td>{item.divisionName}</td>
                        <td>{item.areaName}</td>
                        <td>{item.territoryName}</td>
                        <td>{item.distribID}</td>
                        <td>{item.distribName}</td>
                        <td>{item.distribPointID}</td>
                        <td>{item.distribPointName}</td>
                        <td>{item.salesGroupName}</td>

                        <td>{parseInt(item.memoLine)}</td>
                        <td>{parseInt(item.memoQty)}</td>
                        <td>{parseFloat(item.visitedOutlet)}</td>
                        <td>{parseFloat(item.orderValue)}</td>
                        <td>{item.biscuteQty}</td>
                        <td>{parseFloat(item.biscutePrice).toFixed(2)}</td>
                        <td>{item.cakeQty}</td>
                        <td>{parseFloat(item.cakePrice).toFixed(2)}</td>
                        <td>{item.cookisQty}</td>
                        <td>{parseFloat(item.cookisPrice).toFixed(2)}</td>
                        <td>{parseInt(item.wiferQty)}</td>
                        <td>{parseInt(item.wiferPrice)}</td>
                        <td>{parseInt(item.snacksQty)}</td>
                        <td>{parseInt(item.snacksPrice)}</td>
                        <td>{parseFloat(item.totalQuantity).toFixed(2)}</td>
                        <td>{parseFloat(item.totalQuantityCtn).toFixed(2)}</td>
                        <td>{parseFloat(item.totalPrice).toFixed(2)}</td>

                      </tr>
                    ))}


                </tbody>
              </table>
            </div>
            {!isLoading && cumalative?.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}

          </div>
        </div>

      </div>
    </>
  );
};

export default SecondaryDistributorWiseIMSReportValueV2;
