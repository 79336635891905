import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import {
  InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";

import { cleanSecondarySalesData, getAfmlProductLiftingByDistributor, getAfmlProductLiftingByDistributorForExcel, getCumalativeReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import { dateTimeConvertLongString } from "../../../../../domains/CCO/utils/DateHelper";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import * as XLSX from "xlsx/xlsx.mjs";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import { FaDownload } from "react-icons/fa";



const AfmlProductLiftingByDistributor = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector((state) => state.remoteSales.productList);
  const isProductLoading = useSelector((state) => state.secondarySales.breadBurnReportLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const demandlist = useSelector((state) => state.secondarySales.breadBurnReport);
  console.log('demandlist', demandlist)
  const totalQuantityCum = useSelector((state) => state.secondarySales.totalQuantityCum);
  const totalPriceCum = useSelector((state) => state.secondarySales.totalPriceCum);
  const amount = useSelector((state) => state.secondarySales);
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );



  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
  }

  // const getSalesValueType = (name, value) => {
  //   setSalesType(value);
  // }

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    let toDate = input.value;
    // handleListAction(startDate, toDate, selectArea, selectDivision, selectArea,selectTerritory);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory, SGeoType) => {

    dispatch(getAfmlProductLiftingByDistributor(fromDate, tDate, idZone, idDivision, idArea, idTerritory, SGeoType));
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const showReport = () => {
    handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType);

  }
  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hanleExcelClick = async () => {
    setLoading(true);
    setError(null);

    try {
      let excelClick = await getAfmlProductLiftingByDistributorForExcel(
        startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, 2);
      const current = currentdate();


      const worksheet = XLSX.utils.json_to_sheet(excelClick.data.allReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Product Lifting(Distributor Wise)" + " " + current + '.xlsx');

    } catch (error) {
      console.error("Error:", error);
      setError("Network Error");

    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              {/* <span className="button-010">Product Lifting(Distributor Wise)</span> */}
              <h3 className="mb-0 pb-0">Product Lifting(Distributor Wise)</h3>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right ">
              <Button
                size="sm"
                onClick={hanleExcelClick}
                disabled={loading}
                className={loading ? 'bg-danger' : ''}
              >
                <FaDownload /> {loading ? 'Loading...' : 'Download'}
              </Button>
              {error && <div>Error: {error}</div>}
              <DownloadDropdown excelname='Product Lifting(Distributor Wise)' />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div>

        </div>
        <hr></hr>



        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>

              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  //handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  //handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);

                  setValue("territory", "");
                  //handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  setSelectTerritory(option?.value);


                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col mt-5">
            <button className="button-621" onClick={() => showReport()} >  Show Report</button>
            {/* <Button size="sm" onClick={() => showReport()}>Show Report</Button> */}
          </div>
        </div>
        <div>
          {demandlist?.length > 0 &&

            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">

                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget two">
                    <div className="widget__left">
                      <p className="balanceleft">Product Quantity</p>
                    </div>
                    <div>
                      <h4 className="balanceleft"> {parseInt(amount.totalDisQty)}</h4>
                    </div>
                  </div>
                </div>


                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget four">
                    <div className="widget__left">
                      <p className="balanceleft">Product Price</p>
                    </div>
                    <div>
                      <h4 className="balanceleft">৳ {parseFloat(amount.totalDisPrice).toFixed(2)}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isProductLoading && <LoadingSpinner text="Loading Items List..." />}

          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            <div
              className="stickyTable"
              style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
            >

              {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead>
                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">SALES DATE</th>
                    <th scope="col">DISTRIBUTION ID</th>
                    <th scope="col">DISTRIBUTOR NAME</th>
                    {/* <th scope="col">DISTRIBUTOR CODE</th> */}
                    <th scope="col">PRODUCT CATEGORY</th>
                    <th scope="col">SKU CODE</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">PRODUCT QTY</th>
                    <th scope="col">TRADE QTY</th>
                    <th scope="col">PRODUCT PIC PRICE</th>
                    <th scope="col">PRODUCT TOTAL PRICE</th>

                  </tr>
                </thead>
                <tbody>
                  {demandlist?.length > 0 &&
                    demandlist?.map((item, index) => (
                      <tr>
                        <td>{item.zonename}</td>
                        <td>{item.divisionname}</td>
                        <td>{item.areaname}</td>
                        <td>{item.territoryName}</td>
                        <td>{dateTimeConvertLongString(item.salesDate)}</td>
                        <td>{item.distribId}</td>
                        <td>{item.distribName}</td>
                        {/* <td>{item.distribCode}</td> */}
                        <td>{item.productCategory}</td>
                        <td>{item.productId}</td>
                        <td>{item.productName}</td>
                        <td>{item.productQty}</td>
                        <td>{item.tradeProductQty}</td>
                        <td>{parseFloat(item.productPicPrice).toFixed(2)}</td>
                        <td>{parseFloat(item.productTPrice).toFixed(2)}</td>

                      </tr>
                    ))}


                </tbody>
              </table>
            </div>
            {!isProductLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>



        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default AfmlProductLiftingByDistributor;
