import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  getSalesList,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import {
  InputHandle
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { secondarySalesReportDateWise } from "../../_redux/actions/SecondarySales/SecondaryReportAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const SecondarySalesDateWiseReport = () => {
  const history = useHistory();
  
  const isLoading = useSelector((state) => state.secondarySales.dateReportLoading);
  const dateWiseReport = useSelector(
    (state) => state.secondarySales.dateWiseReport
  );
  const dateSys = useSelector(
    (state) => state.secondarySales.dateSys
  );
  const dateCount = useSelector(
    (state) => state.secondarySales.dateCount
  );

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    document.body.style.backgroundColor = "#fff"
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id,selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);

  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea) => {
    dispatch(secondarySalesReportDateWise(fromDate, tDate, idZone, idDivision, idArea));
  }

  const showReport = () => {
    // dispatch(secondarySalesReportDateWise(startDate, toDate,selectArea));
  }
  const rendereTableData = (product) => {
    let data = product?.dateRange.slice(0, dateCount).map((item, index) => {
      return (
        <>
          <td className="d1">
            {item.d1}
          </td>
          <td className="d1">
            {item.d2}
          </td>
          <td className="d1">
            {item.d3}
          </td>
          <td className="d1">
            {item.d4}
          </td>
          <td className="d1">
            {item.d5}
          </td>
          <td className="d1">
            {item.d6}
          </td>
          <td className="d1">
            {item.d7}
          </td>
          <td className="d1">
            {item.d8}
          </td>
          <td className="d1">
            {item.d9}
          </td>
          <td className="d1">
            {item.d10}
          </td>
          <td className="d1">
            {item.d11}
          </td>
          <td className="d1">
            {item.d12}
          </td>
          <td className="d1">
            {item.d13}
          </td>
          <td className="d1">
            {item.d14}
          </td>
          <td className="d1">
            {item.d15}
          </td>
          <td className="d1">
            {item.d16}
          </td>
          <td className="d1">
            {item.d17}
          </td>
          <td className="d1">
            {item.d18}
          </td>
          <td className="d1">
            {item.d19}
          </td>
          <td className="d1">
            {item.d20}
          </td>
          <td className="d1">
            {item.d21}
          </td>
          <td className="d1">
            {item.d22}
          </td>
          <td className="d1">
            {item.d23}
          </td>
          <td className="d1">
            {item.d24}
          </td>
          <td className="d1">
            {item.d25}
          </td>
          <td className="d1">
            {item.d26}
          </td>
          <td className="d1">
            {item.d27}
          </td>
          <td className="d1">
            {item.d28}
          </td>
          <td className="d1">
            {item.d29}
          </td>
          <td className="d1">
            {item.d30}
          </td>
          <td className="d1">
            {item.d31}
          </td>
        </>
      )
    });
    return data;
  }


  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };








 

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className=" pl-5 pr-5 mb-5 card-top-border mb-5 bg-light">
          <div className="row mt-5">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <h3 className="mb-0 pb-0">Sales DateWise Report</h3>
              </div>
            </div>
            <div className="col-md-3">
              <DownloadDropdown />
            </div>
          </div>
          <hr></hr>
          <div className="form-group row ">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Deadweight"
                  name="fromDate"
                  value={startDate}
                  className="fromStyle"
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Todate"
                  name="todate"
                  className="fromStyle"
                  onChange={handleChangeTodate}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Zone</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={zoneDropdownData()} />}
                  rules={{ required: false }}
                  name="zoneData"
                  register={register}
                  // value={selectZone}
                  value=""
                  onChange={(option) => {
                    setSelectZone(option.value);
                    divisionList(option.value);

                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Division</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={divisionDropdownData()} />}
                  rules={{ required: false }}
                  name="divisionData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)
                    getAreaData(option.value);
                    setSelectDivision(option.value);
                    setValue("areaData", "");
                    setValue("territory", "");
                    handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Area</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={areaDropdownData()} />}
                  rules={{ required: false }}
                  name="areaData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)
                    // setSelectDivision(option.value);
                    // dispatch(getDistributorList(option.value))
                    getTerritoryData(option.value)
                    setSelectArea(option.value);
                    
                    setValue("territory", "");

                    handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>


            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                    dispatch(getSalesList(option.value, salesType));
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>




            <div className="col-lg-3">
              <Button variant="primary" onClick={() => showReport()} size="sm" className="mt-6">
                Show
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-5 pr-5 mb-5  mb-5 dateWiseReport">
        <div className="row da teWiseReport" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {isLoading && <LoadingSpinner text="Loading Items List..." />}
            <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
              <thead className="dateThe">
                {
                  dateSys && dateSys.map((itemDate, index) => (
                    <tr>
                      <th scope="col" className="zone ">{itemDate.zone}</th>
                      <th scope="col" className="division">{itemDate.division}</th>
                      <th scope="col" className="Area">{itemDate.Area}</th>
                      <th scope="col" className="DistributionPoint">{itemDate.DistributionPoint}</th>
                      <th scope="col" className="Enroll">{itemDate.Enroll}</th>
                      <th scope="col" className="soName">{itemDate.soName}</th>
                      <th scope="col" className="product_code">{itemDate.product_code}</th>
                      <th scope="col" className="product_name">{itemDate.product_name}</th>
                      <th scope="col" className="unitName">{itemDate.unitName}</th>
                      <th scope="col" className="price">{itemDate.price}</th>
                      <th scope="col" className="field0">{itemDate.field0}</th>
                      <th scope="col" className="field1">{itemDate.field1}</th>
                      <th scope="col" className="field2">{itemDate.field2}</th>
                      <th scope="col" className="field3">{itemDate.field3}</th>
                      <th scope="col" className="field4">{itemDate.field4}</th>
                      <th scope="col" className="field5">{itemDate.field5}</th>
                      <th scope="col" className="field6">{itemDate.field6}</th>
                      <th scope="col" className="field7">{itemDate.field7}</th>
                      <th scope="col" className="field8">{itemDate.field8}</th>
                      <th scope="col" className="field9">{itemDate.field9}</th>
                      <th scope="col" className="field10">{itemDate.field10}</th>
                      <th scope="col" className="field11">{itemDate.field11}</th>
                      <th scope="col" className="field12">{itemDate.field12}</th>
                      <th scope="col" className="field13">{itemDate.field13}</th>
                      <th scope="col" className="field14">{itemDate.field14}</th>
                      <th scope="col" className="field15">{itemDate.field15}</th>
                      <th scope="col" className="field16">{itemDate.field16}</th>
                      <th scope="col" className="field17">{itemDate.field17}</th>
                      <th scope="col" className="field18">{itemDate.field18}</th>
                      <th scope="col" className="field19">{itemDate.field19}</th>
                      <th scope="col" className="field20">{itemDate.field20}</th>
                      <th scope="col" className="field21">{itemDate.field21}</th>
                      <th scope="col" className="field22">{itemDate.field22}</th>
                      <th scope="col" className="field23">{itemDate.field23}</th>
                      <th scope="col" className="field24">{itemDate.field24}</th>
                      <th scope="col" className="field25">{itemDate.field25}</th>
                      <th scope="col" className="field26">{itemDate.field26}</th>
                      <th scope="col" className="field27">{itemDate.field27}</th>
                      <th scope="col" className="field28">{itemDate.field28}</th>
                      <th scope="col" className="field29">{itemDate.field29}</th>
                      <th scope="col" className="field30">{itemDate.field30}</th>
                     
                    </tr>
                  ))
                }
              </thead>
              <tbody>

                {/* {rendertableData(dateWiseReport)} */}

                {dateWiseReport?.map(row => {
                  return (
                    <>
                      <tr>

                        <td>{row.zoneName}</td>
                      </tr>
                      {row.divisionInformation.map(childRow => (
                        <>
                          <tr>
                            <td />
                            <td>{childRow.divisionName}</td>
                          </tr>
                          {
                            childRow.areaInformation.map((area) => (
                              <>
                                <tr>
                                  <td />
                                  <td />
                                  <td>{area.areaName}</td>
                                </tr>
                                {
                                  area.disPointInformation.map((dispoint) => (
                                    <>
                                      <tr>
                                        <td />
                                        <td />
                                        <td />
                                        <td>{dispoint.disPointName}</td>
                                      </tr>
                                      {
                                        dispoint.soInformation.map((soInfo) => (
                                          <>
                                            <tr>
                                              <td />
                                              <td />
                                              <td />
                                              <td />
                                              <td>{soInfo.soEnroll}</td>
                                              <td>{soInfo.soName}</td>
                                            </tr>
                                            {
                                              soInfo.productInformation.map((product) => (
                                                <>
                                                  <tr>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                      {product.productID}
                                                    </td>
                                                    <td>
                                                      {product.productName}
                                                    </td>
                                                    <td>
                                                      {product.productUom}
                                                    </td>
                                                    <td>
                                                      {product.productPrice}
                                                    </td>

                                                    {/* {rendereTableData(product)} */}

                                                    {
                                          
                                                      product.dateRange.slice(0, dateCount).map((product,index) => (
                                                        <>
                                                          <td className="d1">
                                                            {product.d1}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d2}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d3}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d4}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d5}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d6}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d7}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d8}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d9}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d10}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d11}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d12}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d13}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d14}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d15}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d16}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d17}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d18}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d19}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d20}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d21}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d22}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d23}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d24}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d25}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d26}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d27}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d28}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d29}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d30}
                                                          </td>
                                                          <td className="d1">
                                                            {product.d31}
                                                          </td>

                                                        </>

                                                      ))
                                                    }
                                                  </tr>


                                                </>

                                              ))
                                            }
                                          </>

                                        ))
                                      }
                                    </>

                                  ))
                                }
                              </>

                            ))
                          }

                        </>
                      ))}
                    </>
                  )
                })}

              </tbody>
            </table>

          </div>
        </div>
      </div>


      <SimpleModal
        size="xl"
        show={itemDetailShow}
        handleClose={() => setItemDetailShow(false)}
        handleShow={() => setItemDetailShow(true)}
        modalTitle={"Item Details"}
      >
        <RemoteSalesDetails
          handleClose={() => setItemDetailShow(false)}
          itemID={itemID}
          itemDetailnIfo={itemifo}
        />
      </SimpleModal>

      {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
      {/* </div> */}

    </>

  );
};


export default SecondarySalesDateWiseReport;
