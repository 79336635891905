import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { FaUserAlt, FaMapMarkerAlt, FaMapMarkedAlt, FaTruck, FaChartLine, FaChartArea, FaTasks, FaTimes } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { getItemforStockProduct, getSoInformation } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { Col, Row } from "react-bootstrap";
import LotiAnimation from "../Dashboard/LotiAnimation";
import { GetBradeBurnTopCard } from "../../_redux/actions/Dashboard/BradeBurnDashboardAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { Link } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import LotiAnimationMobile from "../Dashboard/LotiAnimationMobile";

export function SOMobileDashboard() {
  const topCard = useSelector((state) => state.breadburn.topcardValue);
  const dispatch = useDispatch();

  const [showLeadsModal, setShowLeadsModal] = useState(false);

  const handleShowLeadsModal = () => setShowLeadsModal(true);
  const handleCloseLeadsModal = () => setShowLeadsModal(false);

  useEffect(() => {
    dispatch(GetBradeBurnTopCard("BRA"));
  }, [dispatch]);

  const achievement = topCard?.achivement ? parseFloat(topCard.achivement) : 0;
  const [animatedAchievement, setAnimatedAchievement] = useState(0);
  const [animatedDemand, setAnimatedDemand] = useState(0);
  const [animatedDelivery, setAnimatedDelivery] = useState(0);
  const [animatedStock, setAnimatedStock] = useState(0);

  const ProductStockSum = useSelector((state) => state.secondary.ProductStockSum);

  const [soInfo, setSoInfo] = useState({
    zoneName: null,
    divisionName: null,
    areaName: null,
    soName: null,
    soPointID: null,
    soid: null,
    distribName: null,
    soPointName: null,
    territoryName: null,
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initialData = async () => {
    let soDetails = await getSoInformation();
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);

    var d = new Date();
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

   

    if (soDetails.data != null) {
      const {
        zoneName,
        divisionName,
        areaName,
        soName,
        soPointID,
        soid,
        distribName,
        distribID,
        soPointName,
        territoryName,
      } = soDetails.data;
      const soInfomation = { ...soInfo };
      soInfomation.zoneName = zoneName;
      soInfomation.divisionName = divisionName;
      soInfomation.areaName = areaName;
      soInfomation.territoryName = territoryName;
      soInfomation.soName = soName;
      soInfomation.soPointID = soPointID;
      soInfomation.soid = soid;
      soInfomation.distribName = distribName;
      soInfomation.distribID = distribID;
      soInfomation.soPointName = soPointName;
      setSoInfo(soInfomation);

      dispatch(getItemforStockProduct(soInfomation.soPointID, currentD, user.employeeType));
    }

  };

  useEffect(() => {
    initialData();
  }, []);

  // Animate achievement value slowly
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedAchievement((prev) => {
        if (prev < achievement) {
          return prev + 0.2; // Slower increment for smoother animation
        } else {
          clearInterval(interval);
          return achievement;
        }
      });
    }, 50); // Increased interval time for slower animation
    return () => clearInterval(interval);
  }, [achievement]);

  const data = [
    { name: "Achieved", value: animatedAchievement },
    { name: "Remaining", value: 100 - animatedAchievement },
  ];

  const COLORS = ["#ffbc00", "#dcdcdc"];

  // Animate achievement value slowly
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedAchievement((prev) => {
        if (prev < achievement) {
          return prev + 0.2; // Slower increment for smoother animation
        } else {
          clearInterval(interval);
          return achievement;
        }
      });
    }, 50); // Increased interval time for slower animation
    return () => clearInterval(interval);
  }, [achievement]);

  // Animation for Demand
  useEffect(() => {
    const demandValue = topCard?.demand || 0;
    const interval = setInterval(() => {
      setAnimatedDemand((prev) => {
        if (prev < demandValue) {
          return Math.min(prev + (demandValue / 100), demandValue); // Slower increment for Demand animation
        } else {
          clearInterval(interval);
          return demandValue;
        }
      });
    }, 50); // Slower animation speed
    return () => clearInterval(interval);
  }, [topCard?.demand]);

  // Animation for Delivery
  useEffect(() => {
    const deliveryValue = topCard?.delivery || 0;
    const interval = setInterval(() => {
      setAnimatedDelivery((prev) => {
        if (prev < deliveryValue) {
          return Math.min(prev + (deliveryValue / 100), deliveryValue); // Slower animation speed
        } else {
          clearInterval(interval);
          return deliveryValue;
        }
      });
    }, 50); // Slower animation speed
    return () => clearInterval(interval);
  }, [topCard?.delivery]);


  // Animation for Stock
  useEffect(() => {
    const stockValue = ProductStockSum || 0;
    const interval = setInterval(() => {
      setAnimatedStock((prev) => {
        if (prev < stockValue) {
          return Math.min(prev + (stockValue / 100), stockValue); // Slower animation speed
        } else {
          clearInterval(interval);
          return stockValue;
        }
      });
    }, 50); // Slower animation speed
    return () => clearInterval(interval);
  }, [ProductStockSum]);


  return (
    <div className="so-dashboard-container">
      {/* Header Section */}
      <div className="so-card-top so-dashboard-header">
        <div className="so-dashboard-avatar">{soInfo.soName?.charAt(0)}</div>
        <div>
          <div className="so-dashboard-greeting">Hi, {soInfo.soName}</div>
          <div className="so-dashboard-email">{soInfo.soid}</div>
        </div>
      </div>

      {/* Achievement and Pie Chart in One Card */}
      <Row className="mb-0">
        <Col sm={12} className="so-col-12">
          <div className="so-card so-card-achievement" style={{ padding: '10px' }}>
            <Row className="align-items-center">
              {/* Left side: Pie Chart */}
              <Col xs={6} sm={6} className="text-center">
                <ResponsiveContainer width="100%" height={110}>
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius="60%"
                      outerRadius="90%"
                      dataKey="value"
                      stroke="none"
                      isAnimationActive={true}
                      animationDuration={2000}
                      animationEasing="ease-out"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Col>

              <Col xs={6} sm={6}>
                <div className={`so-card-achievement-info ${animatedAchievement >= 100 ? 'achieved' : ''}`} style={{ padding: '15px 20px' }}>
                  <h5 className="text-muted">Achievement</h5>
                  <h2>
                    {animatedAchievement < 100
                      ? animatedAchievement.toFixed(1) + "%"
                      : Math.floor(animatedAchievement) + "%"}
                  </h2>
                  <p>{animatedAchievement >= 100 ? "Congratulations!" : "Keep going!"}</p>

                  {/* Celebration Animation */}
                  {animatedAchievement >= 100 && (
                    <div className="position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 10 }}>
                      <LotiAnimationMobile />
                    </div>
                  )}
                </div>
              </Col>

            </Row>
          </div>
        </Col>
      </Row>


      {/* Overdue Task Section */}
      <Row className="mb-4">

        <Col sm={12}>
          <div className="so-card so-card-overdue">
            <div>
              <div className="so-card-title">Target</div>
              <div className="so-card-value">{numberFormatDecemal(Math.floor(animatedDelivery))}</div>
            </div>
          </div>
        </Col>
        <Col sm={12}>
          <div className="so-card so-card-overdue">

            <div>
              <div className="so-card-title">IMS</div>
              <div className="so-card-value-ims">{numberFormatDecemal(Math.floor(animatedDemand))}</div>
            </div>
          </div>
        </Col>
        <Col sm={12}>
          <div className="so-card so-card-overdue">

            <div>
              <div className="so-card-title">Stock</div>
              <div className="so-card-value-stock">{numberFormatDecemal(Math.floor(animatedStock))}</div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Quick Launcher */}
      <div className="so-quick-launcher">
        <div className="so-quick-launcher-icons">
          <div className="so-quick-launcher-icon" onClick={handleShowLeadsModal}>
            <FaUserAlt />
            <span>Me</span>
          </div>
          <div className="so-quick-launcher-icon">
            <Link to="/soSecondarySales" className="quick-launcher-link">

              <FaClipboardList />
            </Link>
            <span>Entry</span>
          </div>
          <div className="so-quick-launcher-icon">
          <Link to="/remoteSales/SalesPersonWiseTarget" className="quick-launcher-link">
            <FaTasks />
            </Link>
            <span>Reports</span>
          </div>
        </div>
      </div>

      {/* Leads Modal */}
      <Modal show={showLeadsModal} onHide={handleCloseLeadsModal} centered className="custom-leads-modal">
        <Modal.Body>
          {/* Modal Content */}
          <div className="custom-modal-body-content">
            <div className="custom-modal-content-title mt-3">Information :</div>
            <span><strong>Zone:</strong> {soInfo.zoneName}</span><br></br>
            <span><strong>Division:</strong> {soInfo.divisionName}</span><br></br>
            <span><strong>Region:</strong> {soInfo.areaName}</span><br></br>
            <span><strong>Territory:</strong> {soInfo.territoryName}</span><br></br>
            <span><strong>Distributor Point:</strong> {soInfo.soPointName}</span><br></br>
            <span><strong>Distributor :</strong> {soInfo.distribName},[{soInfo.distribID}]</span>
          </div>

          {/* Close Button */}
          <Button variant="close" className="custom-close-btn" onClick={handleCloseLeadsModal}>
           
          </Button>
        </Modal.Body>
      </Modal>


    </div>
  );
}

export default SOMobileDashboard;
