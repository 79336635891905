import React from 'react';
import LaytimeHeader from '../components/create/LaytimeHeader';
import LaytimeRow from '../components/create/LaytimeRow';

const LaytimeInfoContainer = () => {
    return (
        <div>
            <LaytimeHeader />
            <LaytimeRow />
        </div>
    );
}

export default LaytimeInfoContainer;
