import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";

export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_SEARCH_DATA_INPUT, payload: formData });
};
export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
};
export const submitStatusRemove = () => (dispatch) => {
  dispatch({ type: Types.ZONE_SUBMIT_STATUS_REMOVE, payload: null });
};
export const productSubmitStausRemove = () => (dispatch) => {
  dispatch({ type: Types.PRODUCT_SUBMIT_STATUS_REMOVE, payload: null });
};
export const handleSubmit = (inputData) => (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/ZoneEntry?ZoneName=${inputData.zoneName}`;
    axios
      .post(postUrl)
      .then(function (response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.messege);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({
    type: Types.ZONE_SAVE,
    payload: responseList,
  });
};
export const getDistributorList = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerbyUnit?unitId=98&AreaID=${areaId}&DistribCat=${salesTypeId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const getCorporateDistribution = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerbyUnit?unitId=98&AreaID=${areaId}&DistribCat=2`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const EditDateGet = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_SALES_LIST_EDIT_DATA, payload: item });
};
export const getCustomerBalance = (customerId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDetailsByID?CustomerID=${customerId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
};

export const getSubProductList = (unitID,salesType,salesGroupId,superdepo,distibId) => async (dispatch) => {
  console.log('salesType', salesType)
  console.log('salesGroupId', salesGroupId)
  console.log('superdepo', superdepo)
  console.log('distibId', distibId)
  
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSubSalesItemPricebyUnit?unitId=98&DistribCat=${salesType}&salesGroupId=${salesGroupId}&superdepo=${superdepo}&dispoint=${distibId}`;
  console.log('superurl :>> ', url);
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SUB_SALES_PRODUCT_LIST, payload: responseList });
};

export const getPromotionProduct = (salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };

  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPricebyUnitforPromotion?unitId=98&DistribCat=${salesType}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getCorporateProductList = (fDate, distribId, discate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetItemPriceforCorporateSales?SaleDate=${fDate}&DistribID=${distribId}&DisCat=${discate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const getsubSalesList = (areaid, salesTypeId,territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SubSalesOrderReport?AreaID=${areaid}&TerrotoryId=${territoryId}&DistribCat=${salesTypeId}`;
  
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SUB_SALES_PRODUCT_DEMAND_LIST, payload: responseList });
};
export const subSalesApprovedReport = (fromDate, todate, areaId, territoryId, salesTypeId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSubSalesDemandConfirmReport?FromDate=${fromDate}&ToDate=${todate}&Territory=${territoryId}&AreaID=${areaId}&DistribCat=${salesTypeId}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_SALES_REPORT, payload: responseList });
};

export const getCustomerProductDelivery = (areaid, fromDate, toDate, salesTypeId) => async (dispatch) => {

  let responseList = {
    isPProductLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReport?AreaID=${areaid}&FromDate=${fromDate}&ToDate=${toDate}&DistribCat=${salesTypeId}`;


  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isPProductLoading = false
  });
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
};
export const getSubSalesListDetails = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SuperDistriDemandDetails?SalesOrderInvID=${orderId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SUB_SALES_ORDER_DETAILS, payload: responseList });
};

export const getSubSalesApprovedDetails = (invoiceId) => async (dispatch) => {
  let responseList = {
    approvedLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSubSalesDemandConfirmReportDetails?InvoiceID=${invoiceId}`;
 
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.approvedLoading = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_DETAILS, payload: responseList });
};
export const getDistributorDeliveryDetails = (customerId, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
};
export const checkgetDistributorDeliveryDetails = async (customerId, fromDate, toDate) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });

};
export const getSubUpdateProductList = (orderId,salesGroupId,superDepo,superDepoDist) => async (dispatch) => {
  console.log('superDepo :>> ', superDepo);
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReportforUpdateSub?SalesOrderInvID=${orderId}&salesGroupId=${salesGroupId}&superdepo=${superDepo}&distibid=${superDepoDist}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SUB_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};
export const getCorporateUpdateProductList = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReportforUpdateCorporateSales?SalesOrderInvID=${orderId}`;

  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};

export const productSelecteditem = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_SELECTED, payload: item });
};
export const delteProductList = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_DELETE, payload: item });
};
export const SubproductQuantity = (item,name, value) => async (dispatch) => {
  const formData = {
    item: item,
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_SUB_PRODUCT_QUANTITY, payload: formData });
};

export const SuperDepoproductUpdateInputQuantity = (item,name, value) => async (dispatch) => {
  const formData = {
    item: item,
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_UPDATE_QUANTITY, payload: formData });
};
export const InsertSubDisSalesOrder = (submitData, productList, selectZone, selectDivision, selectArea,selectTerritory,superDistribId,startDate,salesGroupId,superdistributorID,superDispointId) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
      bonusQty:0,
      damageQty:0
      
    };
    console.log('dipo productList :>> ', productList);
    if (productList[i].itemQuentity > 0 || productList[i].damageQty || productList[i].BonusQty) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.damageQty = productList[i].damageQty;
      productData.itemVatPrice = productList[i].vatPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      productData.bonusQty = productList[i].BonusQty;
      newList.push(productData);
    }
  }

  console.log('dipo newList :>> ', newList);


  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  try {

    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSubDisSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&DistribPointID=${submitData?.disPointID}&DelPointID=${submitData?.delPointID}&CustomerID=${submitData?.cusid}&MonCustBalance=${submitData?.generaL_BALANCE}&Coaid=${submitData?.intcoaid}&Intsalesofficeid=${submitData?.intsalesoffid}&superDistribId=${superDistribId}&DistribCat=${submitData?.distribCat}&salesDate=${startDate}&salesGroupId=${submitData?.disSalesGroupId}&superid=${superdistributorID}&superdistId=${superDispointId}`;
   
    // let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSubDisSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&superDistribId=${superDistribId}&DistribCat=${distribCat}`;
    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
        console.log('response', response);
        if (response.data.statusCode === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        } else {
          responseList.status = response.data.status;
          responseList.submitLoading = false;
          showToast("error", response.data.message);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
       
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
      });
  } catch (error) {
   
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  }


};
export const stockSubmit = (submitData, productList, selectZone, selectDivision, selectArea) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;

  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }


  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertDistribStok?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DistribID=${intCustomer}&SDate=2022-02-28`;
    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }
  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};

export const corporateSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea, salesDate, distribaddress) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;



  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });



  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrderCorporateSales?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}&DistribAddress=${distribaddress}&SalesDate=${salesDate}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
export const sampleSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea, salesDate, distribaddress, isVat, remarks) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };




  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSampleSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}&Remarks=${remarks}&VatImpact=${isVat}`;
    // let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}/api/RemoteOrder/InsertSampleSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
export const tradeOfferSalesSubmit = (submitData, productList, selectZone, selectDivision, selectArea, salesDate, distribaddress) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;
  const { intCustomer, monCustBalance, coaid, intsalesofficeid, delPointID, disPointID, distribCat } = submitData;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };
  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {

      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertPromotionalSalesOrder?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&DistribPointID=${disPointID}&DelPointID=${delPointID}&CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}&DistribCat=${distribCat}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};
export const subsalesUpdate = (invoiceId,superdepo,superdepodist, productList) => async (dispatch) => {
  console.log('productList :>> ', productList);

  let newList = [];
  let quantityStatus = true;


  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
      bonusQty:0,
      damageQty:0,
    };
    const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      productData.salesGroupId = productList[i].salesGroupId;
      productData.bonusQty = productList[i].bonusQty;
      productData.damageQty = productList[i].damageQty;
      newList.push(productData);
    }

  }

  if (newList.length == 0) {
    showToast("error", "Please type at least One quantity");
    return false;
  }
  // const { intCustomer, monCustBalance, coaid, intsalesofficeid } = submitData;
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SubSalesOrderUpdate?SubSalesOrderInvID=${invoiceId}&superDepo=${superdepo}&distibId=${superdepodist}`;
    axios
      .put(postUrl, newList)
      .then(function (response) {
        console.log('response :>> ', response);
        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.isLoading = false;
        responseList.status = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};

export const SubsalesDelete = (id, areaId,salesType) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_SALES_TITEM, payload: isLoading })
  axios.delete(`${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SubSalesOrderDelete?SalesOrderInvID=${id}`)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_SALES_TITEM, payload: false });
        dispatch(getsubSalesList(areaId, salesType));
      }
    })
}
export const cleanRemoteSalesData = (id) => (dispatch) => {
  dispatch({ type: Types.CLEAR_REMOTE_SALES_DATA, payload: null });
}
export const getSuperDepChildDis = (distributorId,areaId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSuperDepoChildDistributor?superDepoCode=${distributorId}&AreaId=${areaId}`;
  await axios.get(url).then((res) => {
    console.log('ressub', res)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SUB_SUPER_DEPO, payload: responseList });
};
export const GetSuperDistributor = (territory) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSuperDistributorInfo?AreaId=${territory}`;
  await axios.get(url).then((res) => {
    console.log('ressuper :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SUPER_DISTRIBUTOR, payload: responseList });
};

export const handleSubSalesApperoved = (invId,areaId, salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SubSalesOrderApproved?SubSalesOrderInvID=${invId}`;
  await axios.put(url).then((res) => {
   
    responseList.data = res.data.data;
    responseList.status = false;
    showToast("success", `${res.data.message}`);
    dispatch(getsubSalesList(areaId, salesType));
  });
};