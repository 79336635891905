import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  getCustomerProductDelivery,
  getSalesList,
  salesDelete,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { InputHandle } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import CustomerDeliveryDetailsReport from "./CustomerDeliveryDetailsReport";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";

const CustomerProductDeliveryList = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const isPProductLoading = useSelector(
    (state) => state.remoteSales.isPProductLoading
  );
  const [currentPage, setCurrentPage] = useState(1);
  const productDelivery = useSelector(
    (state) => state.remoteSales.productDelivery
  );
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);


  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();
  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);

    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    setStartDate('08/Dec/2021');

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id));
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {

    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



  const itemDetail = (item) => {

    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (startDate == null) {
      alert("Please select From Date");
      return false;
    } else if (toDate == null) {
      alert("Please select To Date");
      return false;
    }
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const showBrReport = () => {
    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (startDate == null) {
      alert("Please select From Date");
      return false;
    } else if (toDate == null) {
      alert("Please select To Date");
      return false;
    }
    dispatch(getCustomerProductDelivery(selectArea, selectTerritory, startDate, toDate, salesType));
  };
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }



  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
        {/* <div className="container"> */}
        {/* <CustomerDeliveryReportFilter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onFilterChange={() => printFilter(inputField)}
        /> */}
        {/* <div className="col-lg-12">
           
              <div  className="float-right mt-6" onClick={()=>history.go(0)}>
                 <i className="fas fa-sync-alt editIcon refreshIcon ml-4" ></i>
                 <p>Refresh</p>
              </div>
          </div> */}
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <span className="button-010">Product Lifting Report</span>
                {/* <h3 className="mb-0 pb-0">Product Lifting Report</h3> */}
              </div>
            </div>
            <SimpleSalesType
              distribType={distribType}
              salesType={salesType}
              getSalesValue={getSalesValueType}
            />
          </div>
        </div>
        <hr></hr>

        <div className="form-group row">

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> From Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  setSelectArea(option?.value);
                  getTerritoryData(option?.value)
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {

                  setSelectTerritory(option?.value);


                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3 mt-5 mb-6">
            <button className="button-621" onClick={() => showBrReport()} >  Show Report</button>
            {/* <Button variant="primary" onClick={() => showBrReport()} size="sm" className="mt-6 mb-5">
              Show Report

            </Button> */}
            {/* <div className="float-right" onClick={() => history.go(0)} >
              <i className="fas fa-sync-alt editIcon refreshIcon ml-4" ></i>
              <p>Refresh</p>
            </div> */}
          </div>

          {/* <div className="col-lg-3">
              <label className="form-label">Select Distributor</label>
              <RHFInput
                as={<Select options={distributorList} />}
                rules={{ required: true }}
                name="intDistributorId"
                register={register}
                value=""
                // value={itemDataInput.intDistributorId}
                onChange={(option) => {
                  inputChangeHandle("distributorName", option.label);
                  inputChangeHandle("intDistributorId", option.value);
                  
                }}
                setValue={setValue}
              />
              <div className="inputError margin-minus-8">
                {errors.intDistributorId &&
                  errors.intDistributorId.ref.value === undefined &&
                  "Distributor can't be blank"}
              </div>
            </div> */}

          {/* <div className="col-xl-6 col-lg-6 col-md-6 ">
              <Form.Group>
                <Form.Label className="formFont pl-1">
                  Search Product
                </Form.Label>
                <Form.Control
                  rules={{ required: true }}
                  className="formHeight"
                  type="text"
                  placeholder="Enter product Name"
                  name="itemName"
                  value={itemDataInput.itemName}
                  onChange={(e) => changeText("itemName", e.target.value)}
                 
                />
              
              </Form.Group>
              <div className="serchproduct">
                {fillterProductList.length > 0
                  ? fillterProductList.map((item, index) => (
                      <p onClick={() => productSelected(item)}>
                        {item.zoneName}
                      </p>
                    ))
                  : null}
              </div>
            </div> */}

          {/* <div className="col-lg-2 mt-5">
            <button className="btn btn-primary btn-sm float-right text-center custome-addnew-btn item-add-save mb-5">
              ADD
            </button>
          </div> */}
        </div>

        {/* {isPProductLoading && (
          <div className="mt-5">
            <LoadingSpinner text="Loading Inforamation " />
          </div>
        )}
        {!isPProductLoading && productDelivery.length === 0 && (
          <div className="alert alert-warning mt-5">
            Sorry ! No Information Found.
          </div>
        )} */}

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isLoading && <LoadingSpinner text="Loading Items List..." />}
          <div
            className="shortstickyTable"
            style={{ height: productDelivery?.length > 0 ? 800 + "px" : 0 }}
          >
            <div className="react-bootstrap-table table-responsive border-0 pl-5">

              {/* <table className="table table-head-custom table-vertical-center  item-add-table"> */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" >
                <thead>
                  <tr>
                    {/* <th scope="col">Department</th> */}
                    {/* <th scope="col">ORDER ID</th> */}

                    <th scope="col">CUSTOMER ID</th>
                    <th scope="col">CUSTOMER NAME</th>
                    {/* <th scope="col">REGION</th>
                  <th scope="col">TERRITORY</th> */}
                    <th scope="col">QUANTITY</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {productDelivery.length > 0 &&
                    productDelivery.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{item.distribCode}</td>
                        <td>{item.distribName}</td>
                        {/* <td>{item.areaname}</td>
                      <td>{item.territoryName}</td> */}
                        <td>{item.quentity}</td>
                        <td>
                          {" "}
                          <Link onClick={() => itemDetail(item)}>
                            <i className="far fa-eye editIcon item-list-icon"></i>
                          </Link>
                          {/* <Link to={`/remoteSales/edit/${item}`}>
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link> */}
                          {/* <a
                          href
                          onClick={(id) => handleDeleteItem(item.orderId)}
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {!isLoading && productDelivery.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! product List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <CustomerDeliveryDetailsReport
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
            fromDate={startDate}
            toDate={toDate}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default CustomerProductDeliveryList;
