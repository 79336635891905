import React from "react";

import LoadableCalculator from "../components/LoadableCalculator";

export default function LoadableCalculatorContainer() {
  return (
    <div>
      <LoadableCalculator />
      {/* <BasicInfoAdd /> */}
    </div>
  );
}
