import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
    itemAddInput,
    InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryAction";
import { getAreaListByDivision, getAreaListByDivisionEntry, getDivisionListByZone, getDivisionListByZoneEntry, getTerritory, getTerritoryEntry, getZoneList } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { handleFundSubmit } from "../../_redux/actions/SecondarySales/FundAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";



const FundCollection = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { register, handleSubmit, errors, setValue, setFocus, fund } = useForm();

    const itemDataInput = useSelector((state) => state.secondary.itemDataInput);
    const productCost = useSelector((state) => state.secondary.productCost);
    const fillterProductList = useSelector(
        (state) => state.secondary.filterProduct
    );
    const productList = useSelector((state) => state.secondary.productList);

    const submitLoading = useSelector((state) => state.secondary.submitLoading);
    const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
    const availableBalance = useSelector(
        (state) => state.secondary.availableBalance
    );
    const restBalance = useSelector(
        (state) => state.secondary.restBalance
    );
    const creditLimit = useSelector((state) => state.secondary.creditLimit);
    const countProduct = useSelector((state) => state.secondary.productQuantity);
    const balanceLoader = useSelector((state) => state.secondary.balanceLoader);
    const submitData = useSelector((state) => state.secondary.submitData);
    const productSubmitStatus = useSelector(
        (state) => state.secondary.productSubmitStatus
    );
    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);
    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [selectGeoType, setSelectGeoType] = useState(2);
    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);
    const [fundAmount, setFundAmount] = useState(null);
    const [orderValue, setOrderValue] = useState(null);

    console.log('orderValue :>> ', orderValue);

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});

    // Redirect List Page after submitting data
    useEffect(() => {
        if (productSubmitStatus) {
            setTimeout(() => {

            }, 3000);

            // dispatch(cleanRemoteSalesData());
        }
    }, [productSubmitStatus]);


    useEffect(() => {
        initialData();
    }, []);

    // const initialData = async () => {
    //     let zoneData = await getZoneList();
    //     Setzone(zoneData.data);
    // }

    const initialData = async () => {
        var permissionwise = await GetPermissionDropDown()
        .then((res) => {
          const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
          setDefaultZone({ label: zoneName, value: zoneId });
          setdefultDivision({ label: divisionName, value: divisionId });
          setdefaultArea({ label: areaName, value: araId });
          setdefultTerritory({ label: territoryName, value: territoryId });
    
          setSelectZone(zoneId);
          setSelectDivision(divisionId);
          setSelectArea(araId);
          setSelectTerritory(territoryId);
    
          divisionList(zoneId);
          getAreaData(divisionId);
          getTerritoryData(araId);
    
        });
        let zoneData = await getZoneList();
    
        Setzone(zoneData.data);
      };
    

    const changeText = (name, value) => {
        dispatch(itemAddInput(name, value));
    };
    const inputChangeHandle = (name, value) => {
        dispatch(InputHandle(name, value));
    };


    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };


    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
      }
      const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
      }
      const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
      }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };


    const onSubmit = async (e) => {
        // if (fundDate == null) {
        //     alert("Please select Date");
        //     return false;
        // } else if (selectZone == null) {
        //     alert("Please select Zone");
        //     return false;
        // } else if (selectDivision == null) {
        //     alert("Please select Division");
        //     return false;
        // } else if (selectArea == null) {
        //     alert("Please select Region");
        //     return false;
        // } else if (selectTerritory == null) {
        //     alert("Please select Territory");
        //     return false;
        // }  else {

        const submit = handleFundSubmit(startDate, selectZone, selectDivision, selectArea, selectTerritory, fundAmount, orderValue);
        // }
    };

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);

    };

    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };
    return (
        <>
            <form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
                autoComplete="off"
            >
                <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

                    <div className="row mt-3">
                        <div className="col-md-9">
                            <div className="mt-2 p-2">
                                <h3 className="mb-0 pb-0">Fund Collection</h3>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    <div className="form-group row">
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label"> Collection Date</label>

                                <Form.Control
                                    type="date"
                                    placeholder="Enter Deadweight"
                                    name="fromDate"
                                    className="fromStyle"
                                    onChange={handleChange}
                                    value={startDate}
                                />
                            </Form.Group>

                        </div>

                        <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    setdefultDivision(null);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    
                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    setdefultTerritory(null);
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>


                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Fund Amount</label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Fund"
                                    name="fundAmount"
                                    className="fromStyle"
                                    onChange={(e) => {
                                        setFundAmount(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <label className="form-label">Order Value</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Fund"
                                    name="ordervalue"
                                    className="fromStyle"
                                    onChange={(e) => {
                                        setOrderValue(e.target.value)
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3">
                            <button type="submit" className="btn btn-primary btn-sm text-center mt-5">Submit</button>
                        </div>
                    </div>

                </div>


                {/* <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
                    {productList.length > 0 ? (
                        <div className="react-bootstrap-table table-responsive mt-8">
                            <table className="table table table-head-custom table-vertical-center">
                                <thead>
                                    <th>SL</th>
                                    <th>ZONE</th>
                                    <th>DIVISION</th>
                                    <th>REGION</th>
                                    <th>TERRITORY</th>
                                    <th>COLLECTION DATE</th>
                                    <th>AMOUNT</th>
                                </thead>
                                <tbody>
                                    
                                    <tr>
                                    <td>01000</td>
                                    <td>South</td>
                                    <td>Chattagram</td>
                                    <td>Chattagram-2</td>
                                    <td>Hathajari</td>
                                    <td>18-10-2022</td>
                                    <td>50000000</td>
                                    </tr>
                                    <tr>
                                    <td>02000</td>
                                    <td>South</td>
                                    <td>Chattagram</td>
                                    <td>Chattagram-2</td>
                                    <td>Hathajari</td>
                                    <td>18-10-2022</td>
                                    <td>50000000</td>
                                    </tr>
                                    <tr>
                                    <td>01000</td>
                                    <td>South</td>
                                    <td>Chattagram</td>
                                    <td>Chattagram-2</td>
                                    <td>Hathajari</td>
                                    <td>18-10-2022</td>
                                    <td>50000000</td>
                                    </tr>
                                    <tr>
                                    <td>01000</td>
                                    <td>South</td>
                                    <td>Chattagram</td>
                                    <td>Chattagram-2</td>
                                    <td>Hathajari</td>
                                    <td>18-10-2022</td>
                                    <td>50000000</td>
                                    </tr>
                                    <tr>
                                    <td>01000</td>
                                    <td>South</td>
                                    <td>Chattagram</td>
                                    <td>Chattagram-2</td>
                                    <td>Hathajari</td>
                                    <td>18-10-2022</td>
                                    <td>50000000</td>
                                    </tr>
                
                                </tbody>

                            </table>
                        </div>
                    ) : null}
                </div> */}

            </form>
        </>
    );
};

export default FundCollection;
