import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams,useLocation  } from "react-router-dom";

import {
  delteProductList,
  productSelecteditem,
  itemAddInput,
  InputHandle,
  getUpdateProductList,
  productUpdateInputQuantity,
  salesUpdate,
  getUpdateSalesGroupProductList,
  getCustomerSalesGroupInfo,
  GetNewCustomerBalanceByID,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";

const RemoteSalesEdit = (props) => {
  console.log('props', props);
  const dispatch = useDispatch();
  const history = useHistory();


  const { id,name,code } = useParams();
  const custId = code.slice(1);
  console.log('custId', custId);

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const location = useLocation();
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const salesEditInfo = useSelector((state) => state.remoteSales.salesEditInfo);
  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled,setDisabled ]=useState(false);
  const salesUpdateData = useSelector(
    (state) => state.remoteSales.salesUpdateData
  );

  const balanceLoader = useSelector((state) => state.remoteSales.balanceLoader);
  const availableBalance = useSelector(
    (state) => state.remoteSales.availableBalance
  );
  const creditLimit = useSelector((state) => state.remoteSales.creditLimit);
  const restBalance = useSelector(
    (state) => state.remoteSales.restBalance
  );
  const productCost = useSelector((state) => state.remoteSales.productCost);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);
  const distributionSalesGroupId = useSelector((state) => state.remoteSales.distributionSalesGroupId);
  console.log('distributionSalesGroupId', distributionSalesGroupId);

  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);
  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);
  

 

  // Redirect List Page after submitting data
  useEffect(() => {
    if (productUpdateStatus) {

       setTimeout(() => {history.go(0)}, 2000);
    }

  }, [productUpdateStatus]);

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
   
    // dispatch(getDistributorList());
    //dispatch(getUpdateProductList(id));
   //dispatch(getCustomerBalance(code));
   dispatch(GetNewCustomerBalanceByID(code));
   
    //dispatch(getCustomerBalance(id));
    
    // dispatch(getItemCategory())
    getInitialData();
  }, []);

  const getInitialData=async ()=>{
    var salesGroupData = await getCustomerSalesGroupInfo(code);
    console.log('salesGroupData', salesGroupData);
    if(salesGroupData?.data !=null){
      console.log('salesGroupData', salesGroupData.data.disSalesGroupId);
      dispatch(getUpdateSalesGroupProductList(id,salesGroupData?.data.disSalesGroupId));
    }
    
  }

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (name, value) => {
    dispatch(productUpdateInputQuantity(name, value));
  };

  const onSubmit = async (e) => {
    setDisabled(true);
    dispatch(salesUpdate(id, salesUpdateData));
    // setDisabled(false);
  };

  const department = [
    {
      id: 1,
      label: "Store",
    },
    {
      id: 2,
      label: "Engine",
    },
  ];
  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border p-3">
          <div className="mt-5">
            <h3 className="mb-0 pb-0"> Sales Update</h3>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">{name}</strong>
                    <br />
                   {code}
                    <br />
                   
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row"></div>
        </div>
         <div className="container mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left" >
                 
                  <p className="balanceleft">Available Balance</p>
                  <p className="balanceleft">Rest Balance</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <div>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                    </div>

                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  <p>Credit Limit</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  <p>Total Product Cost</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  <p>Total Quantity</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.itemQuentity}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/remoteSales/list";
                  }}
                  type="button"
                  className="btn btn-info 
                  btn-sm float-right text-center 
                  mb-5  mr-5"
                >
                  Close
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default RemoteSalesEdit;
