import React from "react";
import POList from "../components/list/POList";

const POListContainer = () => {
  return (
    <>
      <div className="container">
        <POList />
      </div>
    </>
  );
};

export default POListContainer;
