/*************** CERTIFICATE ******************/
export const CERTIFICATE_LIST_HOME = "CERTIFICATE_LIST_HOME";
export const CERTIFICATE_LIST_DASHBOARD = "CERTIFICATE_LIST_DASHBOARD";
export const CERTIFICATE_LIST_LOADING = "CERTIFICATE_LIST_LOADING";
export const CERTIFICATE_DETAIL = "CERTIFICATE_DETAIL";

export const ADD_MULTIPLE_DATA = "ADD_MULTIPLE_DATA";
export const ADD_MULTIPLE_DATA_EDIT = "ADD_MULTIPLE_DATA_EDIT";
export const DELETE_SURVEY_MULTIPLE_DATA = "DELETE_SURVEY_MULTIPLE_DATA";
export const DELETE_SURVEY_MULTIPLE_DATA_EDIT = "DELETE_SURVEY_MULTIPLE_DATA_EDIT";
export const DELETE_SURVEY_MULTIPLE_ATTACHMENT = "DELETE_SURVEY_MULTIPLE_ATTACHMENT";
export const DELETE_SURVEY_MULTIPLE_ATTACHMENT_EDIT = "DELETE_SURVEY_MULTIPLE_ATTACHMENT_EDIT";
export const ADD_MULTIPLE_DATA_ATTACHMENT = "ADD_MULTIPLE_DATA_ATTACHMENT";
export const ADD_MULTIPLE_DATA_ATTACHMENT_EDIT = "ADD_MULTIPLE_DATA_ATTACHMENT_EDIT";
export const DELETE_SURVEY_MULTIPLE_DATA_ATTACHMENT = "DELETE_SURVEY_MULTIPLE_DATA_ATTACHMENT";
export const DELETE_SURVEY_MULTIPLE_DATA_ATTACHMENT_EDIT = "DELETE_SURVEY_MULTIPLE_DATA_ATTACHMENT_EDIT";
export const CHANGE_CERTIFICATE_INPUT = "CHANGE_CERTIFICATE_INPUT";
export const CHANGE_CERTIFICATE_INPUT_UPDATE = "CHANGE_CERTIFICATE_INPUT_UPDATE";
export const EMPTY_CERTIFICATE_MESSAGE = "EMPTY_CERTIFICATE_MESSAGE";
export const LOADING = "UPDATE_LOADING";
export const EDITING = "EDITING";
export const DELETING = "DELETING";
export const CREATE_CERTIFICATE = "CREATE_CERTIFICATE";
export const EDIT_CERTIFICATE_INFO = "EDIT_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const GET_CERTIFICATE_CATEGORY = "GET_CERTIFICATE_CATEGORY";
export const GET_CERTIFICATE_NAME = "GET_CERTIFICATE_NAME";
export const GET_CERTIFICATE_TYPE = "GET_CERTIFICATE_TYPE";
export const GET_CERTIFICATE_ISSUE_BY = "GET_CERTIFICATE_ISSUE_BY";
export const GET_VESSEL_TYPE = "GET_VESSEL_TYPE";
/*************** CERTIFICATE ******************/

/*********create main certificate************/
export const CERTIFICATE_MAIN_SUBMIT = "CERTIFICATE_MAIN_SUBMIT";
export const CERTIFICATE_MAIN_SUBMITTING = "CERTIFICATE_MAIN_SUBMITTING";
/*********get single certificate info************/
export const GET_MAIN_CERTIFICATE_SINGLE_DATA = "GET_MAIN_CERTIFICATE_SINGLE_DATA";
export const MAIN_CERTIFICATE_UPDATE = "MAIN_CERTIFICATE_UPDATE";
export const MAIN_CERTIFICATE_STATUS = "MAIN_CERTIFICATE_STATUS";

/*********Color code change************/
export const CHANGE_STATUS_BACKGROUD = "CHANGE_STATUS_BACKGROUD";
export const BOTTOM_STATUS_LIST = "BOTTOM_STATUS_LIST";