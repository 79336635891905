import React from "react";
import BookingEdit from "../components/BookingEdit";

const BookingEditContainer = () => {
  return (
    <div className="container">
      <BookingEdit />
    </div>
  );
};

export default BookingEditContainer;
