import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";

import {
  InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SecondarySalesDetails from "./SecondarySalesDetails";
import { getFundList } from "../../_redux/actions/SecondarySales/FundAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const FundList = () => {
  const history = useHistory();
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const Fundlist = useSelector((state) => state.fund.fundList);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (item) => {

    dispatch(salesDelete(item, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (item) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(item),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };

  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {

    dispatch(EditDateGet(item));
    // history.push(`/secondary/edit/${item.soid}/${item.custName}/${item.orderCode}`);
    // history.push(`/secondary/edit/${item.soid}/${item.sDate}`);
    history.push(`/secondary/edit/${item.soPointID}/${item.sDate}`);
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const showReport = () => {
    dispatch(getFundList(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate));
  }



  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Fund List</h3>
            </div>
          </div>

        </div>

        <hr></hr>

        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label">Select Zone</label>
            <Select
              options={zoneDropdownData()}
              value={defultZone}
              onChange={(option) => {
                divisionList(option?.value);
                setSelectZone(option?.value);
                setDefaultZone(option)
                setValue("divisionData", "");
                setValue("areaData", "");
                setValue("territory", "");
              }}
              defaultValue={defultZone}
            />
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label">Select Division</label>
            <Select
              options={divisionDropdownData()}
              value={defultDivision}
              isClearable
              onChange={(option) => {
                getAreaData(option?.value);
                setSelectDivision(option?.value);
                setdefultDivision(option)
                setValue("areaData", "");
                setValue("territory", "");

              }}
              defaultValue={defultDivision}
            />
          </div>
          <div className="col-lg-3 mt-0 ml-4">

            <label className="form-label">Select Region</label>
            <Select
              options={areaDropdownData()}
              value={defaultArea}
              isClearable
              onChange={(option) => {
                getTerritoryData(option?.value);
                setSelectArea(option?.value);
                setdefaultArea(option)
                setValue("territory", "");
              }}
              defaultValue={defaultArea}
            />
          </div>


          <div className="col mt-6 mb-5">
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
              className="shortstickyTable"
              style={{ height: Fundlist?.length > 0 ? 800 + "px" : 0 }}
            >
              <table className="table table-head-custom table-vertical-center  item-add-table">
                <thead>
                  <tr>
                    {/* <th scope="col">Department</th> */}
                    {/* <th scope="col">ORDER ID</th> */}
                    <th scope="col">DATE</th>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">FUND AMOUNT</th>
                    <th scope="col">ORDER VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  {Fundlist?.length > 0 &&
                    Fundlist?.map((item, index) => (
                      <tr>
                        {/* <td>{item.orderId}</td> */}
                        <td>{item.zonename}</td>
                        <td>{item.zonename}</td>
                        <td>{item.divisionname}</td>
                        <td>{item.areaname}</td>

                        <td>{parseFloat(item.fundAmount).toFixed(2)}</td>
                        <td>{parseFloat(item.orderValue).toFixed(2)}</td>

                        {/* <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        <Link to={`/remoteSales/edit/${item.orderId}`}>
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <Link onClick={() => gotoEditPage(item)} title="Edit">
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <a
                          href
                          onClick={(id) => handleDeleteItem(item)}
                          title="Delete"
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {!isLoading && Fundlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Fund List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SecondarySalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default FundList;
