import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  delteProductList,
  getDistributorList,
  productSelecteditem,
  itemAddInput,
  InputHandle,
  getSOListByArea,
  getSOListTemp,
  salesSubmitDemo,
  getProductListForTemp,
  DemoProductQuantity,
  cleanRemoteSalesDatafEB,
  getSoDetailsByPointId,
} from "../../_redux/actions/SecondarySales/SecondaryAction";
import { getAreaListByDivision, getDivisionListByZone, getZoneList } from "../../_redux/actions/RemoteSales/DistributorReportAction";

const SecondarySalesDemo = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const itemDataInput = useSelector((state) => state.secondary.itemDataInput);
  const productCost = useSelector((state) => state.secondaryFeb.productCost);
  const fillterProductList = useSelector(
    (state) => state.secondary.filterProduct
  );
  const productList = useSelector((state) => state.secondaryFeb.productList);
  const submitLoading = useSelector((state) => state.secondaryFeb.submitLoading);
  const itemSUbmit = useSelector((state) => state.itemList.itemSUbmit);
  const availableBalance = useSelector(
    (state) => state.secondaryFeb.availableBalance
  );
  const restBalance = useSelector(
    (state) => state.secondaryFeb.restBalance
  );

  

  const creditLimit = useSelector((state) => state.secondaryFeb.creditLimit);
  const countProduct = useSelector((state) => state.secondaryFeb.productQuantity);
  const balanceLoader = useSelector((state) => state.secondaryFeb.balanceLoader);
  const submitData = useSelector((state) => state.secondaryFeb.submitData);
  const feStatus = useSelector(
    (state) => state.secondaryFeb.febProStatus
  );



  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [soList, setSoList] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [selectSo, setSelectSo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [salesType, setSalesType] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [distribType, setDistribType] = useState(null);
  let [distributionId, setdistributionId] = useState(null);
  const [soId, setSoId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [sOPointid, setSoPointId] = useState(null);
  const [pointDetails, setPointDetails] = useState([]);

  const [selectGeoType,setSelectGeoType] = useState(2);




  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);

    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(getProductListForTemp(user.employeeType));
    dispatch(cleanRemoteSalesDatafEB());
    
  }, [])
  // Redirect List Page after submitting data
  useEffect(() => {

    if (feStatus) {
      setTimeout(() => { 
        setValue("intDistributorId", "");
        getSOListDate(selectArea,startDate);
        setDisabled(false);
      }, 3000);
      dispatch(getProductListForTemp());
      dispatch(cleanRemoteSalesDatafEB());
    }
  }, [feStatus]);

  const initialSOData = async ()=>{
    // setDisabled(false);
    let soData =  await getSOListByArea(selectArea,startDate);
    let soDetai = soData.data.slice();
    setSoList(soDetai);
  }

  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const changeText = (name, value) => {
    dispatch(itemAddInput(name, value));
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  function productSelected(productItem) {
    dispatch(productSelecteditem(productItem));
  }

  const delteItem = (itemId) => {
    dispatch(delteProductList(itemId));
  };
  const changeProductQuantity = (name, value) => {
   
    dispatch(DemoProductQuantity(name, value));
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };


  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  }

  

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setValue("intDistributorId", "");
    setValue("divisionData", "");
    setValue("areaData", "");
    dispatch(getDistributorList(selectArea, value));
    dispatch(getProductListForTemp(value));

  }
  const getSOListDate = async (value,date) => {
    let solIst = await getSOListTemp(value,date);
    setSoList(solIst.data);
  }

  const soDropDownData = () => {
    let options = [];
    if (soList?.length > 0) {
      soList.forEach((item) => {
        let itemData = {
          value: item.soid,
          label: item.soName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const handleDistribution = async (item) => {
   
    // let distrInfo = await getSODetailsBySOIDTemp(item);
    // setdistributionId(distrInfo?.data[0]);
    let pointInfo = await getSoDetailsByPointId(item);
    setPointDetails(pointInfo.data[0]);
    // setSoId(item);
  };


  const onSubmit = async (e) => {
    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (itemDataInput.intDistributorId == "") {
      alert("Please select So");
      return false;
    }
    setDisabled(true);
    dispatch(salesSubmitDemo(submitData, productList, distributionId, soId,startDate,pointDetails));
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
    getSOListDate(selectArea,input.value);
  };


  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3">

          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <h3 className="mb-0 pb-0">Secondary Sales For February</h3>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="form-group row"></div>
          <div className="form-group row">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Zones</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={zoneDropdownData()} />}
                  rules={{ required: false }}
                  name="zoneData"
                  register={register}
                  // value={selectZone}
                  value=""
                  onChange={(option) => {

                    divisionList(option.value);
                    setSelectZone(option.value);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("intDistributorId", "");

                  }}
                  setValue={setValue}
                />

              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Division</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={divisionDropdownData()} />}
                  rules={{ required: false }}
                  name="divisionData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)
                    getAreaData(option.value);
                    setSelectDivision(option.value);

                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Area</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={areaDropdownData()} />}
                  rules={{ required: false }}
                  name="areaData"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    // setType(option.value)

                    // dispatch(getDistributorList(option.value, salesType));
                   
                    setSelectArea(option.value);
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Deadweight"
                  name="fromDate"
                  className="fromStyle"
                  onChange={handleChange}
                  value={startDate}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6 ml-4">
              <label className="form-label">Select SO</label>
              <RHFInput
                as={<Select options={soDropDownData()} />}
                rules={{ required: false }}
                name="intDistributorId"
                register={register}
                value=""
                // value={itemDataInput.intDistributorId}
                onChange={(option) => {
                  inputChangeHandle("distributorName", option.label);
                  inputChangeHandle("intDistributorId", option.value);
                  handleDistribution(option.value);
                  setSoPointId(option.value);
                }}
                setValue={setValue}
              />
             
            </div>
          </div>
        </div>
        <div className="container mb-3 containerFixed">
          <div className="row">
           
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Product Cost</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Quantity</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {productList.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  <th>VALUE</th>
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      <td>{parseFloat(item.prodTrdpricebypic).toFixed(2)}</td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            // value={item.quantity}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                {/* {
                  submitLoading?
                  <button
                 
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit .....
                </button>:  */}
                <button
                  type="submit"
                  // disabled={disabled}
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Submit
                </button>
                {/* } */}

              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default SecondarySalesDemo;
