import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Pdf from "react-to-pdf";
import NewLoader2 from '../../../../master/spinner/NewLoader2';
import { divisionSalesReport, DivisionSalesReportActiveInActive, RegionSalesReportActiveInActive, territorySalesReport, TerritorySalesReportActiveInActive, ZoneSalesReport, ZoneSalesReportActiveInActive } from '../../../information/_redux/actions/Report/ReportAction';
import { areaSalesReport } from './../../../information/_redux/actions/Report/ReportAction';
import { useState } from 'react';
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FaSearch, FaFileExcel, FaClock, FaTable } from 'react-icons/fa';
import PrintNew from '../../../../master/PrintDwopdown/PrintNew';

import { format, subDays } from 'date-fns';
import DatePicker from "react-datepicker";
import { dateTimeConvertLongStringForDate, numberFormatDecemal } from '../../../../../domains/CCO/utils/DateHelper';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import SimpleModal from '../../../../master/components/Modal/SimpleModal';
import TopFiveDSM from './TopFiveDSM';
import TopFiveRM from './TopFiveRM';
import TopFiveTSM from './TopFiveTSM';
import Bottom20TSM from './Bottom20TSM';
import Bottom10RM from './Bottom10RM';
import Bottom3DSM from './Bottom3DSM';
import { formatDate } from '../../../../master/PrintDwopdown/DateHelper';
import SOQtyTerritoryActiveInactiveView from './SOQtyTerritoryActiveInactiveView';
import SOQtyRegionActiveInactiveView from './SOQtyRegionActiveInactiveView';
import SOQtyDivisionActiveInactiveView from './SOQtyDivisionActiveInactiveView';
import SOQtyZoneActiveInactiveView from './SOQtyZoneActiveInactiveView';
import DotLoading from '../../../../master/spinner/DotLoading';
import SpinnerLoading from '../../../../master/spinner/SpinnerLoading';

export const numberFormat = (number) => {
    if (number == null) {
        return "0";
    } else {
        const parts = number.toString().split(".");
        const wholePart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        const formattedValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart;
        return formattedValue;
    }
};


const TarVSacvZDATwise = () => {

    const { register, control, setValue } = useForm();

    const reportDataLoading = useSelector((state) => state.target.zoneReportsLoading);
    const reportData = useSelector((state) => state.target.zoneReports);

    const divisionReports = useSelector((state) => state.target.divisionReports);
    const divisionReportsLoading = useSelector((state) => state.target.divisionReportsLoading);

    const areaReports = useSelector((state) => state.target.areaReports);
    const areaReportsLoading = useSelector((state) => state.target.areaReportsLoading);

    const territoryReports = useSelector((state) => state.target.territoryReports);
    const territoryReportsLoading = useSelector((state) => state.target.territoryReportsLoading);
    const TotalTarget = useSelector((state) => state.target.TotalTarget);

    const totalDays = useSelector((state) => state.target.totalDays);
    const workingLeft = useSelector((state) => state.target.workingLeft);
    const [selectFilterData, setSelectFilterData] = useState(1);

    const [itemDetailShow, setItemDetailShow] = useState(false);
    const [itemifo, setItemifo] = useState(null);

    const [itemDetailShow1, setItemDetailShow1] = useState(false);
    const [itemifo1, setItemifo1] = useState(null);

    const [itemDetailShow2, setItemDetailShow2] = useState(false);
    const [itemifo2, setItemifo2] = useState(null);

    const [itemDetailShow3, setItemDetailShow3] = useState(false);
    const [itemifo3, setItemifo3] = useState(null);


    const [reportList, setReportList] = useState([
        {
            id: 4,
            type: 'All'
        },
        {
            id: 1,
            type: 'Funtastic'
        },
        {
            id: 2,
            type: 'Fast'
        },
        {
            id: 3,
            type: 'Bakemans'
        }
    ]);

    const reportTypeDdl = () => {
        let options = [];
        if (reportList?.length > 0) {
            reportList.forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.type,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const componentRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        const d = new Date();
        const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
        const previousDay = new Date(d);
        previousDay.setDate(d.getDate() - 1);

        const firstD = formatDate(firstDayOfMonth);
        const currentD = formatDate(previousDay);
        // const firstD = "2024-07-01";
        // const currentD = "2024-07-31";


        dispatch(ZoneSalesReport(firstD, currentD, 1));
        dispatch(divisionSalesReport(firstD, currentD, 1));
        dispatch(areaSalesReport(firstD, currentD, 1));
        dispatch(territorySalesReport(firstD, currentD, 1));
    }, [])

    const showReport = () => {
        dispatch(ZoneSalesReport(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), selectFilterData));
        dispatch(divisionSalesReport(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), selectFilterData));
        dispatch(areaSalesReport(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), selectFilterData));
        dispatch(territorySalesReport(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), selectFilterData));
    }

    const [autoReload, setAutoReload] = useState(false);

    useEffect(() => {
        const autoReloadValue = localStorage.getItem('autoReload');
        if (autoReloadValue) {
            setAutoReload(JSON.parse(autoReloadValue));
        }
    }, []);

    useEffect(() => {
        let reloadTimer;

        if (autoReload) {
            reloadTimer = setTimeout(() => {
                window.location.reload();
            }, 30000);
        }

        return () => {
            clearTimeout(reloadTimer);
        };
    }, [autoReload]);

    const handleToggleAutoReload = () => {
        const newValue = !autoReload;
        setAutoReload(newValue);
        localStorage.setItem('autoReload', JSON.stringify(newValue));
    };

    const needIms = ((TotalTarget / totalDays) * (workingLeft / TotalTarget)) * 100;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const initialStartDate = new Date(currentYear, currentMonth - 1, 1);

    let quarterStartDate = initialStartDate;
    let quarterEndDate = new Date();
    quarterEndDate.setDate(quarterEndDate.getDate() - 1);

    const initialEndDate = quarterEndDate;

    const [startDate, setStartDate] = useState(initialStartDate);
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeNew = (date) => {
        setIsOpen(!isOpen);
        setStartDate(date);
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const [cuDate, setCuDate] = useState(initialEndDate);
    const [istoOpen, setToIsOpen] = useState(false);

    const handleChangeCuDate = (date) => {
        setToIsOpen(!istoOpen);
        setCuDate(date);
    };

    const handleClickCuDate = (e) => {
        e.preventDefault();
        setToIsOpen(!istoOpen);
    };

    useEffect(() => {
        setStartDate(initialStartDate);
        setCuDate(initialEndDate);
    }, []);

    const [show, setShow] = useState(false);
    const [activeModal, setActiveModal] = useState(null);

    const handleClose = () => setActiveModal(null);

    const handleButtonClick = (modalName) => {
        setActiveModal(modalName);
    };

    const Button = ({ onClick, text, icon, background = "#FA8072" }) => (
        <button
            type="button"
            className="btn btn-responsive"
            onClick={onClick}
            style={{
                background: background,
                color: "white",
                padding: "6px 7px",
                borderRadius: "5px",
                margin: "5px",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "fit-content",
            }}
        >
            <span style={{ display: "flex", alignItems: "center" }}>
                {icon} <span style={{ marginLeft: "5px" }}>{text}</span>
            </span>
        </button>
    );

    const ExcelButton = () => (
        <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelBtn ml-1"
            table="table-to-xls"
            filename={`TARGET VS SECONDARY ACH% (ZONE,DIVISION,REGION,TERRITORY) - From : (${dateTimeConvertLongStringForDate(startDate)}),  To : (${dateTimeConvertLongStringForDate(cuDate)}), Report Date : (${new Date().toLocaleDateString()})`}
            sheet="tablexls"
            buttonText={
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        background: "blue",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                    }}
                >
                    <FaFileExcel style={{ marginRight: "1px" }} />
                    XLS
                </span>
            }
        />
    );

    const PrintButton = ({ componentRef }) => (
        <button type="button" className="btn btn-responsive">
            <PrintNew refvalue={componentRef} />
        </button>
    );

    const terrActiveInactive = (territory) => {
        setItemifo(territory);
        setItemDetailShow(true);
        dispatch(TerritorySalesReportActiveInActive(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), 4, territory.id));
    };
    const regionActiveInactive = (region) => {
        setItemifo1(region);
        setItemDetailShow1(true);
        dispatch(RegionSalesReportActiveInActive(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), 4, region.id));
    };
    const divisionActiveInactive = (division) => {
        setItemifo2(division);
        setItemDetailShow2(true);
        dispatch(DivisionSalesReportActiveInActive(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), 4, division.id));
    };
    const zoneActiveInactive = (zone) => {
        console.log('zone', zone)

        setItemifo3(zone);
        setItemDetailShow3(true);
        dispatch(ZoneSalesReportActiveInActive(dateTimeConvertLongStringForDate(startDate), dateTimeConvertLongStringForDate(cuDate), 4, zone.id));
    };


    return (
        <div className='card fullcardbg m-3' style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
        }}>
            <div className="container">
                <div className="row mt-3 justify-content-end">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="btn-group btn-group-sm d-flex flex-wrap" role="group">
                            <Button
                                onClick={() => handleButtonClick("dsm")}
                                text="TOP 5 DSM"
                                icon={<FaTable />}
                            />
                            <Button
                                onClick={() => handleButtonClick("rm")}
                                text="TOP 5 RM"
                                icon={<FaTable />}
                            />
                            <Button
                                onClick={() => handleButtonClick("tsm")}
                                text="TOP 5 TSM"
                                icon={<FaTable />}
                            />
                            <Button
                                onClick={() => handleButtonClick("b3dsm")}
                                text="BOTTOM 3 DSM"
                                icon={<FaTable />}
                            />
                            <Button
                                onClick={() => handleButtonClick("b10rm")}
                                text="BOTTOM 10 RM"
                                icon={<FaTable />}
                            />
                            <Button
                                onClick={() => handleButtonClick("b20tsm")}
                                text="BOTTOM 20 TSM"
                                icon={<FaTable />}
                            />

                            <Button
                                onClick={handleToggleAutoReload}
                                text={autoReload ? "STOP AUTO RELOAD" : "START AUTO RELOAD"}
                                icon={<FaClock />}
                                background={autoReload ? "#ff9999" : "#8d9bed"}
                            />
                            <ExcelButton />

                            <PrintButton componentRef={componentRef} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group row pl-4">
                <div className="form-group row pl-4">
                    <div className="col-lg-3">
                        <label className="form-label mARBOTTOMREMOVE">From Date</label>
                        <button className="example-custom-input" onClick={handleClick}>
                            {format(startDate, 'dd-MM-yyyy')}{' '}
                            <i className="mt-1 fa fa-calendar calendardesign" aria-hidden="true"></i>
                        </button>
                        {isOpen && (
                            <DatePicker
                                selected={startDate}
                                inline
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="datepickerstyle"
                                onChange={handleChangeNew}
                            />
                        )}
                    </div>

                    <div className="col-lg-3">
                        <label className="form-label mARBOTTOMREMOVE">To Date</label>
                        <button className="example-custom-input" onClick={handleClickCuDate}>
                            {format(cuDate, 'dd-MM-yyyy')}{' '}
                            <i className="mt-1 fa fa-calendar calendardesign" aria-hidden="true"></i>
                        </button>
                        {istoOpen && (
                            <DatePicker
                                selected={cuDate}
                                inline
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="datepickerstyle"
                                onChange={handleChangeCuDate}
                            />
                        )}
                    </div>

                    <div className="col-lg-3 paddingRemove">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Filter By</label>
                            <RHFInput
                                className="formSelect pt-0"
                                as={<Select options={reportTypeDdl()} />}
                                rules={{ required: false }}
                                name="typeData"
                                register={register}
                                value={{ value: '1', label: 'Funtastic' }}
                                onChange={(option) => {
                                    setSelectFilterData(option?.value)

                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3 mt-6 mb-5">
                        <button
                            size="sm"
                            onClick={() => showReport()}
                            className="btn btn-success"
                            style={{
                                height: "30px",
                                padding: "0 20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <FaSearch style={{ marginRight: '5px' }} />
                            Show Report
                        </button>
                    </div>


                </div>

            </div>

            <div className="row" ref={componentRef}>

                <div className='col-lg-12'>
                    {reportDataLoading && <SpinnerLoading name="Zone Report" />}
                    {divisionReportsLoading && <SpinnerLoading name="Division Report" />}
                    {areaReportsLoading && <SpinnerLoading name="Region Report" />}
                    {territoryReportsLoading && <SpinnerLoading name="Territory Report" />}


                    <div className="table-responsive pl-5 pr-5 pb-5 " ref={componentRef}>
                        <table id="table-to-xls" className="printtablenew1">

                            {reportData?.length > 0 &&
                                <thead >
                                    <tr className="backcolor">
                                        <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase' }}>TARGET vs SECONDARY ACH% (ZONE,DIVISION,REGION,TERRITORY)</th>
                                    </tr>
                                    <tr >
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS:&nbsp;&nbsp;{totalDays}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS PASS:&nbsp;&nbsp;{workingLeft}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>NEED IMS:&nbsp;&nbsp;{parseFloat(needIms).toFixed(2)}% </th>
                                    </tr>
                                    <tr className="backcolor">
                                        <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>ZONE WISE DATA</th>
                                    </tr>
                                    <tr>
                                        <th className="text-center">SL</th>
                                        <th>Zone</th>
                                        <th>NSM Name</th>
                                        <th className="text-center">Total IMS Target</th>
                                        <th className="text-center">Previous Month IMS</th>
                                        <th className="text-center">Current Month IMS</th>
                                        <th className="text-center">IMS Ach%</th>
                                        <th className="text-center">IMS Landing %</th>
                                        <th className="text-center">SO QTY</th>
                                        <th className="text-center">IMS Growth%</th>
                                        <th className="text-center">Average Per So Per Day IMS</th>
                                        <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                <>
                                    {reportData?.length > 0 &&
                                        reportData?.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{index++ + 1}</td>
                                                    <td>{item.zone_name}</td>
                                                    <td>{item.nsmName}({item.nsmID})</td>
                                                    <td>{numberFormatDecemal(isNaN(parseFloat(item.target)) ? '0.00' : parseFloat(item.target).toFixed(2))}</td>
                                                    <td>{numberFormatDecemal((parseFloat(item.beforeMonth).toFixed(2)))}</td>
                                                    <td>{numberFormatDecemal((parseFloat(item.sales).toFixed(2)))}</td>
                                                    <td className="text-center">
                                                        <span style={{
                                                            color: ((item.performance)) >= (needIms) ?
                                                                (parseFloat(item.performance).toFixed(2) === '0.00' ? '#f1420d' : '#008000') :
                                                                '#f1420d'
                                                        }}>
                                                            {(parseFloat(item.performance).toFixed(2))}%
                                                        </span>
                                                    </td>

                                                    <td className="text-center" >{(parseFloat((item.performance * totalDays) / workingLeft).toFixed(2))}%</td>
                                                    <td className="text-center" onClick={() => zoneActiveInactive(item)}>{((item.soQty))}</td>
                                                    <td className="text-center">
                                                        <span style={{
                                                            color: parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) > 0 ?
                                                                ((parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2)) === '0.00' ? '#f1420d' : '#008000') :
                                                                '#f1420d'
                                                        }}>
                                                            {(parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2))}%
                                                        </span>
                                                    </td>

                                                    <td className="text-center">{numberFormatDecemal(((parseFloat((item.sales / item.soQty) / workingLeft)).toFixed(2)))}</td>
                                                    <td className="text-center">{((item.rank))}</td>

                                                </tr>
                                            );
                                        })}

                                    {divisionReports?.length > 0 && <>
                                        <tr className="backcolor1">
                                            <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>DIVISION WISE DATA</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th>Division</th>
                                            <th>DSM Name</th>
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">Previous Month IMS</th>
                                            <th className="text-center">Current Month IMS</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                        </tr></>}
                                    {divisionReports?.length > 0 &&
                                        divisionReports?.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{index++ + 1}</td>
                                                    <td>{item.zone_name}</td>
                                                    <td>{item.nsmName}({item.nsmID})</td>
                                                    <td>{numberFormatDecemal(isNaN(parseFloat(item.target)) ? '0.00' : parseFloat(item.target).toFixed(2))}</td>
                                                    <td>{numberFormatDecemal((parseFloat(item.beforeMonth).toFixed(2)))}</td>
                                                    <td>{numberFormatDecemal((parseFloat(item.sales).toFixed(2)))}</td>
                                                    <td className="text-center">
                                                        <span style={{
                                                            color: ((item.performance)) >= (needIms) ?
                                                                (parseFloat(item.performance).toFixed(2) === '0.00' ? '#f1420d' : '#008000') :
                                                                '#f1420d'
                                                        }}>
                                                            {(parseFloat(item.performance).toFixed(2))}%
                                                        </span>
                                                    </td>
                                                    <td className="text-center">{(parseFloat((item.performance * totalDays) / workingLeft).toFixed(2))}%</td>
                                                    <td className="text-center" onClick={() => divisionActiveInactive(item)}>{((item.soQty))}</td>
                                                    <td className="text-center">
                                                        <span style={{
                                                            color: parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) > 0 ?
                                                                ((parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2)) === '0.00' ? '#f1420d' : '#008000') :
                                                                '#f1420d'
                                                        }}>
                                                            {(parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2))}%
                                                        </span>
                                                    </td>
                                                    <td className="text-center">{numberFormatDecemal(((parseFloat((item.sales / item.soQty) / workingLeft)).toFixed(2)))}</td>
                                                    <td className="text-center">{((item.rank))}</td>
                                                </tr>
                                            );
                                        })}

                                    {areaReports?.length > 0 && <>
                                        <tr className="backcolor1">
                                            <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}> REGION WISE DATA</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th>Region</th>
                                            <th>RM Name</th>
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">Previous Month IMS</th>
                                            <th className="text-center">Current Month IMS</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                        </tr></>}
                                </>
                                {areaReports?.length > 0 &&
                                    areaReports?.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{index++ + 1}</td>
                                                <td>{item.zone_name}</td>
                                                <td>{item.nsmName}({item.nsmID})</td>
                                                <td>{numberFormatDecemal((parseFloat(item.target).toFixed(2)))}</td>
                                                <td>{numberFormatDecemal((parseFloat(item.beforeMonth).toFixed(2)))}</td>
                                                <td>{numberFormatDecemal((parseFloat(item.sales).toFixed(2)))}</td>
                                                <td className="text-center">
                                                    <span style={{
                                                        color: ((item.performance)) >= (needIms) ?
                                                            (parseFloat(item.performance).toFixed(2) === '0.00' ? '#f1420d' : '#008000') :
                                                            '#f1420d'
                                                    }}>
                                                        {(parseFloat(item.performance).toFixed(2))}%
                                                    </span>
                                                </td>
                                                <td className="text-center">{(parseFloat((item.performance * totalDays) / workingLeft).toFixed(2))}%</td>
                                                <td className="text-center" onClick={() => regionActiveInactive(item)}>{((item.soQty))}</td>
                                                <td className="text-center">
                                                    <span style={{
                                                        color: parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) > 0 ?
                                                            ((parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2)) === '0.00' ? '#f1420d' : '#008000') :
                                                            '#f1420d'
                                                    }}>
                                                        {(parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2))}%
                                                    </span>
                                                </td>
                                                <td className="text-center">{numberFormatDecemal(((parseFloat((item.sales / item.soQty) / workingLeft)).toFixed(2)))}</td>
                                                <td className="text-center">{((item.rank))}</td>
                                            </tr>
                                        );
                                    })}

                                {territoryReports?.length > 0 &&
                                    < >
                                        <tr className="backcolor3">
                                            <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>TERRITORY WISE DATA</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th>Territory</th>
                                            <th>TSM Name</th>
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">Previous Month IMS</th>
                                            <th className="text-center">Current Month IMS</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                        </tr>
                                    </>
                                }
                                {territoryReports?.length > 0 &&
                                    territoryReports?.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{index++ + 1}</td>
                                                <td>{item.zone_name}</td>
                                                <td>{item.nsmName}({item.nsmID})</td>
                                                <td>{numberFormatDecemal((parseFloat(item.target).toFixed(2)))}</td>
                                                <td>{numberFormatDecemal((parseFloat(item.beforeMonth).toFixed(2)))}</td>
                                                <td>{numberFormatDecemal((parseFloat(item.sales).toFixed(2)))}</td>
                                                <td className="text-center">
                                                    <span style={{
                                                        color: ((item.performance)) >= (needIms) ?
                                                            ((item.performance) === '0.00' ? '#f1420d' : '#008000') :
                                                            '#f1420d'
                                                    }}>
                                                        {parseFloat((item.performance)).toFixed(2)}%
                                                    </span>
                                                </td>
                                                <td className="text-center">{(parseFloat((item.performance * totalDays) / workingLeft).toFixed(2))}%</td>
                                                <td className="text-center" onClick={() => terrActiveInactive(item)}>{((item.soQty))}</td>
                                                <td className="text-center">
                                                    <span style={{
                                                        color: parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2) > 0 ?
                                                            ((parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2)) === '0.00' ? '#f1420d' : '#008000') :
                                                            '#f1420d'
                                                    }}>
                                                        {(parseFloat(((item.sales / item.beforeMonth) - 1) * 100).toFixed(2))}%
                                                    </span>
                                                </td>
                                                <td className="text-center">{numberFormatDecemal(((parseFloat((item.sales / item.soQty) / workingLeft)).toFixed(2)))}</td>
                                                <td className="text-center">{((item.rank))}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {activeModal === 'dsm' && (
                <SimpleModal
                    size="xl"
                    show={true}
                    handleClose={handleClose}
                >
                    <TopFiveDSM />
                </SimpleModal>
            )}

            {activeModal === 'rm' && (
                <SimpleModal
                    size="xl"
                    show={true}
                    handleClose={handleClose}
                >
                    <TopFiveRM />
                </SimpleModal>
            )}
            {activeModal === 'tsm' && (
                <SimpleModal
                    size="xl"
                    show={true}
                    handleClose={handleClose}
                >
                    <TopFiveTSM />
                </SimpleModal>
            )}
            {activeModal === 'b20tsm' && (
                <SimpleModal
                    size="xl"
                    show={true}
                    handleClose={handleClose}
                >
                    <Bottom20TSM />
                </SimpleModal>
            )}
            {activeModal === 'b10rm' && (
                <SimpleModal
                    size="xl"
                    show={true}
                    handleClose={handleClose}
                >
                    <Bottom10RM />
                </SimpleModal>
            )}
            {activeModal === 'b3dsm' && (
                <SimpleModal
                    size="xl"
                    show={true}
                    handleClose={handleClose}
                >
                    <Bottom3DSM />
                </SimpleModal>
            )}


            <SimpleModal
                size="xl"
                show={itemDetailShow}
                handleClose={() => setItemDetailShow(false)}
                handleShow={() => setItemDetailShow(true)}
            >
                <SOQtyTerritoryActiveInactiveView
                    handleClose={() => setItemDetailShow(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={itemDetailShow1}
                handleClose={() => setItemDetailShow1(false)}
                handleShow={() => setItemDetailShow1(true)}
            >
                <SOQtyRegionActiveInactiveView
                    handleClose={() => setItemDetailShow1(false)}
                    itemDetailnIfo={itemifo1}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={itemDetailShow2}
                handleClose={() => setItemDetailShow2(false)}
                handleShow={() => setItemDetailShow2(true)}
            >
                <SOQtyDivisionActiveInactiveView
                    handleClose={() => setItemDetailShow2(false)}
                    itemDetailnIfo={itemifo2}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={itemDetailShow3}
                handleClose={() => setItemDetailShow3(false)}
                handleShow={() => setItemDetailShow3(true)}
            >
                <SOQtyZoneActiveInactiveView
                    handleClose={() => setItemDetailShow3(false)}
                    itemDetailnIfo={itemifo3}
                />
            </SimpleModal>

        </div>
    )
}

export default TarVSacvZDATwise

