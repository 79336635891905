import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getAreaListByDivisionEntry,
  getDivisionListByZone,
  getDivisionListByZoneEntry,
  getTerritory,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
  getSOSkuProduct
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

import * as XLSX from "xlsx/xlsx.mjs";
import { getSecondarysoProductForExcel } from "../../_redux/actions/Delivery/DeliveryAction";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const SecondarySalesSoProduct = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const productReport = useSelector((state) => state.secondarySales.soWiseProduct);
  console.log('21 productReport :>> ', productReport);
  const skuTotal = useSelector((state) => state.secondarySales.skuTotal);
  const skuQuantity = useSelector((state) => state.secondarySales.skuQuantity);
  const productTotal = useSelector((state) => state.secondarySales.productTotal);
  const productQuantity = useSelector((state) => state.secondarySales.productQuantity);
  const productPrice = useSelector((state) => state.secondarySales.productPrice);
  const amount = useSelector((state) => state.secondarySales);
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );


  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const componentRef = useRef();

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var d = new Date();
    var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";


    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, salesType } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }


  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);



  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);




  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    dispatch(getSOSkuProduct(fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectGeoType));
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }

  const hanleExcelClick = async () => {
    let excelClick = await getSecondarysoProductForExcel(
      startDate,
      toDate,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory,
      3
    );
    console.log('excelClick', excelClick);
    const current = currentdate();

    // const worksheet = XLSX.utils.json_to_sheet(excelClick.data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


    // XLSX.writeFile(workbook, "DataSheet.xlsx");


    let Heading = [['ZoneId', 'Zone Name', 'Divisionid', 'DivisionName',
      'RegionID', 'RegionName', 'TerritoryId', 'TerritoryName', 'SoPointid',
      'SoPointName', 'SoEnroll', 'SoName', 'ProductId', 'ProductName', 'salesGroupName', 'BrandName', 'PackTypeName', 'ProductCategory', 'Uom', 'Price', 'Qty', 'CtnQty', 'Value'
    ]];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    XLSX.writeFile(wb, 'So SKU Wise Sku Report' + " " + current + '.xlsx');
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <span className="button-010">So SKU Wise Sku Report</span>
              {/* <h3 className="mb-0 pb-0">So SKU Wise Sku Report  </h3> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              <Button size="sm" onClick={() => hanleExcelClick()} >Download</Button>
              <DownloadDropdown excelname='So SKU Wise Sku Report' />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Zone</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={zoneDropdownData()}
                  value={defultZone}
                  onChange={(option) => {
                    divisionList(option?.value);
                    setSelectZone(option?.value);
                    setDefaultZone(option);
                    setdefultDivision(null);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("divisionData", "");
                    setValue("areaData", "");
                    setValue("territory", "");
                    
                  }}
                  defaultValue={defultZone}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Division</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={divisionDropdownData()}
                  value={defultDivision}
                  onChange={(option) => {
                    getAreaData(option?.value);
                    setSelectDivision(option?.value);
                    setdefultDivision(option);
                    setdefaultArea(null);
                    setdefultTerritory(null);
                    setValue("areaData", "");
                    setValue("territory", "");
                  }}
                  defaultValue={defultDivision}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-0">
              <label className="form-label ml-4">Select Region</label>
              <Form.Group as={Col} controlId="formGridState">
                <Select
                  options={areaDropdownData()}
                  value={defaultArea}
                  onChange={(option) => {
                    getTerritoryData(option?.value);
                    setSelectArea(option?.value);
                    setdefaultArea(option);
                    setdefultTerritory(null);
                    setValue("territory", "");
                  }}
                  defaultValue={defaultArea}
                />
              </Form.Group>
            </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>

          <div className="col-md-3 mt-5 mb-5">
            <button className="button-621" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType)} >  Show Report</button>
            {/* <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType)}>Show Report</Button> */}
          </div>

        </div>
        <div>
          {productReport?.length > 0 &&

            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">

                <div className="col-xl-6 col-lg-4 col-md-6">
                  <div className="paid__widget two">
                    <div className="widget__left">


                      <p className="balanceleft">Total Quantity</p>
                    </div>
                    <div className="widget__right">

                      <div className="overlay-layer bg-transparent">
                      </div>
                      <div>
                        <h4 className="balanceleft">{parseInt(skuQuantity)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left" >
                      <p className="balanceleft">Total Value</p>
                    </div>
                    <div className="widget__right">
                      <div>
                        <h4 className="balanceleft">৳ {parseFloat(skuTotal).toFixed(2)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          }
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">

              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  {/* <table className="table table-head-custom table-vertical-center   item-add-table" id="table-to-xls" ref={componentRef}> */}
                  <thead className="bg-white" >

                    <tr>
                      <th scope="col">ZONE</th>
                      <th scope="col">DIVISION</th>
                      {
                        selectGeoType == 1 ?
                          <>
                            <th scope="col">AREA</th>
                            <th scope="col"></th>
                          </>
                          :
                          <>
                            <th scope="col">REGION</th>
                            <th scope="col">TERRITORY</th>
                          </>
                      }
                      <th scope="col">SO ID</th>
                      <th scope="col">SO NAME</th>
                      <th scope="col">SO POINT</th>
                      <th scope="col">PRODUCT ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">GROUP NAME</th>
                      <th scope="col">BRAND NAME</th>
                      <th scope="col">PACK NAME</th>
                      <th scope="col">CATEGORY</th>
                      <th scope="col">PRODUCT PRICE</th>
                      <th scope="col">PRODUCT QUANTITY(PCS)</th>
                      <th scope="col">PRODUCT QUANTITY(QTY)</th>
                      <th scope="col">Total Value</th>

                    </tr>
                  </thead>
                  <tbody>
                    {productReport?.length > 0 &&
                      productReport?.map((item, index) => (
                        <>
                          <tr className="alert bg-secondary">
                            {/* <td>{item.orderId}</td> */}
                            <td>{item.zoneName}</td>
                            <td>{item.divisionName}</td>

                            {
                              selectGeoType == 1 ?
                                <>
                                  <td>{item.areaName}</td>
                                  <td></td>

                                </> :

                                <>
                                  <td>{item.areaName}</td>
                                  <td>{item.territoryName}</td>
                                </>

                            }

                            <td>{item.soEnroll}</td>
                            <td>{item.soName}</td>
                            <td>{item.soPointName}</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td>{item.totalQuentity}</td>
                            <td>{Math.round(item.totalQuentityCtn)}</td>
                            <td>{Math.round(item.totalPrice)}</td>
                          </tr>
                          {
                            item?.itemReport.map((product, subindex) => (
                              <tr>
                                <td>{item.zoneName}</td>
                                <td>{item.divisionName}</td>
                                {
                                  selectGeoType == 1 ?
                                    <>
                                      <td>{item.areaName}</td>
                                      <td></td>

                                    </> :

                                    <>
                                      <td>{item.areaName}</td>
                                      <td>{item.territoryName}</td>
                                    </>

                                }
                                <td>{item.soEnroll}</td>
                                <td>{item.soName}</td>
                                <td>{item.soPointName}</td>
                                <td>{product.productID}</td>
                                <td>{product.productName}</td>
                                <td>{product.sGroup}</td>
                                <td>{product.brandName}</td>
                                <td>{product.packName}</td>
                                <td>{product.productCategory}</td>
                                <td>{parseFloat(product.productPicPrice).toFixed(2)}</td>
                                <td>{product.productQty}</td>
                                <td>{Math.round(product.productQtyCtn)}</td>
                                <td>{parseFloat(product.productTPrice).toFixed(2)}</td>
                              </tr>
                            ))
                          }
                        </>
                      ))}


                  </tbody>
                </table>
              </div>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>



        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>
      </div>
    </>
  );
};

export default SecondarySalesSoProduct;
