import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import 'react-pivottable/pivottable.css';



import {
  cleanRemoteSalesData,
  EditDateGet,
  getSalesList,
  salesDelete,
  showApprovedReport,
} from "../../../_redux/actions/RemoteSales/RemoteSalesAction";
import {
  InputHandle,
} from "../../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../../_redux/actions/RemoteSales/DistributorReportAction";
import SecondarySalesPivot from "./SecondarySalesPivot";
import { showSecondaryApprovedReportShow } from "../../../_redux/actions/SecondarySales/SecondarySalesAction";
import DownloadDropdown from "../../../Master/DownloadDropdown";
import { dateTimeConvertLongString } from "../../../../../../domains/CCO/utils/DateHelper";
import { allCheckBoxSelect, checkboxStatusChange, showRollbackProduct,salesRoleback } from "../../../_redux/actions/SecondarySales/SecondaryRollbackAction";
import { Checkbox } from "../../../../../../_metronic/_partials/controls";


// var ReactPivot = require('react-pivot')





const SecondarySalesApprovedDelate = () => {
  const history = useHistory();

  const isLoading = useSelector((state) => state.itemList.approvedLoading);
  const [currentPage, setCurrentPage] = useState(1);
  // const salesApprovedList = useSelector((state) => state.secondarySales.secondaryApprovedList);
  const salesApprovedList = useSelector((state) => state.rollback.rollbacklist);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [columnData, setColumnData] = useState([]);
  const allCheckbox = useSelector((state) => state.rollback.allCheckbox);



  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  

  useEffect(() => {
    dispatch(cleanRemoteSalesData());
  }, [])
  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    initialData();
  }, [startDate,toDate]);

  const initialData = async () => {
    let zoneData = await getZoneList();
    
    // setToDate(curDateFormate);
    // setStartDate(curDate);
    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  

 





  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.distID);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage =(item)=>{
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);

  }
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleChange = ({ currentTarget: input }) => {

    setStartDate(input.value);
  };
  const showReport =()=>{
   
    dispatch(showRollbackProduct(startDate,toDate,selectArea,selectTerritory));
  }
  // delete issuing authority list
 
  const handleRollback =(salesId)=>{
    confirmAlert({
        title: "Confirm To RoleBack",
        message: `Are you sure to RoleBack..?`,
        buttons: [
          {
            label: "Yes",
            onClick: () =>  dispatch(salesRoleback(salesApprovedList,startDate,toDate,selectArea,selectTerritory)),
          },
          {
            label: "No",
          },
        ],
      });
  }
  const handleCheckBox = (item, index) => {
    dispatch(checkboxStatusChange(item, index));
}
const handleAllCheckBox = (e) => {
    dispatch(allCheckBoxSelect());
}
    

const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



 

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border">
      <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Sales RollBack</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='Sales RollBack' />
          </div>

        </div>
        <hr></hr>
    
        <div className="form-group row">
        <div className="col-lg-3">
          <Form.Group as={Col} controlId="formGridState">
            <label className="form-label"> From Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Deadweight"
              name="fromDate"
              className="fromStyle"
              onChange={handleChange}
              value={startDate}
            />
            </Form.Group>
            
          </div>
          <div className="col-lg-3">
          <Form.Group as={Col} controlId="formGridState">
            <label className="form-label"> To Date</label>

            <Form.Control
              type="date"
              placeholder="Enter Todate"
              name="todate"
              value={toDate}
              className="fromStyle"
              onChange={handleChangeTodate}
            />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                onChange={(option) => {
                  divisionList(option.value);
                  setSelectZone(option.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option.value);
                  setSelectDivision(option.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                onChange={(option) => {
                  getTerritoryData(option.value)
                  setSelectArea(option.value);
                  
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                   
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>

        <div className="form-group row">
        <div className="col mt-4 ml-4">
            <Button size="sm" onClick={()=>showReport()}>Show Report</Button>
            <Button size="sm" onClick={()=>handleRollback()} className="ml-4">Roll Back</Button>
          </div>
        </div>

        </div>

       

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <table className="table table-head-custom table-vertical-center  item-add-table">
              <thead>
                <tr>
                  <th scope="col"> <Checkbox isSelected={allCheckbox} onChange={(e) => handleAllCheckBox(e)} /></th>
                  <th scope="col">SALES NO</th>
                  <th scope="col">Distribution ID</th>
                  <th scope="col">DISTRIBUTION CODE</th>
                   <th scope="col">DISTRIBUTOR NAME</th>
                   <th scope="col">SALES DATE</th>
                   <th scope="col">APPROVED DATE</th>
                  <th scope="col">QUANTITY</th>
                  <th scope="col">PRICE</th>
                  <th scope="col">ACTION</th>  
                </tr>
              </thead>
              <tbody>
                {salesApprovedList !==null &&
                  salesApprovedList?.map((item, index) => (
                    <tr>
                      <td> <Checkbox isSelected={item.isPresent} onChange={() => handleCheckBox(item, index)} /></td>
                      <td>{item.salesNo}</td>
                      <td>{item.distID}</td>
                      <td>{item.distribCode}</td>
                      <td>{item.distribName}</td>
                      <td>{dateTimeConvertLongString(item.ssDate)}</td>
                      <td>{item.apporveDate}</td>
                      <td>{item.quentity}</td>
                      <td>{item.productPrice}</td>
                      <td>
                       
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon d-inline-block"></i>
                        </Link>  
                        {/* <Link onClick={() =>  handleRollback(item.salesID) } title="Roll Back">
                          <i className="fas fa-undo editIcon item-list-icon d-inline-block"></i>
                        </Link>  */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            
         
            {!isLoading && salesApprovedList == null && (
              <div className="alert alert-warning mt-5">
                Sorry ! Approved List Not Found in this Date.
              </div>
            )}
            
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SecondarySalesPivot
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
            toDate={toDate}
          />
        </SimpleModal>

        
      </div>
    </>
  );
};

export default SecondarySalesApprovedDelate;