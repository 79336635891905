/*******************************************************************************************************
        Module: Certificate
********************************************************************************************************/

export const CHANGE_CERTIFICATE_ISSUE_AUTHORITY_INPUT =
  "CHANGE_CERTIFICATE_ISSUE_AUTHORITY_INPUT";

export const GET_ISSUING_AUTHORITY_LIST = "GET_ISSUING_AUTHORITY_LIST";
export const POST_ISSUING_AUTHORITY = "POST_ISSUING_AUTHORITY";
export const SET_ISSUING_AUTHORITY_EDIT_DATA =
  "SET_ISSUING_AUTHORITY_EDIT_DATA";
export const EDIT_ISSUING_AUTHORITY = "EDIT_ISSUING_AUTHORITY";
export const DELETE_ISSUING_AUTHORITY = "DELETE_ISSUING_AUTHORITY";
