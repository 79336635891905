import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetIMSMenuTrackingList } from '../../../information/_redux/actions/Report/ReportAction';
import { format } from 'date-fns';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { Form, Button, Col } from 'react-bootstrap';
import { FaMap, FaSearch, FaTable } from 'react-icons/fa';
import DotLoading from '../../../../master/spinner/DotLoading';
import { formatDate } from '../../../../master/PrintDwopdown/DateHelper';

// To update map center/zoom after rendering all markers
const UpdateMapView = ({ locations }) => {
  const map = useMap();
  useEffect(() => {
    if (locations.length > 0) {
      const bounds = L.latLngBounds(locations);
      map.fitBounds(bounds); // Automatically adjust the map bounds to fit all markers
    }
  }, [locations, map]);
  return null;
};

const IMSMenuTrackingList = () => {
  const dispatch = useDispatch();

  // Get menuTrackList and loading state from Redux
  const menuTrackList = useSelector((state) => state.target.menuTrackList);
  const menuTrackLoading = useSelector((state) => state.target.menuTrackLoading);
  const [empEnroll, setEmpEnroll] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [view, setView] = useState('map');

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setStartDate(currentDate);
  }, []);

  // Dispatch GetIMSMenuTrackingList action to fetch data
  useEffect(() => {
    const d = new Date();
    const currentDay = new Date(d);
    const currentD = formatDate(currentDay);

    dispatch(GetIMSMenuTrackingList(currentD, empEnroll));
  }, [dispatch]);

  // Ensure menuTrackList is always an array, even if it's undefined or null
  const safeMenuTrackList = Array.isArray(menuTrackList) ? menuTrackList : [];

  // Extract all latitude and longitude for rendering on the map
  const locations = safeMenuTrackList.map((item) => [
    parseFloat(item.latitude),
    parseFloat(item.longitude),
  ]);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const showReport = () => {
    dispatch(GetIMSMenuTrackingList(startDate, empEnroll));
  };

  // Default Leaflet Icon
  const defaultIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png', // Use Leaflet's default icon URL
    iconSize: [20, 31], // Size of the marker
    iconAnchor: [12, 31], // Anchor point of the icon
    popupAnchor: [0, -31], // Popup anchor point relative to marker
  });

  return (
    <div className="card fullcardbg m-3" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/bg/flower.avif')})` }}>
      <div className="p-3 single-sms">
        <div className="row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridDate">
              <label className="form-label">Date : </label>
              <Form.Control
                type="date"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>

          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridEmployeeEnroll">
              <label className="form-label">Enroll : </label>
              <Form.Control
                type="number"
                placeholder="Enter Employee Enroll"
                name="empEnroll"
                value={empEnroll}
                style={{
                  height: '31px',
                }}
                onChange={(e) => setEmpEnroll(e.target.value)}
              />
            </Form.Group>
          </div>

          <div className="col-md-2">
            <Button
              size="sm"
              variant="primary"
              onClick={() => showReport()}
              className="btn-sm btn mt-6"
              style={{
                height: '31px',
              }}
            >
              <FaSearch /> Show Report
            </Button>
          </div>
        </div>
      </div>

      {/* View Toggle */}
      <div className="view-toggle-buttons text-center mb-2 mt-2">
        <Button
          size="sm"
          variant={view === 'map' ? 'primary' : 'success'}
          onClick={() => setView('map')}
          className="mx-2"
        >
          <FaMap className="mr-2" /> Map View
        </Button>
        <Button
          size="sm"
          variant={view === 'data' ? 'primary' : 'success'}
          onClick={() => setView('data')}
          className="mx-2"
        >
          <FaTable className="mr-2" /> Data View
        </Button>
      </div>

      <div className="row">
        <div className="col-lg-12">
          {view === 'map' && (
            <div style={{ height: '500px', width: '100%' }}>
              {menuTrackLoading ? (
                <DotLoading />
              ) : safeMenuTrackList.length > 0 ? (
                <MapContainer
                  center={[23.685, 90.356]} // Center map on Bangladesh (default)
                  zoom={1} // Zoom level
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <UpdateMapView locations={locations} />
                  {safeMenuTrackList.map((item, index) => (
                    <Marker
                      key={index}
                      position={[parseFloat(item.latitude), parseFloat(item.longitude)]}
                      icon={defaultIcon} // Use the default Leaflet icon
                    >
                      <Popup>
                        <div>
                          <strong>Date:</strong> {format(new Date(item.trackingTime), 'dd-MM-yyyy hh:mm:ss a , EEEE')} <br />
                          <strong>Employee:</strong> {item.employee} <br />
                          <strong>Menu:</strong> {item.menuName} <br />
                          <strong>Address:</strong> {item.address}
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                </MapContainer>
              ) : (
                <div className="no-data-message text-center">No data available.</div>
              )}
            </div>
          )}

          {view === 'data' && (
            <div className="table-responsive pl-5 pr-5 pb-5">
              {menuTrackLoading ? (
                <DotLoading />
              ) : (
                <table id="table-to-xls" className="printtablenew1">
                  <thead>
                    <tr className="backcolor">
                      <th className="text-center">SL</th>
                      <th className="text-center">Tracking Time</th>
                      <th className="text-center">Employee</th>
                      <th className="text-center">Latitude</th>
                      <th className="text-center">Longitude</th>
                      <th className="text-center">Address</th>
                      <th className="text-center">Menu Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {safeMenuTrackList.length > 0 ? (
                      safeMenuTrackList.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{format(new Date(item.trackingTime), 'dd-MM-yyyy hh:mm:ss a , EEEE')}</td>
                          <td className="text-center">{item.employee}</td>
                          <td className="text-center">{item.latitude}</td>
                          <td className="text-center">{item.longitude}</td>
                          <td className="text-center">{item.address}</td>
                          <td className="text-center">{item.menuName}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IMSMenuTrackingList;
