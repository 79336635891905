import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

import * as XLSX from "xlsx/xlsx.mjs";
import { getSecondarysoProductForExcel } from "../../_redux/actions/Delivery/DeliveryAction";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import { DamageSummeryReportCatWiseForY, SattlementDamageSummeryReportCatWiseForY } from "../../_redux/actions/RemoteSales/DamageAction";

const SattlementDamageSumCatWiseForY = () => {
  const damageData = useSelector((state) => state.damage.dSummeryDataCatWise);
  const damageSummaryLoading = useSelector((state) => state.damage.dSummaryCatWiseLoading);
  console.log('damageData', damageData);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const [selectCategory, setSelectCategory] = useState(null);
  const [selectCategoryLable, setSelectCategoryLable] = useState(null);
  const componentRef = useRef();

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    // setSalesType(user.employeeType);
    // setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {

    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };



  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };






  let ref = React.createRef();
  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };
  console.log('selectCategory :>> ', selectCategory);
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory,selectCategory) => {
    console.log('selectCategory Submit:>> ', selectCategory);

    dispatch(SattlementDamageSummeryReportCatWiseForY(fromDate, tDate, idZone, idDivision, idArea, idTerritory,selectCategory));
  }

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    // setSalesType(value);
    setSelectGeoType(value);
  }

  

  const catdata = [
    { value: "0", label: "All" },
    { value: "1", label: "Cake" },
    { value: "2", label: "Biscuit" },
];

  
  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
            <h4 className="">Sattlement Damage Summery Report(Category Wise)-Y</h4>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              {/* <Button size="sm" onClick={() => hanleExcelClick()} >Download</Button> */}
              <DownloadDropdown excelname='Sattlement Damage Summery Report(Category Wise)-Y' refvalue={componentRef} />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Category</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={catdata} />}
                rules={{ required: false }}
                name="category"
                register={register}
                value=""
                onChange={(option) => {
                  setSelectCategory(option?.value);
                  setSelectCategoryLable(option?.label);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3 mt-5 mb-5">
          <button className="button-621" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectCategory)} >Show Report</button>
            {/* <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectCategory)}>Show Report</Button> */}
          </div>

        </div>
        <div>
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {damageSummaryLoading && <LoadingSpinner text="Loading List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              <div
                className="stickyTable"
                style={{ height: damageData?.length > 0 ? 600 + "px" : 0 }}
              >
                {/* <table className="table table-head-custom borderTableims" id="table-to-xls" ref={componentRef}> */}
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead className="bg-light" >

                    <tr>
                      <th scope="col">ZONE</th>
                      <th scope="col">DIVISION</th>
                      <th scope="col">REGION</th>
                      <th scope="col">TERRITORY</th>
                      <th scope="col">DISTRIBUTOR ID</th>
                      <th scope="col">DISTRIBUTOR Name</th>
                      <th scope="col">DISPOINT ID</th>
                      <th scope="col">TOTAL DAMAGE QTY</th>
                      <th scope="col">TOTAL DAMAGE VALUE</th>
                      <th scope="col">TOTAL Lifting QTY</th>
                      <th scope="col">TOTAL Lifting VALUE</th>
                      <th scope="col">Damage % </th>
                      <th scope="col">RAT CUT QTY</th>
                      <th scope="col">RAT CUT VALUE</th>
                      <th scope="col">RAT CUT %</th>
                      <th scope="col">BROKEN QTY</th>
                      <th scope="col">BROKEN VALUE</th>
                      <th scope="col">BROKEN %</th>
                      <th scope="col">Empty QTY</th>
                      <th scope="col">Empty VALUE</th>
                      <th scope="col">Empty %</th>
                      <th scope="col">DATE EXPIRED QTY</th>
                      <th scope="col">DATE EXPIRED VALUE</th>
                      <th scope="col">DATE EXPIRED %</th>
                      <th scope="col">DATE MISSING QTY</th>
                      <th scope="col">DATE MISSING VALUE</th>
                      <th scope="col">DATE MISSING %</th>
                      <th scope="col">QUALITY PROBLEM QTY</th>
                      <th scope="col">QUALITY PROBLEM VALUE</th>
                      <th scope="col">QUALITY PROBLEM %</th>
                      <th scope="col">MARKET DAMAGE QTY</th>
                      <th scope="col">MARKET DAMAGE VALUE</th>
                      <th scope="col">MARKET DAMAGE %</th>
                      <th scope="col">AIR LESS DAMAGE QTY</th>
                      <th scope="col">AIR LESS DAMAGE VALUE</th>
                      <th scope="col">AIR LESS %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      damageData?.length > 0 &&
                      damageData?.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.zoneName}</td>
                            <td>{item.divisionName}</td>
                            <td>{item.areaName}</td>
                            <td>{item.territoryName}</td>
                            <td>{item.distribID}</td>
                            <td>{item.distribName}</td>
                            <td>{item.disPointID}</td>
                            {/* <td>{item.dispointName}</td> */}
                            <td>{item.totalQty}</td>
                            <td>{item.totalValue}</td>
                            <td>{item.totalLiftingQty}</td>
                            <td>{item.totalLifting}</td>
                            <td>{parseFloat((item.totalValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.ratCutQty}</td>
                            <td>{item.ratCutValue}</td>
                            <td>{parseFloat((item.ratCutValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.brokenQty}</td>
                            <td>{item.brokenValue}</td>
                            <td>{parseFloat((item.brokenValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.empptyQtyQty}</td>
                            <td>{item.empptyValue}</td>
                            <td>{parseFloat((item.empptyValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.dateExpiredQty}</td>
                            <td>{item.dateExpiredValue}</td>
                            <td>{parseFloat((item.dateExpiredValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.dateMissingQty}</td>
                            <td>{item.dateMissingValue}</td>
                            <td>{parseFloat((item.dateMissingValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.qualiProblemQty}</td>
                            <td>{item.qualiProbleValue}</td>
                            <td>{parseFloat((item.qualiProbleValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.marketDamageQty}</td>
                            <td>{item.marketDamageValue}</td>
                            <td>{parseFloat((item.marketDamageValue/item.totalLifting)*100).toFixed(2)}</td>
                            <td>{item.airLessQty}</td>
                            <td>{item.airLessValue}</td>
                            <td>{parseFloat((item.airLessValue/item.totalLifting)*100).toFixed(2)}</td>

                          </tr>

                        </>
                      ))}


                  </tbody>
                </table>
              </div>
              {!damageSummaryLoading && damageData.length === 0 && (
                <div className="alert alert-warning mt-5">
                  Sorry ! List Not Found.
                </div>
              )}
            </div>
          </div>

        </div>




      </div>
    </>
  );
};

export default SattlementDamageSumCatWiseForY;
