import React from "react";

const PartnerBasicInformationListContainer = () => {
  return (
    <>
      <h2>Partner List</h2>
      <p>Testing Partner List List Page</p>
    </>
  );
};

export default PartnerBasicInformationListContainer;
