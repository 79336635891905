import React from "react";
import ItemList from "../information/components/ItemList";

const ItemListContainer = () => {
  return (
    <div className="container">

      <ItemList />

    </div>
  );
};

export default ItemListContainer;
