import React from "react";

const SalaryGenerateContainer = () => {
    return (
        <>
            <div>
                <iframe src="https://report.akij.net/reports/report/ASLL/Salary/Salary_Generate_Main?rs:Embed=true" height="800" width="1000" title="Iframe Example"></iframe>
            </div>
        </>
    );
};

export default SalaryGenerateContainer;
