export const CHANGE_PO_APPROVAL_FILTER_INPUT = "CHANGE_PO_APPROVAL_FILTER_INPUT";
export const GET_SHIP_NAME = "GET_SHIP_NAME";
export const GET_SBU_NAME = "GET_SBU_NAME";
export const GET_BRANCH_NAME = "GET_BRANCH_NAME";
export const GET_PO_APPROVAL_LIST = "GET_PO_APPROVAL_LIST";
export const GET_REFERENCE_TYPE = "GET_REFERENCE_TYPE";
export const GET_PURCHASE_ORGANISATION_NAME = "GET_PURCHASE_ORGANISATION_NAME";
export const GET_PO_APPROVAL_DETAIL = "GET_PO_APPROVAL_DETAIL";
export const SUBMIT_PO_APPROVE = "SUBMIT_PO_APPROVE";
export const CHANGE_PO_APPROVAL_DETAIL_INPUT = "CHANGE_PO_APPROVAL_DETAIL_INPUT";

//************Purhase Order Approval ************ */
export const PO_APPROVAL_DETAILS_INPUT = "PO_APPROVAL_DETAILS_INPUT";
export const UPDATE_PO_APPROVAL = "UPDATE_PO_APPROVAL";


