import React from "react";
import PurchaseRequisitionUpload from "../../components/purchase-requisition/PurchaseRequisitionUpload";

const UploadPurhcaseRequisitionContainer = () => {
  return (
    <>
      <PurchaseRequisitionUpload />
    </>
  );
};

export default UploadPurhcaseRequisitionContainer;
