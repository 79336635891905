import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import {
  InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, getCumalativeReportBySo, getSoReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { dateTimeConvertLongString, getDateFromUtcDate } from "../../../../../domains/CCO/utils/DateHelper";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

const SecondarySalesSoReport = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const demandlist = useSelector((state) => state.secondarySales.cumalative);

  const totalQuantityCum = useSelector((state) => state.secondarySales.totalQuantityCum);
  const totalPriceCum = useSelector((state) => state.secondarySales.totalPriceCum);
  const amount = useSelector((state) => state.secondarySales);
  console.log('amountData :>> ', amount);
  console.log('demandlist', demandlist)
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
   
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id,selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
  }

  
  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  // const handleChangeTodate = ({ currentTarget: input }) => {
  //   setToDate(input.value);
  // };
  const handleChangeTodate = (input) => {
    setToDate(input);
  };
  // const handleListAction = (fromDate, tDate, idZone, idDivision, idArea,idTerritory) => {

  //   dispatch(getCumalativeReportBySo(fromDate, tDate, idZone, idDivision, idArea,idTerritory));
  // }

  const handleDateFilter=(option)=>{
    handleChangeTodate(option);
    // handleListAction(startDate, option, 0, selectDivision, selectArea);
  }




  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const showReport =()=>{
    dispatch(getSoReport(startDate,toDate));

  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Secondary Sales So Report</h3>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right ">
              <DownloadDropdown />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
            <SimpleSgeoType
             getSalesValue={getSalesValueType}
             sGeoType={selectGeoType}
             />
            </div>
          </div>
        </div>  
       
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={(event) => {
                  handleDateFilter(event.target.value)
                 

                }}

              />
            </Form.Group>
          </div>
          
            <div className="col mt-5">
            <Button size="sm" onClick={()=>showReport()}>Show Report</Button>
            </div>






        </div>
        <div>
          {demandlist.length > 0 &&
           
            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">
               
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget two">
                    <div className="widget__left">
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <p className="balanceleft">Cake Quantity</p>
                      <p className="balanceleft">Cake Value</p>
                    </div>
                    <div className="widget__right">
                     
                        <div className="overlay-layer bg-transparent">
                          {/* <div className="spinner spinner-lg spinner-warning" /> */}
                        </div>
                        <div>
                          <h4 className="balanceleft"> {parseFloat(amount.cakeQty)}</h4>
                          <h4 className="balanceleft">৳ {parseFloat(amount.cakePrice).toFixed(2)}</h4>
                        </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left" >
                      <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" />
                      <p className="balanceleft">Biscuit Quantity</p>
                      <p className="balanceleft">Biscuit Value</p>
                      <p className="balanceleftmodified">Wafer Value</p>
                      <p className="balanceleftmodified">Wafer QTY</p>
                    
                    </div>
                    <div className="widget__right">
                    
                        <div className="overlay-layer bg-transparent">
                          <div className="spinner spinner-lg spinner-warning" />
                        </div>
                    
                        <div>
                          <h4 className="balanceleft">{parseFloat(amount.biscuteQty+amount.cookisQty)}</h4>
                          <h4 className="balanceleft">৳ {parseFloat(amount.biscutePrice+amount.cookisPrice).toFixed(2)}</h4>
                          <h4 className="balanceleftmodified">৳ {parseFloat(amount.wiferPrice).toFixed(2)}</h4>
                          <h4 className="balanceleftmodified"> {parseInt(amount.wiferQty)}</h4>
                         
                        </div>

                     
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <div className="achiveLayer">
                      <span>Biscuit Quantity:  {parseInt(amount.biscuteQty+amount.cookisQty)}</span>
                      <span>Biscuit Value:  ৳ {parseFloat(amount.biscutePrice+amount.cookisPrice).toFixed(2)}</span>
                      <span>Wafer Quantity:   {parseInt(amount.wiferQty)}</span>
                      <span>Wafer Value:  ৳ {parseFloat(amount.wiferPrice).toFixed(2)}</span>
                      <span>Snacks Quantity:   {parseInt(amount.snacksQty)}</span>
                      <span>Snacks Value:  ৳ {parseFloat(amount.snacksPrice).toFixed(2)}</span>
                    
                    </div>
                  </div>
                </div>
             </div>






                {/* <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="paid__widget three">
                    <div className="widget__left">
                     
                      <p className="balanceleft">Cookies Quantity</p>
                      <p className="balanceleft">Cookies Price</p>
                    </div>
                    <div>
                      <h4 className="balanceleft">{parseFloat(amount.cookisQty)}</h4>
                      <h4 className="balanceleft">৳ {parseFloat(amount.cookisPrice).toFixed(2)}</h4>
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget four">
                    <div className="widget__left">
                      {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                      <p className="balanceleft">Total Quantity</p>
                      <p className="balanceleft">Total Value</p>
                    </div>
                    <div>
                      <h4 className="balanceleft">{parseInt(amount.totalQuantityCum)}</h4>
                      <h4 className="balanceleft">৳ {parseFloat(amount.totalPriceCum).toFixed(2)}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          }
        </div>

        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
                className="stickyTable"
                style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
              >
            <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}>
              <thead>
                <tr>
                 <th scope="col">ZONE NAME</th>
                 <th scope="col">DIVSION NAME</th>
                 {
                      selectGeoType==1?
                      <>
                       <th scope="col">AREA</th>
                      </>                     
                      :
                      <>
                      <th scope="col">REGION</th>
                      <th scope="col">TERRITORY</th>
                      </>
                    }
                  <th scope="col">JOINING DATE</th>
                  <th scope="col">SO ID</th>
                  <th scope="col">SO NAME</th>
                  <th scope="col">SO POINT ID</th>
                  <th scope="col">SO POINT</th>
                  <th scope="col">NUMBER OF MEMOlINE</th>
                  <th scope="col">TOTAL MEMO QTY</th>
                  <th scope="col">TOTAL VISITED OUTLET</th>
                  <th scope="col">TOTAL ORDER VALUE</th>
                  <th scope="col">BISCUIT QUNATITY</th>
                  <th scope="col">BISCUIT VALUE</th>
                  <th scope="col">CAKE QUNATITY</th>
                  <th scope="col">CAKE VALUE</th>
                  <th scope="col">COOKIES QUNATITY</th>
                  <th scope="col">COOKIES VALUE</th>
                  <th scope="col">WAFER QUNATITY</th>
                  <th scope="col">WAFER VALUE</th>
                  <th scope="col">SNACKS QUNATITY</th>
                  <th scope="col">SNACKS VALUE</th>
                  <th scope="col">TOTAL QUANTITY</th>
                  <th scope="col">TOTAL CTN(QTY)</th>
                  <th scope="col">TOTAL VALUE</th>
                </tr>
              </thead>
              <tbody>
                {demandlist.length > 0 &&
                  demandlist.map((item, index) => (
                    <tr>
                      {/* <td>{item.orderId}</td> */}
                      <td>{item.zoneName}</td>
                      <td>{item.divisionName}</td>
                      {
                            selectGeoType==1?
                            <td>{item.areaName}</td>:
                            <>
                              <td>{item.areaName}</td>
                              <td>{item.territoryName}</td>
                            </>
                          
                          }
                      <td>{dateTimeConvertLongString(item.joiningDate)}</td>
                      <td>{item.soId}</td>
                      <td>{`${item.soName}`}</td>
                      <td>{item.delpointId}</td>
                      <td>{item.delpointName}</td>
                      <td>{parseInt(item.memoLine)}</td>
                      <td>{parseInt(item.memoQty)}</td>
                      <td>{parseFloat(item.visitedOutlet)}</td>
                      <td>{parseFloat(item.orderValue)}</td>
                      <td>{item.biscuteQty}</td>
                      <td>{parseFloat(item.biscutePrice).toFixed(2)}</td>
                      <td>{item.cakeQty}</td>
                      <td>{parseFloat(item.cakePrice).toFixed(2)}</td>
                      <td>{item.cookisQty}</td>
                      <td>{parseFloat(item.cookisPrice).toFixed(2)}</td>
                      <td>{parseInt(item.wiferQty)}</td>
                      <td>{parseInt(item.wiferPrice)}</td>
                      <td>{parseInt(item.snacksQty)}</td>
                      <td>{parseInt(item.snacksPrice)}</td>
                      <td>{parseFloat(item.totalQuantity).toFixed(2)}</td>
                      <td>{parseFloat(item.totalQuantityCtn).toFixed(2)}</td>
                      <td>{parseFloat(item.totalPrice).toFixed(2)}</td>

                      {/* <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                        

                        <Link onClick={() => gotoEditPage(item)} title="Edit">
                          <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                        </Link>

                        <a
                          href
                          onClick={(id) => handleDeleteItem(item.orderId)}
                          title="Delete"
                        >
                          <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                        </a>
                      </td> */}
                    </tr>
                  ))}


              </tbody>
            </table>
            </div>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
            {/* <PaginationLaravel
              isDescription={true}
              changePage={changePage}
              data={productList}
            /> */}
          </div>
        </div>

        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default SecondarySalesSoReport;
