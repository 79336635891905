import React from 'react'
import { FaSearch, FaTable } from 'react-icons/fa'
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

const WelCome = () => {
    const history = useHistory();
    const gotoDashboardPage = (item) => {
        history.push(`/SPerformanceDashboard`);
    }


    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>WELCOME</strong>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>TO</strong>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>AKIJ</strong>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>
                    <strong className='text-warning dashboardfont' style={{ textAlign: 'center' }}>BAKERS</strong>
                </h1>
            </div>
            {/* <div className='mb-5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ textAlign: 'center' }}>
                    <Link onClick={() => gotoDashboardPage()} >
                        <Button size="lg" className="btn btn-success">
                            <FaTable style={{ marginRight: '5px' }} />Click Here To Show Dashboard
                        </Button>
                    </Link>
                </span>
            </div> */}
            {/* <marquee className="card-title font-weight-bolder text-red" behavior="scroll" direction="left">IMS থেকে Dashbord অংশটি সাময়িক সময়ের জন্য বন্ধ রাখা হয়েছে।</marquee> */}
            {/* <h3 className="card-title font-weight-bolder text-danger text-center" behavior="scroll" direction="left">***IMS থেকে Dashboard অংশটি সাময়িক সময়ের জন্য বন্ধ রাখা হয়েছে***</h3> */}
            {/* <div className='text-center mt-4'>
                <img
                    src="/media/bg/notice.jpg"
                    alt="Notice"
                    width="350"
                    height="370"
                    style={{
                        border: '1px solid yellow',
                        borderRadius: '10px', // Optional: Add border-radius for rounded corners
                        boxShadow: '0 0 8px 3px red', // Box shadow for the lighting-blowing effect
                        transition: 'box-shadow 0.3s ease-in-out', // Optional: Add transition for a smooth effect
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.boxShadow = '0 0 20px 10px red'; // Increase the box shadow on hover
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.boxShadow = '0 0 10px 5px red'; // Reset box shadow when not hovering
                    }}
                />
            </div> */}



        </>
    )
}

export default WelCome