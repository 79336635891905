import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  certificatecategorySubmitAction,
  getCertificateCategoryListData,
  getCertificateParentCategoryData,
  handleCertificateCategoryInput,
} from "../../_redux/actions/CertificateCategoryAction";

const CertificateCategoryAdd = (props) => {
  const history = useHistory();
  const { isSubCategory } = props;
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const getCategoryInpuData = useSelector(
    (state) => state.CertificateCategoryReducer.certificateCategoryInput
  );

  const certificateParentCategoryList = useSelector(
    (state) => state.CertificateCategoryReducer.certificateParentCategoryList
  );

  const status = useSelector(
    (state) => state.CertificateCategoryReducer.status
  );
  const isLoading = useSelector(
    (state) => state.CertificateCategoryReducer.isLoading
  );

  const categoryInputChange = (name, value) => {
    dispatch(handleCertificateCategoryInput(name, value));
  };

  const submiteCategory = (data) => {
    dispatch(certificatecategorySubmitAction(getCategoryInpuData, isSubCategory));
  };
  useEffect(() => {
    dispatch(getCertificateParentCategoryData());
    if (status) {
      dispatch(getCertificateCategoryListData());
    }
  }, [status]);

  const statusOptions = [
    {
      label: "Active",
      value: 0,
    },
    {
      label: "Inactive",
      value: 1,
    },
  ];

  // useEffect(() => {
  //   dispatch(getCertificateParentCategoryData());
  // }, []);

  const loading = false;
  return (
    <>
      <form
        className="form form-label-right voyageEngineerForm"
        onSubmit={handleSubmit(submiteCategory)}
        method="post"
      >
        <div className="form-group mt-0">
          <label className="form-label formFont">Category Name</label>
          <Form.Control
            type="text"
            className="formHeight"
            ref={register({
              required: true,
              maxLength: 100,
            })}
            placeholder="Type Category Name"
            name="strCertificateCategoryName"
            onChange={(e) => {
              categoryInputChange(
                "strCertificateCategoryName",
                e.target.value
              );
            }}
          />
          <div className="inputError margin-minus-8">
            {errors.strCertificateCategoryName &&
              errors.strCertificateCategoryName.type === "required" &&
              "Certificate Category name can't be blank"}
          </div>
        </div>
        <div className="form-group mt-0 ">
          <label className="form-label formFont">
            Parent Category <span className="text-info"> (Optional)</span>
          </label>
          <RHFInput
            as={<Select options={certificateParentCategoryList}
              isDisabled={typeof isSubCategory === 'undefined' ? false : true}
            />}
            rules={{ required: false }}
            name="intParentsCategoryID"
            className="formSelect pt-0"
            register={register}
            value={getCategoryInpuData.certificateCategoryParent}
            onChange={(option) => {
              categoryInputChange("certificateCategoryParent", {
                label: option.label,
                value: option.value,
              });
              categoryInputChange("intParentsCategoryID", option.value);
            }}
            setValue={setValue}
          />
        </div>

        <div className="form-group row">
          <div className="col-sm-10"></div>
        </div>
        {isLoading && (
          <button
            type="submit"
            class="btn btn-primary saveButton text-white mt-3"
            disabled={true}
          >
            <span className="p-1">Submitting...</span>
            <span className="ml-3 spinner spinner-white "></span>
          </button>
        )}
        {!isLoading && (
          <button
            type="submit"
            className="btn btn-primary saveButton text-white mt-3"
          >
            <span>Submit</span>
          </button>
        )}
      </form>
    </>
  );
};

export default CertificateCategoryAdd;
