export const CHANGE_PURCHASE_APPROVAL_FILTER_INPUT = "CHANGE_PURCHASE_APPROVAL_FILTER_INPUT";
export const CHANGE_PURCHASE_APPROVAL_DETAIL_INPUT = "CHANGE_PURCHASE_APPROVAL_DETAIL_INPUT";
export const GET_SHIP_NAME = "GET_SHIP_NAME";
export const GET_SBU_NAME = "GET_SBU_NAME";
export const GET_PURCHASE_APPROVAL_LIST = "GET_PURCHASE_APPROVAL_LIST";
export const GET_PURCHASE_APPROVAL_DETAIL = "GET_PURCHASE_APPROVAL_DETAIL";
export const SUBMIT_PURCHASE_APPROVE = "SUBMIT_PURCHASE_APPROVE";
export const REJECTED_PURCHASE_APPROVAL = "REJECTED_PURCHASE_APPROVAL";


