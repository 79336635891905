import React from "react";
import PurchaseRequestCreate from "../components/PurchaseRequestCreate";

const PurchaseRequestCreateContainer = () => {
  return (
    <div className="container">
      <PurchaseRequestCreate />
    </div>
  );
};

export default PurchaseRequestCreateContainer;
