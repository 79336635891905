import React from "react";
import CertificateMainAdd from "../components/create/CertificateMainAdd";

const CertificateMainAddContainer = () => {
  return (
    <>
      <CertificateMainAdd />
      <br />
    </>
  );
};

export default CertificateMainAddContainer;
