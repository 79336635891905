import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getZoneList,
  getDistributorReport,
  getTerritory,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {  useHistory } from "react-router-dom";
import SimpleSalesType from "../SalesType/SimpleSalesType";

const DistributorReportsFilter = () => {
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const dispatch = useDispatch();

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [salesType, setSalesType] = useState(1);
  const [distribType, setDistribType] = useState(null);


  const CertificateMasterInput = useSelector(
    (state) => state.CertificateListReducer.certificateMasterInput
  );

  const { register, setValue } = useForm();

  // const statusOptions = [
  //   {
  //     label: "Active",
  //     value: "1",
  //   },
  //   {
  //     label: "Inactive",
  //     value: "0",
  //   },
  // ];

  useEffect(() => {
    //dispatch(getCertificateMasterList("", "", currentPage));
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const zoneDropdownData = () => {
    let options = [];
    if (zone) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);

    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const showBrReport = () => {
    if (selectZone == null) {
      alert("Please select Zone");
      return false;
    } else if (selectDivision == null) {
      alert("Please select Division");
      return false;
    } else if (selectArea == null) {
      alert("Please select Area");
      return false;
    } else if (startDate == null) {
      alert("Please select From Date");
      return false;
    }
    // dispatch(getDistributorBrReport(selectArea,startDate,toDate));
    dispatch(getDistributorReport(selectArea, startDate, salesType));
  }

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };






  return (
    <>
      <div className="pb-5 pt-5 ">
        <div className="row">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Distributor Bank Receive Daily</h3>
            </div>
          </div>
          {/* <div className="col-md-3">
          <div className="btn-group SalesType float-right" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check salsRadious" name="btnradio" id="btnradio1" value="1" autocomplete="off" checked={salesType == 1 ? true : false} onChange={(e) => getSalesValue('general', e.target.value)} />
            <label class="btn btn-outline-primary salsRadious" for="btnradio1">General Sales</label>
            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="3" checked={salesType == 3 ? true : false} onChange={(e) => getSalesValue('Bread', e.target.value)} />
            <label class="btn btn-outline-primary" for="btnradio2">Bread & Burn</label>
          </div>
        </div> */}
          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>
        <hr></hr>
      </div>

      <div className="col-lg-3">
      <Form.Group as={Col} controlId="formGridState">
        <label className="form-label">Select Date</label>
        <Form.Control
          type="date"
          placeholder="Enter Todate"
          name="fromDate"
          className="fromStyle"
          onChange={handleChangeTodate}
        />
      </Form.Group>
      </div>

      <div className="col-lg-3">
        <Form.Group as={Col} controlId="formGridState">
          <label className="form-label">Select Zone</label>
          <RHFInput
            className="formSelect pt-0"
            as={<Select options={zoneDropdownData()} />}
            rules={{ required: false }}
            name="zoneData"
            register={register}
            value={selectZone}
            onChange={(option) => {
              setSelectZone(option.value);
              divisionList(option.value);
              setValue("divisionData", "");
              setValue("areaData", "");
              setValue("territory", "");
            }}
            setValue={setValue}
          />
        </Form.Group>
      </div>
      <div className="col-lg-3">
        <Form.Group as={Col} controlId="formGridState">
          <label className="form-label">Select Division</label>
          <RHFInput
            className="formSelect pt-0"
            as={<Select options={divisionDropdownData()} />}
            rules={{ required: false }}
            name="divisionData"
            register={register}
            value={CertificateMasterInput.isActive}
            onChange={(option) => {
              // setType(option.value)
              setSelectDivision(option.value);
              getAreaData(option.value);
              setValue("areaData", "");
              setValue("territory", "");
            }}
            setValue={setValue}
          />
        </Form.Group>
      </div>
      <div className="col-lg-3">
        <Form.Group as={Col} controlId="formGridState">
          <label className="form-label">Select Region</label>
          <RHFInput
            className="formSelect pt-0"
            as={<Select options={areaDropdownData()} />}
            rules={{ required: false }}
            name="areaData"
            register={register}
            // value={CertificateMasterInput.isActive}
            onChange={(option) => {
              // setType(option.value)
              // setSelectDivision(option.value);
              getTerritoryData(option.value)
              setSelectArea(option.value);
      
              setValue("territory", "");
              // dispatch(getDistributorReport(option.value));
            }}
            setValue={setValue}
          />
        </Form.Group>
      </div>

      <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option.value);
                   
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>



      {/* <div className="col-lg-2">
        <Form.Group as={Col} controlId="formGridState">
          <RHFInput
            className="formSelect pt-0"
            as={<Select options={areaDropdownData()} />}
            rules={{ required: false }}
            name="areaData"
            register={register}
            // value={CertificateMasterInput.isActive}
            onChange={(option) => {
              // setType(option.value)
              setSelectDivision(option.value);
              dispatch(areaDataSelect(option.value));
              // dispatch(getDistributorReport(option.value));
            }}
            setValue={setValue}
          />
        </Form.Group>
      </div> */}
      
      <div className="col-lg-2 ml-3">
        <Button variant="primary"  onClick={() => showBrReport()} size="sm" className="mt-5">Show Report</Button>
       
      </div>
      <div>
      
        {/* <div className="float-right" onClick={() => history.go(0)} >
          <i className="fas fa-sync-alt editIcon refreshIcon ml-4" ></i>
          <p>Refresh</p>
        </div> */}
      </div>

      {/* <div className="col-lg-2">
          <Button variant="primary" onClick={()=>showBrReport()}>
                  Show
        </Button>
      </div> */}
    </>
  );
};

export default DistributorReportsFilter;
