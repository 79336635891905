import React from "react";

const ControllingUnitListContainer = () => {
  return (
    <>
      <h2>Controlling Unit</h2>
      <p>Testing Controlling Unit List Page</p>
    </>
  );
};

export default ControllingUnitListContainer;
