import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import {
  InputHandle
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button,Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, getSecondarySalesStatusReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { dateTimeConvertLongString} from "../../../../../domains/CCO/utils/DateHelper";

const SecondaryStatusSales = () => {
  const history = useHistory();
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const demandlist = useSelector((state) => state.secondarySales.secondaryStatus);
  console.log('demandlist :>> ', demandlist);
  const salesGiven = useSelector((state) => state.secondarySales.salesGiven);
  const salesNotGiven = useSelector((state) => state.secondarySales.salesNotGiven);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [reportType, setReportType] = useState(2);
  const [reportTypeDDl, setReportTypeDDl] = useState([
    {
      value:1,
      label:"All"
    },
    {
      value:3,
      label:"NOT Sales"
    },
    {
      value:2,
      label:"Sales"
    },
  ]);
  const componentRef = useRef();

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
  };



  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id,selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id,selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };
  const handleListAction = () => {
    dispatch(getSecondarySalesStatusReport(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, reportType));
  }

  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">SO Wise IMS Status Report</h3>
            </div>
          </div>
          <div className="col-md-3">
            <DownloadDropdown excelname='SO Wise IMS Status Report' />
          </div>

        </div>
        <hr></hr>
        



        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>

              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                 
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  
                  setValue("territory", "");
                  
                 
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                  
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>

          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Report Type</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={reportTypeDDl} />}
                rules={{ required: false }}
                name="reportype"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  
                  setReportType(option?.value);
                  
                 
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3 mt-5">
            <Button size="sm" onClick={() => handleListAction()}>Show Report</Button>
          </div>
        </div>
        <div className="container-fluid mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Sales Given</p>
                </div>
                <div className="widget__right">
                    <h4>{salesGiven}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Sales Not Given</p>
                </div>
                <div className="widget__right">
                    <h4>{salesNotGiven}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  {/* <img src="/media/svg/icons/Code/cil_money.svg" alt="iMarine" /> */}
                  <p>Total Sales</p>
                </div>
                <div className="widget__right">
                  <h4>{demandlist.length}</h4>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        
        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
            {/* {isLoading && <LoadingSpinner text="Loading Items List..." />} */}
            <div
                className="stickyTable"
                style={{ height: demandlist?.length > 0 ? 800 + "px" : 0 }}
              >
            {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
            <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
              <thead>
                <tr>
                  <th scope="col">DATE</th>
                  <th scope="col">ZONE</th>
                  <th scope="col">DIVISION</th>
                  <th scope="col">REGION</th>
                  <th scope="col">TERRITORY</th>
                  <th scope="col">SO POINT ID</th>
                  <th scope="col">SO POINT NAME</th>
                  <th scope="col">GROUP NAME</th>
                  <th scope="col">Joining Date</th>
                  <th scope="col">SO ID</th>
                  <th scope="col">SO NAME</th>
                  <th scope="col">CONTACT NO</th>
                  <th scope="col">DELIVERY STATUS</th>
                </tr>
              </thead>
              <tbody>
                {demandlist.length > 0 &&
                  demandlist.map((item, index) => (
                    <tr className={item.status==false?"markDetails":null}>
                      <td>{dateTimeConvertLongString(item.salesDate)}</td>
                      <td>{item.zone}</td>
                      <td>{item.division}</td>
                      <td>{item.area}</td>
                      <td>{item.territoryName}</td>
                      <td>{item.soPointID}</td>
                      <td>{item.soPointName}</td>
                      <td>{item.salesGroup}</td>
                      <td>{dateTimeConvertLongString(item?.joiningDate)}</td>
                      <td>{item.soEnroll}</td>
                      <td>{item.soName}</td>
                      <td>{item.contract}</td>
                      <td>{item.deliveryStatus}</td>
                    </tr>
                  ))}


              </tbody>
            </table>
            </div>
            {!isLoading && demandlist.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )}
           
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondaryStatusSales;
