import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
  cleanSecondarySalesData,
  getSOSkuProduct
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";

import * as XLSX from "xlsx/xlsx.mjs";
import { getSecondarysoProductForExcel } from "../../_redux/actions/Delivery/DeliveryAction";
import { currentdate } from "../../../../../domains/CCO/utils/DateHelper";
import { getDistributorWiseDamageReport } from "../../_redux/actions/RemoteSales/DamageAction";

const DistributorWiseDamageReport = () => {
  const history = useHistory();
  const itemList = useSelector((state) => state.itemList.itemList);
  const itemListPaginated = useSelector(
    (state) => state.remoteSales.productList
  );
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const productReport = useSelector((state) => state.secondarySales.soWiseProduct);
  const skuTotal = useSelector((state) => state.secondarySales.skuTotal);
  const skuQuantity = useSelector((state) => state.secondarySales.skuQuantity);
  const productTotal = useSelector((state) => state.secondarySales.productTotal);
  const productQuantity = useSelector((state) => state.secondarySales.productQuantity);
  const productPrice = useSelector((state) => state.secondarySales.productPrice);
  const amount = useSelector((state) => state.secondarySales);
  const distributorList = useSelector(
    (state) => state.remoteSales.distributorList
  );

  const damageData = useSelector((state) => state.damage.distributordamagedata);
  console.log('damageData :>> ', damageData);

  
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectGeoType,setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const componentRef = useRef();




  
  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {

    let zoneData = await getZoneList();

    Setzone(zoneData.data);
    // zoneDropdownData(zoneData.data)
    // zoneDropdownData(zoneData.data);
  };

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id,selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id,selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);



  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);




  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea,idTerritory) => {
    dispatch(getDistributorWiseDamageReport(fromDate, tDate, idZone, idDivision, idArea,idTerritory));
  }




  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id,selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }

  const hanleExcelClick = async () => {
    let excelClick = await getSecondarysoProductForExcel(
      startDate,
      toDate,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory,
      3
    );
    const current = currentdate();

    // const worksheet = XLSX.utils.json_to_sheet(excelClick.data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  
    // XLSX.writeFile(workbook, "DataSheet.xlsx");
  

    let Heading = [['ZoneId', 'Zone Name', 'Divisionid','DivisionName',
    'RegionID','RegionName','TerritoryId','TerritoryName','SoPointid',
    'SoPointName','SoEnroll','SoName','ProductId','ProductName','Uom','Price','Qty','CtnQty','Value'
  ]];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    XLSX.writeFile(wb, 'So SKU Wise Sku Report'+" "+ current +'.xlsx');
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Distributor Wise Damage Report</h3>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              {/* <Button size="sm" onClick={() => hanleExcelClick()} >Download</Button> */}
              <DownloadDropdown excelname='Distributor Wise Damage Report' />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
            <SimpleSgeoType
             getSalesValue={getSalesValueType}
             sGeoType={selectGeoType}
             />
            </div>
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectZone(option?.value);
                  divisionList(option?.value);

                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  getTerritoryData(option?.value)
                  setSelectArea(option?.value);
                  
                  setValue("territory", "");
                  // handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Territory</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={territoryDropdownData()} />}
                  rules={{ required: false }}
                  name="territory"
                  register={register}
                  value=""
                  isClearable
                  // value={CertificateMasterInput.isActive}
                  onChange={(option) => {
                    setSelectTerritory(option?.value);
                    // handleListAction(startDate, toDate, selectZone, selectDivision, selectArea,option.value);
                   
                  }}
                  setValue={setValue}
                />
              </Form.Group>
            </div>
          <div className="col-md-3 mt-5 mb-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea,selectTerritory,selectGeoType)}>Show Report</Button>
          </div>
         
        </div>
        <div>
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">
              
              <div
                className="stickyTable"
                style={{ height: damageData?.length > 0 ? 800 + "px" : 0 }}
              >
              {/* <table className="table table-head-custom table-vertical-center   item-add-table" id="table-to-xls" ref={componentRef}> */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead className="bg-white" >

                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">DISTRIBUTOR</th>
                    <th scope="col">DISPOINT</th>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">PRODUCT CATEGORY</th>
                    <th scope="col">PRODUCT QUANTITY(QTY)</th>
                    <th scope="col">PRODUCT TOTAL VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  damageData?.length > 0 &&
                    damageData?.map((item, index) => (
                      <>
                        <tr className="alert bg-secondary">
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.distribName}</td>
                          <td>{item.dispointName}</td>
                          <td>{item.productId}</td>
                          <td>{item.productName}</td>
                          <td />
                          <td />
                          <td />
                          
                        </tr>
                        {
                          item?.productDetails.map((product, subindex) => (
                            <>
                                <tr>
                                <td>{item.zoneName}</td>
                                <td>{item.divisionName}</td>
                                <td>{item.areaName}</td>
                                <td>{item.territoryName}</td>
                                <td>{item.distribName}</td>
                                <td>{item.dispointName}</td>
                                <td />
                                <td />
                                <td>{product.categoryName}</td>
                                <td>{product.quantity}</td>
                                <td>{product.productValue}</td>
                                
                                </tr>

                                    {/* {
                                        product?.details.map((category, subindex) => (
                                            <tr>
                                                <td>{item.zoneName}</td>
                                                <td>{item.divisionName}</td>
                                                <td>{item.areaName}</td>
                                                <td>{item.territoryName}</td>
                                                <td>{item.distribName}</td>
                                                <td>{item.dispointName}</td>
                                                <td />
                                                <td />
                                                <td>{category.categoryName}</td>
                                                <td>{category.quantity}</td>
                                            </tr>

                                        ))
                                    } */}
                            </>

                          ))}
                      </>
                    ))}


                </tbody>
              </table>
              </div>
              {!isProductLoading && damageData.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! List Not Found.
              </div>
            )}
            </div>
          </div>

        </div>



        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>
      </div>
    </>
  );
};

export default DistributorWiseDamageReport;
