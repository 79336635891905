//post
export const PURCHASE_REQUEST_INPUT_CHANGE = "PURCHASE_REQUEST_INPUT_CHANGE"
export const PURCHASE_REQUEST_ROW_INPUT_CHANGE = "PURCHASE_REQUEST_ROW_INPUT_CHANGE"
export const MULTIPLE_PURCHASE_DATA_CREATE = "MULTIPLE_PURCHASE_DATA_CREATE"
export const DELETE_MLTIPLE_PQ_ROW_DATA = "DELETE_MLTIPLE_PQ_ROW_DATA"
export const PQ_DATA_SUBMIT = "PQ_DATA_SUBMIT"

//get 
export const GET_PQ_LIST_DATA = "GET_PQ_LIST_DATA"
export const GET_PR_DETAILS_DATA = "GET_PR_DETAILS_DATA"
