// Vessel booking entry
export const VESSEL_BOOKING_INPUT_CHANGE = "VESSEL_BOOKING_INPUT_CHANGE";
export const GET_BROKER_LIST = "GET_BROKER_LIST";
export const GET_CHARTER_LIST = "GET_CHARTER_LIST";
export const GET_SHIP_LIST = "GET_SHIP_LIST";
export const GET_VOYAGE_TYPE = "GET_VOYAGE_TYPE";
export const GET_COMMENCE_PORT = "GET_COMMENCE_PORT";
export const GET_COMPLETION_PORT = "GET_COMPLETION_PORT";
export const GET_CARGO_LIST = "GET_COMPLETION_PORT";
//submit 
export const VESSEL_BOOKING_SUBMIT = "VESSEL_BOOKING_SUBMIT";
export const VESSEL_BOOKING_SUBMITTING = "VESSEL_BOOKING_SUBMITTING";
export const VESSEL_BOOKING_EDIT = "VESSEL_BOOKING_EDIT";
export const VESSEL_BOOKING_EDITTING = "VESSEL_BOOKING_EDITTING";

// get vessel bookig list 
export const GET_VESSEL_BOOKING_LIST = "GET_VESSEL_BOOKING_LIST";
export const GET_VESSEL_BOOKING_DETAILS = "GET_VESSEL_BOOKING_DETAILS";
export const DELETE_VESSEL_BOOKING = "DELETE_VESSEL_BOOKING";
