import React from "react";
import BookingList from "../components/BookingList";

const BookingListContainer = () => {
  return (
    <>
      <div className="container">
        <BookingList />
      </div>
    </>
  );
};

export default BookingListContainer;
