import React from "react";
import EmployeeSigningList from "../../components/hr/employee/signing/EmployeeSigningList";


const EmployeeSigningListContainer = () => {
    return (
        <>
            <EmployeeSigningList />
        </>
    );
};

export default EmployeeSigningListContainer;
