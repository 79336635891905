export const GET_LAYTIME_LIST = "GET_LAYTIME_LIST";
export const CHANGE_LAYTIME_HEADER_INPUT = "CHANGE_LAYTIME_HEADER_INPUT";
export const CHANGE_LAYTIME_DETAIL_INPUT = "CHANGE_LAYTIME_DETAIL_INPUT";
export const GET_CURRENCY_DATA = "GET_CURRENCY_DATA";
//get header input from api
export const GET_HEADER_INPUT_FROM_API = "GET_HEADER_INPUT_FROM_API";
//GET VOYAGE ID
export const GET_VOYAGE_ID = "GET_VOYAGE_ID";
export const CHANGE_LAYTIME_HEADER_DEMURRAGE_INPUT = "CHANGE_LAYTIME_HEADER_DEMURRAGE_INPUT";
export const ADD_MULTIPLE_DUMMARAGES = "ADD_MULTIPLE_DUMMARAGES";

// submitted laytime data 
export const LAYTIME_DATA_SUBMIT = "LAYTIME_DATA_SUBMIT";
export const LAYTIME_DATA_SUBMITTING = "LAYTIME_DATA_SUBMITTING";
export const DELETE_LAYTIMEROW_DATA = "DELETE_LAYTIMEROW_DATA";

//laytime multiple

export const LAYTIME_MULTIPLE_DATA = "LAYTIME_MULTIPLE_DATA";
export const LAYTIME_DETAILS_ENTRY_HEADER_DATA = "LAYTIME_DETAILS_ENTRY_HEADER_DATA";

//remark list

export const GET_ROWTIME_REMARK_LIST = "GET_ROWTIME_REMARK_LIST";

//SOF LIST

export const LAYTIMEROW_SOF_LIST = "LAYTIMEROW_SOF_LIST";

export const ADD_NEW_SOF = "ADD_NEW_SOF";

export const ADD_NEW_OPERATION = "ADD_NEW_OPERATION";

export const LAYTIME_OPERATION_DATA = "LAYTIME_OPERATION_DATA";

export const SOFANDOPERATION_DATA_SUBMITTING = "SOFANDOPERATION_DATA_SUBMITTING";

export const SOFANDOPERATION_DATA_SUBMIT = "SOFANDOPERATION_DATA_SUBMIT";

export const DELETE_SOF_DATA = "DELETE_SOF_DATA";

export const DELETE_OPERATION_DATA = "DELETE_OPERATION_DATA";
export const REMOVE_PARENT_SOF_LIST = "REMOVE_PARENT_SOF_LIST";
export const GET_LAYTIMEROW_LIST_FROM_API = "GET_LAYTIMEROW_LIST_FROM_API";
export const GET_VOYAGE_LIST = "GET_VOYAGE_LIST";

