import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import NewLoader2 from '../../../../master/spinner/NewLoader2';
import { GetAllSoValueAcvr, TotalWorkingDayAndNeedImsCalculation } from '../../../information/_redux/actions/Report/ReportAction';
import { useState } from 'react';
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { FaSearch } from 'react-icons/fa';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import { dateTimeConvertLongStringForDate } from '../../../../../domains/CCO/utils/DateHelper';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { formatDate } from '../../../../master/PrintDwopdown/DateHelper';

export const numberFormat = (number) => {
    if (number == null) {
        return "0";
    } else {
        const parts = number.toString().split(".");
        const wholePart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        const formattedValue = parts.length > 1 ? `${wholePart}.${parts[1]}` : wholePart;
        return formattedValue;
    }
};

const SalesOfficerPerDayValueAchvr = () => {

    const { register, control, setValue } = useForm();

    const reportDataLoading = useSelector((state) => state.target.zoneReportsLoading);
    const TotalTarget = useSelector((state) => state.target.TotalTarget);
    const totalDays = useSelector((state) => state.target.totalDays);
    const workingLeft = useSelector((state) => state.target.workingLeft);

    const Top20SO = useSelector((state) => state.target.Top20SO);
    const Bottom20SO = useSelector((state) => state.target.Bottom20SO);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [selectFilterData, setSelectFilterData] = useState(1);


    const [reportList, setReportList] = useState([
        {
            id: 4,
            type: 'All'
        },
        {
            id: 1,
            type: 'Funtastic'
        },
        {
            id: 2,
            type: 'Fast'
        },
        {
            id: 3,
            type: 'Bakemans'
        }
    ]);

    const reportTypeDdl = () => {
        let options = [];
        if (reportList?.length > 0) {
            reportList.forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.type,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const componentRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        const d = new Date();
        const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
        const previousDay = new Date(d);
        previousDay.setDate(d.getDate() - 1);

        const firstD = formatDate(firstDayOfMonth);
        const currentD = formatDate(previousDay);
        dispatch(TotalWorkingDayAndNeedImsCalculation(firstD, currentD, 1));
        dispatch(GetAllSoValueAcvr(firstD));
    }, [])

    const showReport = () => {
        dispatch(TotalWorkingDayAndNeedImsCalculation((selectedMonthDate), (selectedMonthDate), selectFilterData));
        dispatch(GetAllSoValueAcvr((selectedMonthDate)));

    }

    const needIms = (TotalTarget && ((TotalTarget / totalDays) * (workingLeft / TotalTarget)) * 100);

    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);
    const [selectedMonthDate, setSelectedMonthDate] = useState(initialMonth);

    const handleMonthChange = (event) => {
        const month = event.target.value;
        setSelectedMonth(event.target.value);
        setSelectedMonthDate(`${month}-01`);
    };


    return (
        <div className='card fullcardbg m-3' style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
        }}>

            <div className="container-fluid form-group row pl-4 paddingremove">
                <div className="form-group row pl-4">
                    <div className="col-lg-5 col-sm-5">
                        <Form.Group controlId="formGridState">
                            <label className="form-label text-light mb-0 mr-3">Select Month</label>
                            <div>
                                <input
                                    className='example-custom-input-month'
                                    type="month"
                                    id="monthPicker"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                />
                            </div>
                        </Form.Group>
                    </div>

                    <div className={`col-lg-6 col-sm-6 ${isSmallScreen ? '' : 'mt-6'}`}>
                        <button
                            size="sm"
                            onClick={() => showReport()}
                            className="btn btn-success"
                            style={{
                                height: "30px",
                                padding: "0 15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <FaSearch style={{ marginRight: '5px' }} />
                            Show Report
                        </button>
                    </div>

                </div>
            </div>


            <div className="row" ref={componentRef}>


                <div className='col-lg-12' >
                    {reportDataLoading && <NewLoader2 />}


                    <div className="table-responsive pl-5 pr-5 pb-5 " ref={componentRef}>
                        <table id="table-to-xls" className="printtablenew1 " >

                            {workingLeft &&
                                <thead >
                                    <tr className="backcolor">
                                        <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#E2DDD7', textTransform: 'uppercase' }}>SALES OFFICER ( PER DAY VALUE ACHIEVER )</th>
                                    </tr>
                                    <tr >
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS:&nbsp;&nbsp;{totalDays}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>WORKING DAYS PASS:&nbsp;&nbsp;{workingLeft}</th>
                                        <th colSpan="4" className="text-center" style={{ color: 'black', backgroundColor: '#FFFACD' }}>NEED IMS:&nbsp;&nbsp;{parseFloat(needIms).toFixed(2)}% </th>
                                    </tr>
                                    {Top20SO?.length > 0 && <>
                                        <tr className="backcolor">
                                            <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>TOP TWENTY SALES OFFICER ( PER DAY TOP VALUE ACHIEVER )</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th className="text-center">SO</th>
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">IMS VALUE</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                        </tr>
                                    </>}

                                </thead>
                            }
                            <tbody>
                                <>
                                    {Top20SO?.length > 0 &&
                                        Top20SO?.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{index++ + 1}</td>
                                                    <td className="text-center">{item.delPointName}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsTarget)) ? '0.00' : parseFloat(item.imsTarget).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.totImsCm)) ? '0.00' : parseFloat(item.totImsCm).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsAchPer)) ? '0.00' : parseFloat(item.imsAchPer).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsLandingPer)) ? '0.00' : parseFloat(item.imsLandingPer).toFixed(2)}</td>

                                                    <td className="text-center">{isNaN((item.totSo)) ? '0' : (item.totSo)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsGrowth)) ? '0.00' : parseFloat(item.imsGrowth).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.perSoIms)) ? '0.00' : parseFloat(item.perSoIms).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN((item.imsRanking)) ? '0' : (item.imsRanking)}</td>

                                                </tr>
                                            );
                                        })}


                                    {Bottom20SO?.length > 0 && <>
                                        <tr className="backcolor1">
                                            <th colSpan="12" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}>BOTTOM TWENTY SALES OFFICER ( PER DAY BOTTOM VALUE ACHIEVER )</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th className="text-center">SO</th>
                                            <th className="text-center">Total IMS Target</th>
                                            <th className="text-center">IMS VALUE</th>
                                            <th className="text-center">IMS Ach%</th>
                                            <th className="text-center">IMS Landing %</th>
                                            <th className="text-center">SO QTY</th>
                                            <th className="text-center">IMS Growth%</th>
                                            <th className="text-center">Average Per So Per Day IMS</th>
                                            <th className="text-center">Ranking(TAR Vs IMS Ach%)</th>
                                        </tr></>}
                                    {Bottom20SO?.length > 0 &&
                                        Bottom20SO?.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td className="text-center">{index++ + 1}</td>
                                                    <td className="text-center">{item.delPointName}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsTarget)) ? '0.00' : parseFloat(item.imsTarget).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.totImsCm)) ? '0.00' : parseFloat(item.totImsCm).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsAchPer)) ? '0.00' : parseFloat(item.imsAchPer).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsLandingPer)) ? '0.00' : parseFloat(item.imsLandingPer).toFixed(2)}</td>

                                                    <td className="text-center">{isNaN((item.totSo)) ? '0' : (item.totSo)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.imsGrowth)) ? '0.00' : parseFloat(item.imsGrowth).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN(parseFloat(item.perSoIms)) ? '0.00' : parseFloat(item.perSoIms).toFixed(2)}</td>
                                                    <td className="text-center">{isNaN((item.imsRanking)) ? '0' : (item.imsRanking)}</td>

                                                </tr>
                                            );
                                        })}




                                </>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesOfficerPerDayValueAchvr

