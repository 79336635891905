import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Link} from "react-router-dom";
import {
  getSalesList,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { generateStringDateFromDate } from "../../../../../domains/CCO/utils/DateHelper";
import { cleanTargetSalesData, getSubLedger } from "../../_redux/actions/Report/ReportAction";

const CustomerLedger = () => {

  const subLedgerData = useSelector((state) => state.delivery.subLedgerData);
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromTime, setFromTime] = useState("12.00");
  const [defaultTime, setDefaultTime] = useState("date");
  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  })

  const componentRef = useRef();
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanTargetSalesData());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };
  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };
        options.push(itemData);
      });
    }
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const itemDetail = (item) => {
    alert();
    // setItemifo(item);
    // setItemID(item.orderId);
    // setItemDetailShow(true);
   
  };

  const [wordDate, setWordDate] = useState([]);



  let ref = React.createRef();
  const inputField = React.useRef(null);
  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };


  const handleChangeTodate = (input) => {
    let genData = generateStringDateFromDate(input);
    setWordDate(genData);
    setToDate(input);
  };

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea) => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    dispatch(getSubLedger(conFromDate, conToDate, idZone, idDivision, idArea));
  }



  const style = {
    container: {
      'background': 'f7f7f7',
      // 'height':670,
      'overflowy': 'scroll'
    },
  }
  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setDefaultTime("datetime-local");
    } else {
      setDefaultTime("date");
    }
  }
  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Customer Ledger </h3>
            </div>
          </div>
         
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                // value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
         
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={
                  (e) => {
                    handleChange(e, 'todate');
                  }
                }
              />
            </Form.Group>
          </div>
          
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zone</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                onChange={(option) => {
                  setSelectZone(option.value);
                  divisionList(option.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("intDistributorId", "");
                  handleListAction(startDate, toDate, option.value, selectDivision, selectArea);

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                onChange={(option) => {
                  getAreaData(option.value);
                  setSelectDivision(option.value);
                  setValue("areaData", "");
                  handleListAction(startDate, toDate, selectZone, option.value, selectArea);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Area</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                onChange={(option) => {
                  setSelectArea(option.value);
                  dispatch(getSalesList(option.value, salesType));
                  handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <button type="button" className="btn btn-success btn-sm mt-5" onClick={() => handleListAction()}>
              Show Report
            </button>
          </div>
        </div>
        {/* {subLedgerData.length > 0 &&
          <div className="container mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <p className="widgetTitle">Cake</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.cookisTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.cookisLiftingQty)}</span>
                      <span>Target Price:  {parseFloat(totalTarget.cookisTargetPrice)}</span>
                      <span>Lifting Price:  {parseFloat(totalTarget.cookisLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <p className="widgetTitle">Cookies & Biscuit</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.cookisandBiscuitTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.cookisandBiscuitLiftingQty)}</span>
                      <span>Target Price:  {parseFloat(totalTarget.cookisandBiscuitTargetPrice)}</span>
                      <span>Lifting Price:  {parseFloat(totalTarget.cookisandBiscuitLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget three">
                  <div className="widget__left">
                    <p className="widgetTitle">Total</p>
                    <div className="achiveLayer">
                      <span>Target Quantity:  {parseFloat(totalTarget.totalTargetQty)}</span>
                      <span>Lifting Quantity:  {parseFloat(totalTarget.totalLiftingQty)}</span>
                      <span>Target Price:  {parseFloat(totalTarget.totalTargetPrice)}</span>
                      <span>Lifting Price:  {parseFloat(totalTarget.totalLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } */}

        <div className="row" id="id" ref={inputField}>
          <div className="react-bootstrap-table table-responsive border-0 pl-5 " style={style.container}>
            <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} >
              <thead>
                <tr>
                  <th scope="col">CUSTOMER ID</th>
                  <th scope="col">ENTRY CODE</th>
                  <th scope="col">AMOUNT</th>
                  <th scope="col">OPENING BALANCE</th>
                  <th scope="col">VOUCHAR DATE</th>
                  <th scope="col">NARRATION</th>
                </tr>
              </thead>
              <tbody>
                {subLedgerData.length > 0 &&
                  subLedgerData.map((item, index) => (
                    <>
                      <tr>
                        <td>Cookies-Chess Board-Bakem</td>
                        <td>Cookies-Chess Board-Bake</td>
                        <td>Pack-220gm-Carton(6)</td>
                        <td>Bakemans Saltice-Family</td>
                        <td>Bakemans Saltice-Family</td>
                        <td>Biscuit-Cream-Funtastic Ore</td>
                        <td>
                        {" "}
                        <Link onClick={() => itemDetail(item)} title="Details">
                          <i className="far fa-eye editIcon item-list-icon"></i>
                        </Link>
                      </td>
                      </tr>

                    </>

                  ))}
                
              </tbody>
            </table>

            {!isLoading && subLedgerData.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! LEDGER Not Found.
              </div>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerLedger;
