/*******************************************************************************************************
        Sub Module: Voyage
********************************************************************************************************/
// Master Data

export const GET_CARGO = "GET_CARGO";
export const GET_PORT_LIST = "GET_PORT_LIST";

export const GET_VOYAGE = "GET_VOYAGE";
export const GET_VOYAGE_LIST_DETAIL = "GET_VOYAGE_LIST_DETAIL";
export const CHANGE_VOYAGE_INPUT = "CHANGE_VOYAGE_INPUT";
export const VOYAGE_ACTIVITY_ADD = "VOYAGE_ACTIVITY_ADD";
export const VOYAGE_SUBMIT = "VOYAGE_SUBMIT";
export const VOYAGE_SUBMITTING = "VOYAGE_SUBMITTING";
export const GET_LAST_VOYAGE = "GET_LAST_VOYAGE";

/*******************************************************************************************************
        Sub Module: Voyage Activity
********************************************************************************************************/
export const GET_VOYAGE_ACTIVITY = "GET_VOYAGE_ACTIVITY";
export const GET_VOYAGE_ACTIVITY_DETAIL = "GET_VOYAGE_ACTIVITY_DETAIL";
export const GET_VOYAGE_SHIP_CONDITION = "GET_VOYAGE_SHIP_CONDITION";
export const CHANGE_VOYAGE_ACTIVITY_INPUT = "CHANGE_VOYAGE_ACTIVITY_INPUT";
export const VOYAGE_WIND_DIREACTION_DATA = "VOYAGE_WIND_DIREACTION_DATA";
export const VOYAGE_ACTIVITY_CREATE_OFFICER_SUBMIT =
  "VOYAGE_ACTIVITY_CREATE_OFFICER_SUBMIT";
export const VOYAGE_ACTIVITY_CREATE_OFFICER_SUBMITTING =
  "VOYAGE_ACTIVITY_CREATE_OFFICER_SUBMITTING";

/*******************************************************************************************************
        Sub Module: Voage Activity Boiler 
********************************************************************************************************/
export const CHANGE_VOYAGE_ACTIVITY_BOILER_INPUT =
  "CHANGE_VOYAGE_ACTIVITY_BOILER_INPUT";
export const ADD_MULTIPLE_BOILER_LIST = "ADD_MULTIPLE_BOILER_LIST";
export const VOYAGE_BOILER_SUBMIT = "VOYAGE_BOILER_SUBMIT";
export const DELETE_BOILER_MULTIPLE_DATA = "DELETE_BOILER_MULTIPLE_DATA";
export const VOYAGE_BOILER_SUBMITTING = "VOYAGE_BOILER_SUBMITTING";

/*******************************************************************************************************
        Sub Module: Voage Activity Aux Engine
********************************************************************************************************/
export const CHANGE_VOYAGE_ACTIVITY_AUX_ENGN_INPUT =
  "CHANGE_VOYAGE_ACTIVITY_AUX_ENGN_INPUT";
export const VOYAGE_AUX_ENGN_SUBMIT = "VOYAGE_AUX_ENGN_SUBMIT";
export const VOYAGE_AUX_ENGN_SUBMITTING = "VOYAGE_AUX_ENGN_SUBMITTING";
export const VOYAGE_AUX_ENGN_2_SUBMIT = "VOYAGE_AUX_ENGN_SUBMIT";
export const VOYAGE_AUX_ENGN_2_SUBMITTING = "VOYAGE_AUX_ENGN_SUBMITTING";
// Sub Module Voyage Activity Bunker VLSFO
export const CHANGE_VOYAGE_ACTIVITY_CREATE_INPUT =
  "CHANGE_VOYAGE_ACTIVITY_CREATE_INPUT";
export const VOYAGE_AUX_ENGN_3_SUBMIT = "VOYAGE_AUX_ENGN_SUBMIT";
export const VOYAGE_AUX_ENGN_3_SUBMITTING = "VOYAGE_AUX_ENGN_SUBMITTING";
/*******************************************************************************************************
        Sub Module: Voage Activity Bunker 
********************************************************************************************************/
export const CHANGE_VOYAGE_ACTIVITY_BUNKER_INPUT =
  "CHANGE_VOYAGE_ACTIVITY_BUNKER_INPUT";
export const VOYAGE_BUNKER_SUBMIT = "VOYAGE_BUNKER_SUBMIT";
export const VOYAGE_BUNKER_SUBMITTING = "VOYAGE_BUNKER_SUBMITTING";
// New Module
