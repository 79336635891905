import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, getCumalativeReport } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import SimpleSgeoType from "../SalesType/SimpleSgeoType";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ProductWiseTarget = () => {
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const componentRef = useRef();

  const isLoading = useSelector((state) => state.secondarySales.distributorwiseLoading);
  const demandlist = useSelector((state) => state.secondarySales.cumalative);
  const amount = useSelector((state) => state.secondarySales);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  console.log('startDate :>> ', startDate);
  const [toDate, setToDate] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    const tableConfig = {
      margin: { top: 18, right: 8, bottom: 10, left: 8 },
      styles: { fontSize: 4, cellPadding: 1 },
      headStyles: { valign: 'middle', fillColor: [108, 143, 192] },
    };

    // Add logo
    const logoUrl = "/media/logos/abl_logo.png";
    const logoWidth = 16;
    const logoHeight = 16;
    const logoX = 3;
    const logoY = 2;
    doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight);

    // Add title
    doc.setFontSize(8);
    const titleText = "Product Wise Target";
    const titleWidth = doc.getTextDimensions(titleText).w;
    const titleX = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
    doc.text(titleText, titleX, 10);

    // Add sub text
    doc.setFontSize(6);
    const text = "This is the PDF report for product-wise targets.";
    const textWidth = doc.getTextDimensions(text).w;
    const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
    doc.text(text, textX, 13);

    const columns = [
      { header: "ZONE", dataKey: "zone" },
      { header: "DIVISION", dataKey: "division" },
      { header: "REGION", dataKey: "region" },
      { header: "TERRITORY", dataKey: "territory" },
      { header: "DISTRIBUTION ID", dataKey: "distributionId" },
      { header: "DISTRIBUTOR NAME", dataKey: "distributionName" },
      { header: "DISTRIBUTOR POINT ID", dataKey: "distributorPointId" },
      { header: "DISTRIBUTOR POINT NAME", dataKey: "distributorPointName" },
      { header: "NUMBER OF MEMO LINE", dataKey: "memoLine" },
      { header: "TOTAL MEMO QTY", dataKey: "memoQty" },
      { header: "TOTAL VISITED OUTLET", dataKey: "visitedOutlet" },
      { header: "TOTAL ORDER VALUE", dataKey: "orderValue" },
      { header: "BISCUIT QUANTITY", dataKey: "biscuitQty" },
      { header: "BISCUIT VALUE", dataKey: "biscuitValue" },
      { header: "CAKE QUANTITY", dataKey: "cakeQty" },
      { header: "CAKE VALUE", dataKey: "cakeValue" },
      { header: "COOKIES QUANTITY", dataKey: "cookiesQty" },
      { header: "COOKIES VALUE", dataKey: "cookiesValue" },
      { header: "WAFER QUANTITY", dataKey: "waferQty" },
      { header: "WAFER VALUE", dataKey: "waferValue" },
      { header: "TOTAL QUANTITY", dataKey: "totalQty" },
      { header: "TOTAL VALUE", dataKey: "totalValue" },
    ];

    const tableData = demandlist.map((item) => ({
      zone: item.zoneName,
      division: item.divisionName,
      region: item.areaName,
      territory: item.territoryName,
      distributionId: item.distributionId,
      distributionName: item.distributionName,
      distributorPointId: item.distributorPointId,
      distributorPointName: item.distributorPointName,
      memoLine: parseInt(item.memoLine),
      memoQty: parseInt(item.memoQty),
      visitedOutlet: parseInt(item.visitedOutlet),
      orderValue: parseInt(item.orderValue),
      biscuitQty: item.biscuteQty,
      biscuitValue: parseFloat(item.biscutePrice).toFixed(2),
      cakeQty: item.cakeQty,
      cakeValue: parseFloat(item.cakePrice).toFixed(2),
      cookiesQty: item.cookisQty,
      cookiesValue: parseFloat(item.cookisPrice).toFixed(2),
      waferQty: parseFloat(item.wiferQty),
      waferValue: parseFloat(item.wiferPrice).toFixed(2),
      totalQty: parseInt(item.totalQuantity),
      totalValue: parseFloat(item.totalPrice).toFixed(2),
    }));

    doc.autoTable(columns, tableData, tableConfig);
    doc.save("product_wise_target.pdf");
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var d = new Date();
    var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);
      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };

  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectGeoType) => {
    dispatch(getCumalativeReport(fromDate, tDate, idZone, idDivision, idArea, idTerritory, selectGeoType));
  };

  const showValidation = () => {
    if (startDate == null || startDate === "") {
      alert("Please Select From Date");
      return false;
    } else if (toDate == null || toDate === "") {
      alert("Please select To Date");
      return false;
    } else {
      return true;
    }
  };

  const showReport = () => {
    const validation = showValidation();
    if (validation) {
      handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType);
    }
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }
  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [defultTerritory, setdefultTerritory] = useState({});
  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="mt-2 p-2">
              <span className="button-010">Product Wise Target</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right">
              <DownloadDropdown excelname="Product Wise Target" />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType getSalesValue={getSalesValueType} sGeoType={selectGeoType} />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control type="date" placeholder="Enter Deadweight" name="fromDate" value={startDate} className="fromStyle" onChange={handleChange} />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control type="date" placeholder="Enter Todate" name="todate" className="fromStyle" onChange={handleChangeTodate} />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select options={zoneDropdownData()} value={defultZone} onChange={(option) => {
                divisionList(option?.value);
                setSelectZone(option?.value);
                setDefaultZone(option);
                setValue("divisionData", "");
                setValue("areaData", "");
                setValue("territory", "");
              }} defaultValue={defultZone} />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select options={divisionDropdownData()} value={defultDivision} onChange={(option) => {
                getAreaData(option?.value);
                setSelectDivision(option?.value);
                setdefultDivision(option);
                setValue("areaData", "");
                setValue("territory", "");
              }} defaultValue={defultDivision} />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select options={areaDropdownData()} value={defaultArea} onChange={(option) => {
                getTerritoryData(option?.value);
                setSelectArea(option?.value);
                setdefaultArea(option);
                setValue("territory", "");
              }} defaultValue={defaultArea} />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select options={territoryDropdownData()} value={defultTerritory} onChange={(option) => {
                setSelectTerritory(option?.value);
                setdefultTerritory(option);
              }} defaultValue={defultTerritory} />
            </Form.Group>
          </div>
          <div className="col mt-5 mb-5">
            <button className="button-621" onClick={showReport}>Show Report</button>
            {!isLoading && demandlist.length > 0 && (
              <button className="button-pdf ml-2" onClick={downloadAsPDF}>Download as PDF</button>
            )}
          </div>
        </div>

        <div>
          {demandlist.length > 0 && (
            <div className="container-fluid mb-3 mt-3 containerFixed">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget two">
                    <div className="widget__left">
                      <p className="balanceleft">Cake Quantity</p>
                      <p className="balanceleft">Cake Value</p>
                    </div>
                    <div className="widget__right">
                      <div>
                        <h4 className="balanceleft">{parseInt(amount.cakeQty)}</h4>
                        <h4 className="balanceleft">৳ {parseFloat(amount.cakePrice).toFixed(2)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left">
                      <div className="achiveLayer">
                        <span>Biscuit Quantity: {parseInt(amount.biscuteQty + amount.cookisQty)}</span>
                        <span>Biscuit Value: ৳ {parseFloat(amount.biscutePrice + amount.cookisPrice).toFixed(2)}</span>
                        <span>Wafer Quantity: {parseInt(amount.wiferQty)}</span>
                        <span>Wafer Value: ৳ {parseFloat(amount.wiferPrice).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="paid__widget four">
                    <div className="widget__left">
                      <p className="balanceleft">Total Quantity</p>
                      <p className="balanceleft">Total Value</p>
                    </div>
                    <div>
                      <h4 className="balanceleft">{parseInt(amount.totalQuantityCum)}</h4>
                      <h4 className="balanceleft">৳ {parseFloat(amount.totalPriceCum).toFixed(2)}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isLoading && <LoadingSpinner />}
          {!isLoading && demandlist.length === 0 && (
            <div className="text-center mt-5 mb-5">
              <h5 className="bg-warning p-2">No Data Found</h5>
            </div>
          )}
          {!isLoading && demandlist.length > 0 && (
            <div>
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="table-responsive">
                      <div className="table-container" >
                        <table className="table table-bordered table-hover" id="table-to-xls" ref={componentRef}>
                          <thead>
                            <tr>
                              <th scope="col">ZONE</th>
                              <th scope="col">DIVISION</th>
                              <th scope="col">REGION</th>
                              <th scope="col">TERRITORY</th>
                              <th scope="col">DISTRIBUTION ID</th>
                              <th scope="col">DISTRIBUTOR NAME</th>
                              <th scope="col">DISTRIBUTOR POINT ID</th>
                              <th scope="col">DISTRIBUTOR POINT NAME</th>
                              <th scope="col">NUMBER OF MEMOlINE</th>
                              <th scope="col">TOTAL MEMO QTY</th>
                              <th scope="col">TOTAL VISITED OUTLET</th>
                              <th scope="col">TOTAL ORDER VALUE</th>
                              <th scope="col">BISCUIT QUNATITY</th>
                              <th scope="col">BISCUIT VALUE</th>
                              <th scope="col">CAKE QUNATITY</th>
                              <th scope="col">CAKE VALUE</th>
                              <th scope="col">COOKIES QUNATITY</th>
                              <th scope="col">COOKIES VALUE</th>
                              <th scope="col">WAFER QUNATITY</th>
                              <th scope="col">WAFER VALUE</th>
                              <th scope="col">TOTAL QUANTITY</th>
                              <th scope="col">TOTAL VALUE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {demandlist.length > 0 &&
                              demandlist.map((item, index) => (
                                <tr>
                                  <td>{item.zoneName}</td>
                                  <td>{item.divisionName}</td>
                                  <td>{item.areaName}</td>
                                  <td>{item.territoryName}</td>
                                  <td>{item.distributionId}</td>
                                  <td>{item.distributionName}</td>
                                  <td>{item.distributorPointId}</td>
                                  <td>{item.distributorPointName}</td>
                                  <td>{parseInt(item.memoLine)}</td>
                                  <td>{parseInt(item.memoQty)}</td>
                                  <td>{parseInt(item.visitedOutlet)}</td>
                                  <td>{parseInt(item.orderValue)}</td>
                                  <td>{item.biscuteQty}</td>
                                  <td>{parseFloat(item.biscutePrice).toFixed(2)}</td>
                                  <td>{item.cakeQty}</td>
                                  <td>{parseFloat(item.cakePrice).toFixed(2)}</td>
                                  <td>{item.cookisQty}</td>
                                  <td>{parseFloat(item.cookisPrice).toFixed(2)}</td>
                                  <td>{parseFloat(item.wiferQty)}</td>
                                  <td>{parseFloat(item.wiferPrice).toFixed(2)}</td>
                                  <td>{parseInt(item.totalQuantity)}</td>
                                  <td>{parseFloat(item.totalPrice).toFixed(2)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          
        </div>
      </div>
    </>
  );
};

export default ProductWiseTarget;
